import { useTheme } from "@emotion/react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography,Popover,Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "src/reusable-components/icons";
import { useState } from "react";
export default function Result({type,clientDetail,openInstance,openFacility}) {
    const navigate = useNavigate();
    const Intances_columns = [
        { id: 0, name: 'instanceId', label: 'Name', value: true },
        { id: 1, name: 'authenticateMode', label: 'Authentication Mode', value: true },
        { id: 2, name: 'createdOn', label: 'Created On', value: true },
        { id: 2, name: 'expiryOn', label: 'Expiry On', value: true },
        { id: 3, name: 'status', label: 'Status', value: true }
      ];
    const facility_columns = [
        { id: 0, name: 'facilityName', label: 'Name', value: true },
        { id: 1, name: 'specilaities', label: 'Specialty', value: true },
        { id: 2, name: 'email', label: 'Contact Email', value: true },
        { id: 3, name: 'livestatus', label: 'Live Status', value: true },
        { id: 4, name: 'facilityStatus', label: 'Status', value: true }
    ];
    const initialColumns = type === 'instance' ? Intances_columns : facility_columns;
    const theme = useTheme();
    const classes = {
        root: {},
        headCell: {
            color: theme.palette.text.gray,
            padding: '0 10px 5px 0',
            fontSize: '12px',
        },
        headcell: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            flexWrap: 'nowrap',
            justifyContent: 'start',
            whiteSpace: 'nowrap',
        },
        headcellCenter: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
        },
        headlabel: {
            fontSize: '12px',
            marginRight: '8px'
        },
        highlightheadlabel: {
            color: '#355EEF',
        },
        bodyCell: {
            verticalAlign: 'middle',
            padding: '9px 15px 9px 0',
            whiteSpace: 'nowrap',
            minWidth: '60px',
        },
        blueValue: {
            color: theme.palette.text.secondary,
            marginBottom: 0,
            cursor: 'pointer',
        },
        blackValue: {
            color: theme.palette.text.primary,
            marginBottom: 0,
        },
        selected: {
            backgroundColor: 'transparent !important',
            border: `1px solid ${theme.palette.button.blue}`,
        },
        hover: {
            '&:hover': {
            backgroundColor: theme.palette.button.weak,
            },
        },
        dropDown: {
            transition: '0.4s',
        },
        dropUp: {
            transform: 'rotate(180deg)',
        },
        labelIcon: {
            borderRadius: '25px',
            height: '25px',
            width: '25px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
        },
    }
    const [columns, setColumns] = useState(initialColumns);

    const getContent = (value, key,result) => {
        if(key === 'status'){
            return <Box display="flex" >
                    {value.toLowerCase() === 'activated' && <>Activated &nbsp;<Icon name="success" size="md"className={{marginLeft: '26px'}} /><Icon name="edit" size="md" className={{marginLeft: 2, cursor: 'pointer'}} onClick={() => openInstance({...result,client: clientDetail?.clientName})} /></>}
                    {value.toLowerCase() === 'deactivated' && <>Deactivated &nbsp;<Icon name="reject" size="md" ml={10} color="red"/><Icon name="edit" size="md" className={{marginLeft: 2, cursor: 'pointer'}} onClick={() => openInstance({...result,client: clientDetail?.clientName})} /></>}
                </Box>
        }
        else if(key === 'facilityStatus'){
            return <Box display="flex">
                    {value.toLowerCase() === 'activated' && <>Activated &nbsp;<Icon name="success" size="md"className={{marginLeft: '26px'}} /><Icon name="edit" size="md" className={{marginLeft: 2, cursor: 'pointer'}} onClick={()=>openFacility(result)} /></>}
                    {value.toLowerCase() === 'deactivated' && <>Deactivated &nbsp;<Icon name="reject" size="md" ml={10} color="red"/><Icon name="edit" size="md" className={{marginLeft: 2, cursor: 'pointer'}} onClick={()=>openFacility(result)} /></>}
                </Box>
        }
        else if(key === 'livestatus'){
            return <Box display="flex" justifyContent='center'>
                        <Icon name={value === "" ? 'red-square' : 'green-square'} size="xs" />
                    </Box>
        }
        else if(key === 'authenticateMode') {
            return <Typography variant="body1"> {value.toUpperCase()}</Typography> 
        }
        else {
            return <Typography variant="body1" sx={key === 'Role' || key === 'Users' ? classes.blueValue : classes.blackValue}> {value}</Typography> 
        }
    }
    const initialRows =  type === 'instance' ? clientDetail?.instances : clientDetail?.facilities;
    const tableCell = (column, result) => {
        const key = column.name;
        return (
          column.value && (
            <TableCell
              key={key}
              sx={classes.bodyCell}
              style={{
                textTransform:  key === 'TransactionType' 
                  ? 'capitalize'
                  : '',
                width: key === 'status' || key === 'facilityStatus' ? '6%' : {}
              }}
                >
                {getContent(result[key],key,result)}
            </TableCell>
          )
        );
    };
    return (
        <>
        <Table>
            <TableHead style={{ borderBottom: '2px solid black' }}>
                <TableRow>
                {columns
                .filter((item) => item.value)
                .map((item) => (
                    <TableCell
                    sx={classes.headCell}
                    key={item.name}
                    value={item.name}
                    >
                    <div style={ item.name === 'livestatus' ? classes.headcellCenter : classes.headcell }>
                        <Typography sx={classes.headlabel}>
                        {item.label}
                        </Typography>
                    </div>
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {initialRows.map((result, index) => {
                return (
                    <TableRow hover key={index}>
                    {columns.map((column) => {
                        return tableCell(column, result);
                    })}
                    </TableRow>
                );
                })}
          </TableBody>
        </Table>
    </>
    )
}
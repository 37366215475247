import { useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import CustomPagination from "src/reusable-components/pagination";
import Result from "./Result";
import { useDispatch , useSelector} from "react-redux";
import { useState } from "react";
import Loading from "src/components/Loading";

export default function Details({
    page,
    setPage,
    limit,
    setLimit,
    fetchUserData
    }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { users, total } = useSelector((state)=>state.userlist);
    const classes = {
        label: {
            color: theme.palette.text.gray,
        }
    }
    const [sortHead, setSortHead] = useState({
        label: 'username',
        up: true,
    });
    const handleHeadClick = (item) => {
        let direction = false;
        if (item.sort) {
          if (sortHead.label === item.name) {
            setSortHead({
              label: item.name,
              up: !sortHead.up,
            });
            direction = !sortHead.up;
          } else {
            setSortHead({
              label: item.name,
              up: sortHead.up,
            });
          }
        }
        fetchUserData(page, limit,item.sortKey, direction);
    };
    const handlePageChange = (event, newPage) => {
        dispatch(setPage(newPage));
        fetchUserData(newPage, limit,sortHead.label, sortHead.up);
    };
    const handleLimit = (e) => {
        dispatch(setPage(page));
        dispatch(setLimit(e.target.value));
        fetchUserData(1, e.target.value);
      };
    const { user: userLoading } = useSelector((state) => state.loading);

    return (
        <Box maxWidth="100%">
            <Box display="flex" alignItems="end" justifyContent="space-between" pb={2}>
                <Box display="flex" alignItems="end" height={64} pt={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={classes.label}>
                            <span>{total} Users(s) found</span>
                        </Typography>
                        {userLoading && <Loading />}
                    </Box>
                </Box>
                <Box pt={2} display="flex" justifyContent="flex-end" alignItems="end">
                    <CustomPagination
                        onLimitChange={handleLimit}
                        limit={limit}
                        page={page}
                        onPageChange={handlePageChange}
                        totalNum={total}
                    />
                </Box>
            </Box>
            <Box pb={5} style={{ overflowX: 'auto', marginBottom: '15px' }} width={'100%'} height={window.innerHeight - 225 + 'px'}>
                <Result sortHead={sortHead} users={users} page={page} rowsPerPage={limit} handleHeadClick={handleHeadClick}/>
            </Box>
        </Box>
    );
}
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from './Header';
import UserCard from './userCard';
import { getUserDetails } from 'src/slices/userInfo';

const UsersDetail = () => {
  const dispatch = useDispatch();
  const { uid } = useParams();
  const userId = uid;
  const { userDetails } = useSelector((state) => state.userdetails);

  useEffect(() => {
    dispatch(getUserDetails({ userId }));
  }, []);  // eslint-disable-line

  return (
    <Box px={7}>
      <Header />
      <Box mt={2}>
        <UserCard userCard={userDetails}/>
      </Box>
    </Box>
  )
};

export default UsersDetail;

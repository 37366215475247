import axios from 'axios';
import { endPoints } from 'src/utils/endPoints';

axios.interceptors.request.use(function (config) {
  var apiUrl = endPoints.BASE_URL;
  const token = localStorage.getItem('token');

  const excludedEndpoints = [
    endPoints.LOG_IN,
    endPoints.GET_CLIENT,
    endPoints.GET_INSTANCE
  ];

  config.url = `${apiUrl}${config.url}`;
  var url = new URL(config.url);
  var pathName = url.pathname.replace(/^\/[\w\d]+\//, '');
  var index1 = excludedEndpoints.findIndex((e) => e.indexOf(pathName) > -1);
  var index2 = pathName.indexOf('securityQuestions');

  if (index1 < 0 && index2 < 0) {
    config.headers['TOKEN'] = token;
  }

  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response && error.response?.data?.errorType === 'TOKEN_NOT_VALID') {
      window.location.href = `${window.location.origin}${process.env.REACT_APP_ROUTER_BASE}`;
    }
    return Promise.reject(error);
  }
);
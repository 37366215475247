import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getNotificationEnd } from 'src/slices/notification';
import Alert from 'src/reusable-components/alerts';

const NotificationItem = ({ type, id, content }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(getNotificationEnd(id));
    }, 3000);
  },[dispatch,id]); 

  return (
    <Box my={1}>
      <Alert message={content} type={type?.trim()?.toLowerCase()} cancelIgnore={true} style={{ textTransform: 'capitalize' }} />
    </Box>
  );
};

export default NotificationItem;

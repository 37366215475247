import { combineReducers } from "redux";
import { reducer as layoutReducer } from '../slices/layout'
import { reducer as notificationReducer } from '../slices/notification'
import { reducer as loadingReducer } from '../slices/loading'
import { reducer as filterReducer } from '../slices/filter'
import {reducer as userReducer} from '../slices/user'
import {reducer as userlistReducer} from '../slices/userlist'
import {reducer as userdetailsReducer} from '../slices/userInfo'
import {reducer as transactionReducer} from '../slices/transaction'
import { reducer as transactionDetailsReducer} from '../slices/transactionDetails'
import { reducer as managementReducer} from '../slices/management'
const rootReducer = combineReducers({
    layout: layoutReducer,
    notification: notificationReducer,
    loading: loadingReducer,
    filter: filterReducer,
    transactions: transactionReducer,
    user: userReducer,
    userdetails:userdetailsReducer,
    userlist: userlistReducer,
    transactionDetails:transactionDetailsReducer,
    management:managementReducer,
});
export default rootReducer

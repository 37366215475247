import { useTheme } from "@emotion/react";
import { Box,Typography } from "@mui/material";
const UserTool = ({ onClose, handleLogOut }) => {
    const theme = useTheme();
    const classes = {
        root: { width: '200px' },
        user: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '96px',
            paddingTop: '16px',
            backgroundColor: '#46CC2E',
        },
        avatar: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            borderRadius: '50%',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        userName: {
            marginTop: theme.spacing(1.5),
            color: 'white',
            fontSize: '14px',
            fontWeight: '700',
        },
        item: {
            display: 'flex',
            width: 'calc(100% - 16px)',
            justifyContent: 'space-between',
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            borderRadius: '3px',
            color: '#6D6D6D',
            fontSize: '14px',
            fontWeight: '500',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#F3F4F4',
            },
        },
        number: {
            padding: '5px',
            borderRadius: '50%',
            width: '18px',
            height: '18px',
            color: theme.palette.text.secondary,
            backgroundColor: '#DCF4FF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
  const userName = localStorage.getItem('user');
  return (
    <Box sx={classes.root}>
      <Box sx={classes.user}>
        <Box sx={classes.avatar}>
          <img src="/images/union.svg" alt="user" />
        </Box>
        <Typography sx={classes.userName}>{userName}</Typography>
      </Box>
      <Typography
        sx={classes.item}
        onClick={() => {
          onClose();
          handleLogOut();
        }}
      >
        Log Out
      </Typography>
    </Box>
  );
}
export default UserTool;
import { Box } from "@mui/material"
import TransactionFilter from "./transaction"
import RolesFilter from "./roles"
import UsersFilter from "./users"
import ClientsFilter from "./clients"

const FilteringList = ({ type,data,fetchData,setPage,setNewFilter }) => {
    return (
        <Box>
            {type === 'transaction' && (
                <TransactionFilter 
                    data={data} 
                    fetchData={fetchData}
                    setPage={setPage}
                    setNewFilter={setNewFilter}
                />
            )}
            {type === 'roles' && (
                <RolesFilter data={data} setNewFilter={setNewFilter}
                />
            )}
            {type === 'clients' && (
                <ClientsFilter data={data} setNewFilter={setNewFilter}/>
            )}
            {type === 'users' && (
                <UsersFilter 
                data={data} 
                fetchData={fetchData}
                setPage={setPage}
                setNewFilter={setNewFilter} />
            )}
        </Box>
    )
}
export default FilteringList

import { useTheme } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
const CustomCheckBox = ({ label, disabled, checked, onChange, width }) => {
  const theme = useTheme();
  const classes = {
    itemcheck: {
      '&.MuiFormControlLabel-root': {
          margin: 0,
          paddingLeft: '5px',
          alignItems: 'start',
          borderTop: 'solid 1px #ddd',
          width: '100%',
      },
      '&.focused': {
          backgroundColor: 'red !important',
      }, 
      '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
        marginTop: '0.1rem'
      }
    },
    checkBox: {
      '&.MuiCheckbox-root': {
            padding: '3px 8px 3px 0px',
      },
      '&.MuiTypography-root': {
        marginTop: '14px',
        color: 'red'
      },
      color: '#6D6D6D',
      '&.Mui-checked': {
        color: theme.palette.button.blue
      }
    },
  }
  return (
    <FormControlLabel
      sx={classes.itemcheck}
      control={
        <Checkbox
          sx={classes.checkBox}
          checked={checked}
          onChange={onChange}
          size={'small'}
          disableRipple
        />
      }
      label={label}
      disabled={disabled}
      labelPlacement="end"
    />
  );
};
const MultiSelect = React.forwardRef((props, forwardRef) => {
  const theme = useTheme();
  const { selected, setSelect, onChange, placeholder, selectList, search, setSearch, error } = props;
  const orgList = useRef([]);
  const selectNum = useRef(1);
  const rootRef = useRef(forwardRef);
  const [options, setOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [drop, setDrop] = useState(false);
  const selectRef = useRef();
  const searchRef = useRef();
    const classes = {
        root: {
            position: 'relative',
            width: props.width ? props.width : '100%',
            cursor: 'pointer',
        },
        header: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        fresh: {
            color: theme.palette.text.gray_3 + ' !important',
        },
        selectedItem: {
            border: 'solid 1px #D6D6D6',
            lineHeight: '0px',
            '&:focus': {
                borderColor: '#355EEF',
                outline: 'none !important',
            },
        },
        selection: {
            borderRadius: '3px',
            border: 'solid 1px #D6D6D6',
            borderColor: '#D6D6D6',
            '&:hover': {
                backgroundColor: '#F3F4F4',
            },
        },
        item: {
            height: props.size === 'small' ? '32px' : '36px',
            padding: '6px 8px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: '500',
            fontSize: '13px',
            fontFamily: 'Inter',
            color: theme.palette.text.neutral,
        },
        dropdown: {
            zIndex: 9999,
            boxShadow: '0px 2px 4px 2px #e2e2e2',
            position: 'absolute',
            width: '100%',
            background: 'white',
            borderRadius: '4px',
        },
        dropUp: {
            transform: 'rotate(180deg)',
        },
        checked: {
            color: theme.palette.button.blue,
        },
        selectContainer: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '3px',
            backgroundColor: '#FFFFFF',
            maxHeight: '200px',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px',
                backgroundColor: '#ddd',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#aaa',
                borderRadius: '10px',
            },
        },
        search: {
            width: '100%',
            height: '30px',
            outline: 'none',
            border: 'none',
            paddingLeft: '10px',
        },
        selectItem: {
            '&:focus': {
                outline: 'none !important',
                backgroundColor: '#4169E133 !important',
            },
        },
    };
   
  
  //close the dropdown by clicking outside
  const closeDropDown = (e) => {
    if (rootRef.current?.contains(e.target)) {
      rootRef.current?.classList.add('focused');
    } else {
      rootRef.current?.classList.remove('focused');
    }
    !rootRef.current?.contains(e.target) && setDrop(false);
  };

  const handleChange = (e, item) => {
    if (onChange) onChange();
    if (e.target.checked) {
      setSelect([...selected, item]);
    } else {
      setSelect([...selected.filter((el) => el.id !== item.id)]);
    }
  };

  const handleSelectAll = (e) => {
    if (onChange) onChange();
    setSelectAll(!selectAll);
    if (e.target.checked) {
      setSelect([...orgList.current]);
    } else {
      setSelect([]);
    }
  };

  const handleDrop = () => {
    setDrop(!drop);
    let rest = selectList.filter(function (e) {
      return !selected.some(function (s) {
        return s.id === e.id;
      });
    });
    let temp = [...selected, ...rest];
    orgList.current = temp;
    setOptions(orgList.current.slice(0, 40));
    setSearch('');
    selectNum.current = 1;
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const stopPropagationKeyDown = (e) => {
    if (
      e.key === 'Enter' ||
      e.keyCode === 13 ||
      e.key === ' ' ||
      e.keyCode === 32 ||
      e.key === 'ArrowDown' ||
      e.keyCode === 40 ||
      e.key === 'ArrowUp' ||
      e.keyCode === 38
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const handleKeyDownOnSelectedItem = (e) => {
    stopPropagationKeyDown(e);
    if (e.key === 'Enter' || e.keyCode === 13 || e.key === ' ' || e.keyCode === 32) {
      handleDrop();
    }
    if ((e.key === 'ArrowDown' || e.keyCode === 40) && drop) {
      searchRef?.current?.focus();
    }
  };

  const handleKeyDownOnSearch = (e) => {
    if ((e.key === 'ArrowDown' || e.keyCode === 40) && drop) {
      if (selectRef?.current?.childNodes?.length > 0) {
        selectRef.current.childNodes[0].focus();
      }
    }
  };

  const handleKeyDownOnSelectListItem = (e, index, item, checked) => {
    stopPropagationKeyDown(e);
    if (e.key === ' ' || e.keyCode === 32) {
      if (onChange) onChange();
      if (index === 0) {
        setSelectAll(!selectAll);
        if (!selectAll) {
          setSelect([...orgList.current]);
        } else {
          setSelect([]);
        }
      } else {
        if (!checked) {
          setSelect([...selected, item]);
        } else {
          setSelect([...selected.filter((el) => el.id !== item.id)]);
        }
      }
    }
    if ((e.key === 'ArrowDown' || e.keyCode === 40) && drop && index < selectRef.current.childNodes.length - 1) {
      selectRef.current.childNodes[index + 1].focus();
    }
    if ((e.key === 'ArrowUp' || e.keyCode === 38) && drop && index > 0) {
      selectRef.current.childNodes[index - 1].focus();
    }
    if ((e.key === 'ArrowUp' || e.keyCode === 38) && drop && index === 0) {
      searchRef?.current?.focus();
    }
  };

  useEffect(() => {
    if (search) {
      let filter = search.toLowerCase();
      let temp = selectList.filter((item) => item.value.toLowerCase().includes(filter));
      orgList.current = temp;
    } else {
      orgList.current = selectList;
    }
    setOptions([...orgList.current.slice(0, 40)]);
    selectNum.current = 1;
  }, [search, selectList]); //eslint-disable-line

  const handleSelectScroll = (e) => {
    if (selectRef.current) {
      if (
        orgList.current.length > selectNum.current * 40 &&
        selectRef.current.scrollTop + 300 > selectRef.current.scrollHeight
      ) {
        setOptions(orgList.current.slice(selectNum.current * 40 - 10, (selectNum.current + 1) * 40));
        selectNum.current += 1;
        selectRef.current.scrollTo(0, 20);
      }
      if (selectRef.current.scrollTop === 0 && selectNum.current > 1) {
        if (selectNum.current === 2) {
          setOptions(orgList.current.slice(0, 40));
        } else {
          setOptions(orgList.current.slice((selectNum.current - 1) * 40 - 10, selectNum.current * 40));
        }
        selectNum.current -= 1;
        selectRef.current.scrollTo(0, selectRef.current.scrollHeight - 450);
      }
    }
  };

  const closeDropDownByESC = (e) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      setDrop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', closeDropDown);
    window.addEventListener('keydown', closeDropDownByESC);
    selectRef.current?.addEventListener('scroll', handleSelectScroll);
    return () => {
      window.removeEventListener('click', closeDropDown);
      window.removeEventListener('keydown', closeDropDownByESC);
      rootRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (selected.length >= orgList.current.length && selected.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selected, orgList.current]); //eslint-disable-line
    return (
      <Box sx={classes.root} ref={rootRef}>
      <Box
        sx={[classes.selection, classes.item, classes.selectedItem]}
        onClick={handleDrop}
        tabIndex="0"
        onKeyDown={handleKeyDownOnSelectedItem}
      >
          <Typography sx={[classes.header, (selected.length > 0 ? {} : classes.fresh)]}>
          {selected.length > 0 ? selected.map((item) => item.value).join(', ') : placeholder}
        </Typography>
          <img src="/images/dropdown.svg" style={drop ? classes.dropUp : {}} alt="dropdown" />
      </Box>
      <Box
        display={drop ? 'block' : 'none'}
        // style={{ transformOrigin: "center top" }}
        sx={classes.dropdown}
        // ref={dropListRef}
      >
        <Box style={{ borderBottom: '1px solid #000000' }}>
          <input
            placeholder="search"
            onChange={handleSearch}
            style={classes.search}
            value={search}
            ref={searchRef}
            onKeyDown={handleKeyDownOnSearch}
          />
        </Box>
        <Box sx={classes.selectContainer} ref={selectRef} tabIndex="0">
          {selectList.length > 0 && (
            <Box
              tabIndex="0"
              sx={classes.selectItem}
              onKeyDown={(e) => {
                handleKeyDownOnSelectListItem(e, 0);
              }}
            >
              <CustomCheckBox label="Select All" checked={selectAll} onChange={handleSelectAll} />
            </Box>
          )}
          {options.map((item, key) => (
            <Box
              tabIndex="0"
              key={key}
              sx={classes.selectItem}
              onKeyDown={(e) => {
                handleKeyDownOnSelectListItem(e, key + 1, item, !!selected.find((v) => v.id === item.id));
              }}
            >
              <CustomCheckBox
                label={item.value}
                key={item.id}
                checked={!!selected.find((v) => v.id === item.id)}
                onChange={(e) => handleChange(e, item)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      {error && (
        <Typography variant="caption" style={{ color: 'red' }}>
          {error}
        </Typography>
      )}
    </Box>
    )
})
export default MultiSelect;
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../utils/endPoints";
import { getNotificationStart } from "./notification";
import {NOTIFICATION} from '../utils/constant'
import { getLoadingStart,getLoadingEnd } from "./loading";
const initialState = {
  transactions: [],
  total: 0,
  page: 1,
  limit: 50
};

const slice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    initialize(state, action) {
      state.transactions = [];
      state.total = 0;
      state.page = 1;
      state.limit = 50;
    },
    getTransactions(state, action) {
      const payload = action.payload;
      state.transactions = payload.transactions;
      state.total = payload?.total || 0;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload
    }
  },
});

export const reducer = slice.reducer;

export const getTrasactions = (requestData) => async (dispatch) =>{
    dispatch(getLoadingStart('transaction'));
  await axios({
    method: 'POST',
    url: endPoints.GET_TRANSACTIONS,
    data: { ...requestData }
  })
  .then(async (response) =>{
    dispatch(slice.actions.getTransactions(response.data));
    dispatch(getLoadingEnd('transaction'));
  })
  .catch((err) => {
    dispatch(getLoadingEnd('transaction'));
    dispatch(
      getNotificationStart(NOTIFICATION.ERROR, err?.response?.data?.errorMessage || 'Error trying to fetch reports.'));
  });
}
export const setPage = (data) => async (dispatch) => {
  dispatch(slice.actions.setPage(data));
};

export const setLimit = (data) => async (dispatch) => {
  dispatch(slice.actions.setLimit(data));
};

export const transactionInitialize = () => async (dispatch) => {
  dispatch(slice.actions.initialize());
};
export default slice;

import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/reusable-components/buttons/CustomButton';
import MultiSelect from 'src/reusable-components/multi-select';
import UserBreadcrumbs from 'src/reusable-components/navigation/Breadcrumbs';
import { CustomRadioGroup } from 'src/reusable-components/radios';
import Textfield from 'src/reusable-components/text-field';
import { getNotificationStart } from 'src/slices/notification';
import { NOTIFICATION } from 'src/utils/constant';
import { STATUS } from 'src/utils/constant';
import { addNewRole } from 'src/slices/management';

const classes = {
  divider: {
    background: '#242424',
    width: '100%',
    height: '2px',
  },
  section: {
    borderBottom: '2px solid #242424',
    paddingBottom: '32px',
    marginBottom: '16px',
  },
  title: {
    color: '#242424',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '15px',
  },
};
const AddNewRole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [permissionSearch, setPermissionSearch] = useState('');

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      roleName: '',
      status: 'Activate',
      permission: [],
    },
  });
  const { rolePermissions } = useSelector((state) => state.management);
  const permissionOptions = () => {
    const options = rolePermissions.map((role, index) => ({
      value: role,
      id: index,
    }));
    return options;
  };

  const onSubmit = async (values) => {
    const statusSuffix = values?.status == 'Lock' ? 'ed' : 'd';
    const newRole = {
      ...values,
      permission: values.permission.map((e) => e.value),
      status: values.status + '' +statusSuffix,
    };
    setLoading(true)
    await addNewRole(newRole)
      .then((res) => {
        setLoading(false);
        dispatch(getNotificationStart(NOTIFICATION.SUCCESS, 'Added new role successfully.'));
        navigate('/dashboard/roles');
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          getNotificationStart(NOTIFICATION.ERROR, err?.response?.data?.errorMessage || 'Failed to add a new role.')
        );
      });
  };

  return (
    <Box px={7} pt={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <UserBreadcrumbs type="roleAdd" />
          </Grid>
        </Grid>
        <Typography variant="h5" style={{ marginTop: 30 }}>
          Add Role
        </Typography>
        <Box sx={classes.section} mt={5}>
          <Box display="flex" mt={2} alignItems="flex-start" flexWrap="wrap">
            <Box>
              <Typography sx={classes.title}>Role Name</Typography>
              <Controller
                control={control}
                name="roleName"
                rules={{ required: true, maxLength: 20}}
                render={({ field }) => (
                  <Textfield
                    {...field}
                    placeholder="Role Name"
                    width="224px"
                    error={
                      errors.roleName?.type === 'required'
                        ? 'Role name is required'
                        : errors.roleName?.type === 'pattern'
                        ? 'Invalid role name'
                        : ''
                    }
                  />
                )}
              />
            </Box>
            <Box ml={5}>
              <Typography sx={classes.title}>Permissions</Typography>
              <Controller
                control={control}
                name="permission"
                rules={{ required: true }}
                render={({ field: { value, name, ref, onChange } }) => (
                  <MultiSelect
                    ref={ref}
                    name={name}
                    width="224px"
                    placeholder="Select permission"
                    selectList={permissionOptions()}
                    selected={value}
                    setSelect={(v) => onChange(v)}
                    search={permissionSearch}
                    setSearch={setPermissionSearch}
                    error={errors.permission ? 'Permission is required' : ''}
                  />
                )}
              />
              <Box mt={1}>
                <Typography variant="caption" style={{ color: '#878787' }}>
                  {watch('permission')
                    .map((e) => e.value)
                    .join(', ')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography sx={classes.title}>Status</Typography>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography sx={classes.title} style={{ marginBottom: '-2px' }}>
              Account status
            </Typography>
            <Controller
              control={control}
              name="status"
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomRadioGroup
                  direction="row"
                  ref={ref}
                  labels={STATUS}
                  selectedLabel={value}
                  name={name}
                  setSelectedLabel={(e) => onChange(e)}
                />
              )}
            />
          </Box>
        </Box>
        {errors.status ? (
          <Typography variant="caption" style={{ color: 'red' }}>
            Status is required.
          </Typography>
        ) : null}
        <Box sx={classes.divider} mt={4} mb={2} />
        <Box display="flex" justifyContent="flex-end" mt={10}>
          <Button size="medium" color="secondary" text="Cancel" />
          <Button size="medium" color="primary" text="Add role" type="submit" disabled={!isValid || !isDirty || loading}
        //   disabled={!isValid || !isDirty || loading}
           />
        </Box>
      </form>
    </Box>
  );
};

export default AddNewRole;

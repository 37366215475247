import { Box } from "@mui/material"
import Header from './Header'
export default function Home() {
    return (
        <Box display="flex">
            <Box px={7} flex="1" sx={{
                height: 'calc(100vh - 55px)',
                overflow: 'auto',
            }}>
            <Header/>
            {/* <Details /> */}
            </Box>
        </Box>
    )
}
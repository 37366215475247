import calendarize from 'calendarize';

export const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const TIME_HH = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];

export const TIME_MM = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59'
];

export const YEAR_RANGE = new Array(50).fill(2000).map((num, index) => {
  return num + index;
}).reverse();

export const formatDate = (day, month, year) => {
  day = day < 10 ? `0${day}` : day;
  const monthIndex = MONTHS.indexOf(month) + 1;
  month = monthIndex < 10 ? `0${monthIndex}` : monthIndex;
  return `${year}-${month}-${day}`;
};

export const getWeekendArrayInMonth = (weeks) => {
  let sundays = [];
  let saturdays = [];
  weeks.forEach((week) => {
    saturdays.push(week[6]);
    sundays.push(week[0]);
  });

  return { SAT_ARRAY: saturdays, SUN_ARRAY: sundays };
};

export const preprocessDays = (year, month) => {
  const normalYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const leapYear = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  let originalDays = calendarize(new Date(`${year}-${month}-01`), 0);

  let prevMonthDays = 0;
  if (year % 4 === 0 && year % 100 !== 0) {
    prevMonthDays = leapYear[MONTHS.indexOf(month) - 1 > 0 ? MONTHS.indexOf(month) - 1 : 11];
  } else {
    prevMonthDays = normalYear[MONTHS.indexOf(month) - 1 > 0 ? MONTHS.indexOf(month) - 1 : 11];
  }
  const nonZeroIndexInMonth = originalDays[0].indexOf(1);
  // Fill the first 0 arrays with previous  month days.
  for (let i = 0; i < nonZeroIndexInMonth; i++) {
    originalDays[0][i] = -(prevMonthDays - nonZeroIndexInMonth + i + 1);
  }
  // Fill the last 0 arrays with next month days.
  const zeroIndexInMonthLast = originalDays.slice(-1)[0].indexOf(0);
  if (zeroIndexInMonthLast > -1) {
    for (let i = 0; i < 7 - zeroIndexInMonthLast; i++) {
      originalDays.slice(-1)[0][i + zeroIndexInMonthLast] = -(i + 1);
    }
  }
  return originalDays;
};

export const checkValidSelect = (
  firstDay,
  firstMonth,
  firstYear,
  secondDay,
  secondMonth,
  secondYear,
  futureSelect = false
) => {
  //const firstDate = new Date(firstMonth + '/' + firstDay + '/' + firstYear);
  // const secondDate = new Date(secondMonth + '/' + secondDay + '/' + secondYear);

  // if (secondDate < firstDate) {
  //   return false;
  // }
  // if (secondDate > new Date() && !futureSelect) {
  //   return false;
  // }
  return true;
};

export const formatPickerDate = (date) => {
  let year = date.getFullYear(date).toString();
  let month = date.getMonth(date).toString();
  let day = date.getDate(date).toString();
  const formattedDate =
    year + '-' + (month < 9 ? `0${Number(month) + 1}` : `${Number(month) + 1}`) + '-' + (day < 10 ? `0${day}` : day);

  return formattedDate;
};

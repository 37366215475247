import { Box, Typography } from '@mui/material';
import React from 'react';


const ranges = [0, 1, 2, 7, 30, 'this_month', 'last_month', 'last_year', 'custom'];
const labels = [
  'Today',
  'Yesterday',
  'Last 2 Days',
  'Last 7 Days',
  'Last 30 Days',
  'This Month',
  'Last Month',
  'Last Year',
  'Custom Range',
];

const RangeList = (props) => {
  const classes = {
    root: {
      width: '158px',
      height: props.isTimePicker ? '360px' : '',
      borderRadius: '0px 3px 3px 0px',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
      padding: '12px 16px',
      marginRight: '2px',
    },
    item: {
      width: '126px',
      height: '32px',
      cursor: 'pointer',
      '&:hover': {
        background: '#F3F4F4',
      },
      paddingLeft: '8px',
      display: 'flex',
      alignItems: 'center',
    },
  };
  const { setRange, setToggle, toggle, handleClosePicker } = props;
  const handleClick = (value) => {
    if (value < 8) {
      setRange(ranges[value]);
      handleClosePicker();
    } else {
      setToggle(!toggle);
    }
  };

  return (
    <Box sx={classes.root}>
      {labels.map((label, index) => {
        return (
          <Typography sx={classes.item} key={index} value={index} onClick={() => handleClick(index)}>
            {label}
          </Typography>
        );
      })}
    </Box>
  );
};

export default RangeList;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { NOTIFICATION } from '../utils/constant';
import { endPoints } from '../utils/endPoints';
import { getLoadingEnd, getLoadingStart, loadingInitialize } from './loading';
import * as storage from '../helper/storage'
import { getNotificationStart } from './notification';
import { layoutInitialize } from './layout';
import { filterInitialize } from './filter';
import { transactionInitialize } from './transaction';

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  isForgotPassword: false,
  isSendPasswordRequest: false,
  isResetPassword: false,
  securityQuestions: [],
  clientInformation: {},
  isUpdateQuestion: false,
  user: {},
  error: '',
  defaultPage: ''
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = '';
    },
    getUserFailure(state, action) {
      state.error = action.payload.error;
      state.isAuthenticated = false;
    },
    initialize(state, action) {
      localStorage.removeItem('userData');
      state.isAuthenticated = false;
      state.isInitialised = false;
      state.isForgotPassword = false;
      state.isSendPasswordRequest = false;
      state.user = {};
      state.error = '';
    },
  },
});
export const reducer = slice.reducer;
export const getUser = (requestData) => async (dispatch) => {
  dispatch(initialize());
  dispatch(getLoadingStart('auth'));
  await axios({
    method: 'POST',
    url: endPoints.LOG_IN,
    data: { ...requestData },
  })
    .then(async (res) => {
      dispatch(slice.actions.getUser({ ...res.data }));
      dispatch(getLoadingEnd('auth'));
      localStorage.setItem('token', res.data.TOKEN);
      localStorage.setItem('userData', JSON.stringify(res.data));
      localStorage.setItem('user', requestData.userName);
      storage.removeStorage('authTrial');
      storage.removeStorage('authTrialErrorNum');
      return true;
    })
    .catch(async (err) => {
      dispatch(getLoadingEnd('auth'));
      let authTrialErrorNum = localStorage.getItem('authTrialErrorNum');
      storage.getWithExpiry('authTrial');
      if (!Number(authTrialErrorNum)) {
        storage.setWithExpiry('authTrial', true, 3600000); // 1 hour.
        localStorage.setItem('authTrialErrorNum', 1);
      } else {
        localStorage.setItem('authTrialErrorNum', Number(authTrialErrorNum) + 1);
      }
      if (err?.response?.data?.errorType !== 'INVALID_PASSWORD') {
        dispatch(getNotificationStart(NOTIFICATION.ERROR, err.response?.data?.errorMessage));
      } else {
        dispatch(
          slice.actions.getUserFailure({
            error: "Username/Password combination doesn't match",
          })
        );
      }
      return false;
    });
};
//initialize the status to logout and backward
export const initialize = () => (dispatch) => {
  dispatch(slice.actions.initialize());
};
export const logOut = () => async (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  await dispatch(initialize());
  await dispatch(loadingInitialize());
  await dispatch(layoutInitialize());
  await dispatch(filterInitialize());
  await dispatch(transactionInitialize());
};
export default slice;

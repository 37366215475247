export const removeStorage = (index) => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(index);
    }
  };
  
  export const setWithExpiry = (key, value, ttl) => {
    if (typeof window !== 'undefined') {
      const time = new Date().getTime();
      const item = {
        value,
        expiry: time + ttl,
      };
  
      localStorage.setItem(key, JSON.stringify(item));
    }
  };
  
  export const getWithExpiry = (key) => {
    if (typeof window !== 'undefined') {
      const itemString = localStorage.getItem(key);
      if (!itemString) return null;
  
      const item = JSON.parse(itemString);
      const time = new Date().getTime();
      if (time > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
  
      return item.value;
    }
    return null;
  };
  
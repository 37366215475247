import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import Notifications from "./components/notifications";
import { ThemeProvider } from "@mui/material/styles";
import { createUserTheme } from "./theme";
const theme = createUserTheme();
function App() {
  const router = createBrowserRouter(routes, { basename: process.env.REACT_APP_ROUTER_BASE })

  return (
    <ThemeProvider theme={theme}>
      <Notifications />
      <RouterProvider router={router}/>
    </ThemeProvider>
  );
}

export default App;

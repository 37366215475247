import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import IconButton from '../buttons/CustomIconButton';
import Icon from '../icons'
import Select from '../text-field/customSelect';
import {
  checkValidSelect,
  DAYS_OF_WEEK,
  formatDate,
  getWeekendArrayInMonth,
  MONTHS,
  preprocessDays,
  YEAR_RANGE,
  TIME_HH,
  TIME_MM
} from './utils';



const Calendar = (props) => {
  const classes = {
    root: {
      position: 'relative',
      width: '296px',
      height: props.isTimePicker ? '360px' : '',
      background: '#fff',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
      borderRadius: '3px',
      marginLeft: '2px',
    },
    datepicker: {
      display: 'flex',
      justifyContent: 'space-evenly',
      height: '56px',
      alignItems: 'center',
    },
    timepicker:{
      display: 'flex',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
    },
    month: {
      width: '104px',
      height: '32px',
      paddingRight: '5px',
      '& > .MuiSelect-selectMenu': {
        textOverflow: 'inherit!important',
        backgroundColor: '#fff',
      },
      '& > .MuiOutlinedInput-input': {
        padding: '0 5px !important',
      },
      '& > svg': {
        cursor: 'pointer',
      },
    },
    year: {
      width: '90px',
      height: '32px',
      paddingRight: '5px',
      '& > .MuiSelect-selectMenu': {
        textOverflow: 'inherit!important',
      },
      '& > .MuiOutlinedInput-input': {
        padding: '0 15px !important',
        backgroundColor: '#fff',
      },
    },
    calendar: {
      padding: '10px 23px',
    },
    day: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      '&:hover': {
        backgroundColor: '#DCF4FF',
      },
      borderRadius: '18px',
      cursor: 'pointer',
    },
    dayWidget: {
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      border: 'none',
    },
    activeDayWidget: {
      width: '35px',
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '50%',
      background: '#355EEF',
      color: '#fff',
      '&:hover': {
        background: '#355EEF',
        color: '#fff',
      },
    },
    disabledDayWidget: {
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '18px 18px',
      color: '#A4A4A4',
      cursor: 'pointer',
    },
    rangeDay: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      background: '#DCF4FF',
      border: 'none',
      outline: 'none',
    },
    weekWidget: {
      width: '28px',
      height: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#6d6d6d',
      textAlign: 'center',
      fontWeight: '500',
      fontFamily: 'Inter',
    },
    week: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingBottom: '2px',
    },
    dayInSelectedRange: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      // borderRadius: "0px 50% 50% 0",
      background: '#DCF4FF',
      color: '#000',
    },
    firstDayInSelectedRange: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '50% 0px 0px 50%',
      background: '#DCF4FF',
      color: '#000',
    },
    firstDay: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '50% 0px 0px 50%',
      background: '#DCF4FF',
      color: '#000',
      '& > div': {
        backgroundColor: '#355EEF',
        color: '#fff',
      },
    },
    lastDayInSelectedRange: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '0 50% 50% 0',
      background: '#DCF4FF',
      color: '#000',
    },
    firstDayActiveWidget: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '50%',
      background: '#DCF4FF',
      color: '#000',
    },
    startWeek: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '50% 0 0 50%',
      background: '#DCF4FF',
    },
    endWeek: {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      background: '#DCF4FF',
      borderRadius: '0 50% 50% 0',
    },
    inner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      borderRadius: '100%',
    },
  };
  const {
    setDate,
    range,
    futureSelect,
    endDateDay,
    endDateMonth,
    endDateYear,
    selectedDay,
    setSelectedDay,
    setMonth,
    month,
    setYear,
    year,
    hour,
    setHour,
    minutes,
    setMinutes
  } = props;
  const today = new Date();

  let totalDays = preprocessDays(year, month);

  const lastDayInMonth = totalDays
    .slice(-1)[0]
    .filter((day) => day > 0)
    .slice(-1)[0];

  let { SAT_ARRAY, SUN_ARRAY } = getWeekendArrayInMonth(totalDays);

  // Check a day widget is clicked on calendar pane. This is needed for range selection on first calendar.
  const [selected, setSelected] = useState(-1);

  // Variables for first and second day in first calendar.
  const [secondSelectedDay, setSecondSelecteDay] = useState(-1);

  const onDayClick = (day) => {
    if (day > 0) {
      if (checkValidSelect(day, month, year, endDateDay, endDateMonth, endDateYear, futureSelect)) {
        setSelectedDay(day);
        if (month === endDateMonth && year === endDateYear) {
          setSecondSelecteDay(endDateDay);
        } else {
          setSecondSelecteDay(lastDayInMonth);
        }
      }
    }
  };

  const onMonthClick = (month) => {
    if (checkValidSelect(selectedDay, month, year, endDateDay, endDateMonth, endDateYear, futureSelect)) {
      setMonth(month);
    }
  };

  const onYearClick = (year) => {
    if (checkValidSelect(selectedDay, month, year, endDateDay, endDateMonth, endDateYear, futureSelect)) {
      setYear(year);
    }
  };

  // Disable the second selected day when one day is selected in second calendar.

  const classNameForDayWidget = (classes, day) => {
    let className = 'day';
    if (day < 0) {
      className = 'disabledDayWidget';
    }

    if (day >= selectedDay && day <= secondSelectedDay && day) {
      if (day === selectedDay && SAT_ARRAY.includes(selectedDay)) {
        className = 'firstDayActiveWidget';
      } else if (day === secondSelectedDay && SUN_ARRAY.includes(secondSelectedDay)) {
        className = 'firstDayActiveWidget';
      } else if (day === secondSelectedDay) {
        className = 'lastDayInSelectedRange';
      } else if (SAT_ARRAY.includes(day)) {
        className = 'lastDayInSelectedRange';
      } else if (SUN_ARRAY.includes(day)) {
        className = 'firstDayInSelectedRange';
      } else {
        className = 'dayInSelectedRange';
      }
    }
    if (day === selectedDay) {
      className = 'firstDay';
    }
    return classes[className];
  };

  // Consider the month and year of end date change.
  useEffect(() => {
    if (endDateDay > 0 && year === endDateYear && month === endDateMonth) {
      setSecondSelecteDay(endDateDay);
    } else {
      setSecondSelecteDay(lastDayInMonth);
    }
  }, [endDateMonth, endDateYear, endDateDay, year, month, selectedDay, lastDayInMonth]);

  useEffect(() => {
    setDate([formatDate(selectedDay, month, year), range[1]]);
  }, [selectedDay, month, year]); //eslint-disable-line

  // Handle left and right icon click.
  const forwardMonth = () => {
    const index = MONTHS.indexOf(month);
    if (index < 11) {
      if (!checkValidSelect(selectedDay, MONTHS[index + 1], year, endDateDay, endDateMonth, endDateYear)) return;
      setMonth(MONTHS[index + 1]);
    } else {
      if (!checkValidSelect(selectedDay, MONTHS[0], year + 1, endDateDay, endDateMonth, endDateYear)) return;
      setMonth(MONTHS[0]);
      setYear(year + 1);
    }
  };
  const backwardMonth = () => {
    const index = MONTHS.indexOf(month);
    if (index === 0) {
      if (!checkValidSelect(selectedDay, MONTHS[11], year - 1, endDateDay, endDateMonth, endDateYear)) return;
      setMonth(MONTHS[11]);
      setYear(year - 1);
    } else {
      if (!checkValidSelect(selectedDay, MONTHS[index - 1], year, endDateDay, endDateMonth, endDateYear)) return;
      setMonth(MONTHS[index - 1]);
    }
  };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.datepicker}>
        <IconButton color="secondary" size="small" onClick={backwardMonth}>
          <Icon name="chevron-left" size="sm" />
        </IconButton>
        <Select
          selectList={MONTHS}
          placeholder={month}
          setSelect={(e) => onMonthClick(e)}
          selected={month}
          size="small"
          width="104px"
          helperText="this is for testing"
          dropHeight="200px"
        />
        <Select
          selectList={futureSelect ? YEAR_RANGE : YEAR_RANGE.filter((value) => value < today.getFullYear() + 1)}
          placeholder={year}
          setSelect={(e) => onYearClick(e)}
          selected={year}
          width="72px"
          size="small"
          helperText="this is for testing"
          dropHeight="200px"
        />
        <IconButton color="secondary" size="small" onClick={() => forwardMonth()}>
          <Icon name="chevron-right" size="sm" />
        </IconButton>
      </Box>
      {props.isTimePicker && <Box sx={classes.timepicker}>
        <Select
          selectList={TIME_HH}
          placeholder={month}
          setSelect={(e) => onMonthClick(e)}
          selected='00'
          size="small"
          width="50px"
          helperText="this is for testing"
          dropHeight="200px"
        />
        <Box sx={{marginX: '10px'}}>
          <Typography variant='caption'> : </Typography>
        </Box>
        <Select
          selectList={TIME_MM}
          placeholder={month}
          setSelect={(e) => onMonthClick(e)}
          selected='00'
          size="small"
          width="50px"
          helperText="this is for testing"
          dropHeight="200px"
        />
      </Box> }
      <Box sx={classes.calendar}>
        <Box sx={classes.week}>
          {DAYS_OF_WEEK.map((day, index) => {
            return (
              <Box sx={classes.weekWidget} key={index}>
                {day}
              </Box>
            );
          })}
        </Box>
        <Box onClick={() => setSelected(!selected)}>
          {totalDays.map((row, index) => (
            <Box sx={classes.week} key={index}>
              {row.map((day, ind) => (
                <Box sx={classNameForDayWidget(classes, day)} key={ind} onClick={() => onDayClick(day)}>
                  <Box sx={classes.inner}>{day > 0 ? day : -day}</Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

Calendar.propTypes = {
  setDate: PropTypes.func.isRequired,
  futureSelect: PropTypes.bool,
  endDateMonth: PropTypes.string.isRequired,
  endDateYear: PropTypes.number.isRequired,
  isFirstCalendar: PropTypes.bool.isRequired, // Check for start date or end date.
  initialDate: PropTypes.string, // Get the initial date. Format: 'MM/DD/YYYY'
  endDate: PropTypes.string, // Get the end date. Format:
};

export default Calendar;

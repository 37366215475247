import { useTheme } from "@emotion/react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography,Popover,Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "src/reusable-components/icons";
import CheckList from "src/reusable-components/lists/CustomList";
import { useState } from "react";
export default function UserResults({ results, sortHead, setSortHead, printRef, showRoleData, setResults }) {
    const navigate = useNavigate();
    const initialColumns = [
        { id: 0, name: 'userName', label: 'Username', value: true, sort: true },
        { id: 1, name: 'email', label: 'Email', value: true, sort: false },
        { id: 2, name: 'role', label: 'Role', value: true, sort: false },
        { id: 3, name: 'facility', label: 'Facility', value: true, sort: false },
        { id: 4, name: 'speciality', label: 'Specialty', value: true, sort: false },
        { id: 5, name: 'status', label: 'Status', value: true, sort: false }
      ];
    const theme = useTheme();
    const classes = {
        root: {},
        headCell: {
            color: theme.palette.text.gray,
            padding: '0 10px 5px 0',
            fontSize: '12px',
        },
        headcell: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            flexWrap: 'nowrap',
            justifyContent: 'start',
            whiteSpace: 'nowrap',
        },
        headlabel: {
            fontSize: '12px',
            marginRight: '8px'
        },
        highlightheadlabel: {
            color: '#355EEF',
        },
        bodyCell: {
            verticalAlign: 'middle',
            padding: '9px 15px 9px 0',
            whiteSpace: 'nowrap',
        },
        blueValue: {
            color: theme.palette.text.secondary,
            marginBottom: 0,
            cursor: 'pointer',
        },
        blackValue: {
            color: theme.palette.text.primary,
            marginBottom: 0,
        },
        selected: {
            backgroundColor: 'transparent !important',
            border: `1px solid ${theme.palette.button.blue}`,
        },
        hover: {
            '&:hover': {
            backgroundColor: theme.palette.button.weak,
            },
        },
        dropDown: {
            transition: '0.4s',
        },
        dropUp: {
            transform: 'rotate(180deg)',
        },
        labelIcon: {
            borderRadius: '25px',
            height: '25px',
            width: '25px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
        },
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const [columns, setColumns] = useState(initialColumns);
    const open = Boolean(anchorEl);
    const id = open ? 'edit-columns' : undefined;

    const handleColumns = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleColumnsClose = () => {
        setAnchorEl(null);
    };

    const handleView = (result) => {
        navigate(`/dashboard/users/${result.userId}`)
    }

    const handleHeadClick = (item) => {
        if (item.sort) {
          if (sortHead.label === item.name) {
            setSortHead({
              label: item.name,
              up: !sortHead.up,
            });
          } else {
            setSortHead({
              label: item.name,
              up: false,
            });
          }
        }
    };

    const getContent = (value, key) => {
        if(key === 'status'){
            return <Box display="flex">
                    <Typography display="inline"></Typography>
                    {value ? value : ''} &nbsp;
                    {value.toLowerCase() === 'activated' && <Icon name="success" size="md"className={{marginLeft: '26px'}} />}
                    {value.toLowerCase() === 'locked' && <Icon name="locked" size="md" color="red" className={{marginLeft: '40px'}} />}
                    {value.toLowerCase() === 'deactivated' && <Icon name="reject" size="md" ml={10} color="red"/>}
                </Box>
        }
        else if(key === 'role'){
            return <Typography variant="body1">
                        {value?.map((role, index) => {
                        return (
                            <span key={index}>
                            {role}
                            <br />
                            </span>
                        );
                        })}
                    </Typography>
        }
        else {
            return <Typography variant="body1" sx={key === 'userName' || key === 'email' ? classes.blueValue : classes.blackValue}> {value}</Typography>
            
        }
    }
    const tableCell = (column, result) => {
        const key = column.name;
        return (
          column.value && (
            <TableCell
              key={key}
              sx={classes.bodyCell}
              style={{
                textTransform:  key === 'TransactionType' 
                  ? 'capitalize'
                  : '',
                  width: key === 'status' ? '0%' : ''
              }}
                >
                {getContent(result[key],key,result)}
            </TableCell>
          )
        );
    };
    return (
        <>
        <Table>
            <TableHead style={{ borderBottom: '2px solid black' }}>
                <TableRow>
                {columns
                .filter((item) => item.value)
                .map((item) => (
                    <TableCell
                    sx={classes.headCell}
                    key={item.name}
                    value={item.name}
                    onClick={(e) => handleHeadClick(item)}
                    >
                    <div style={ classes.headcell }>
                        <Typography sx={classes.headlabel}
                                style={item.name === sortHead.label ? classes.highlightheadlabel : {}}
                        >
                        {item.label}
                        </Typography>
                        {item.sort && (
                        <img
                            src="/images/dropdown.svg"
                            style={{...classes.dropDown,...(item.name === sortHead.label && sortHead.up ? classes.dropUp : classes.dropDown)}}
                            alt="dropdown"
                        />
                        )}
                    </div>
                    </TableCell>
                ))}
                <TableCell sx={classes.headCell} align="right" style={{width: '0%'}}>
                    <img src="/images/set-columns.svg" style={{ cursor: 'pointer' }} onClick={handleColumns} alt="setting" />
                </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {results?.map((result, index) => {
                return (
                    <TableRow hover key={index} onClick={() => handleView(result)} style={{ cursor: 'pointer' }}>
                    {columns.map((column) => {
                        return tableCell(column, result);
                    })}
                    <TableCell sx={classes.bodyCell} />
                    </TableRow>
                );
                })}
          </TableBody>
            </Table>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleColumnsClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            >
                <CheckList columns={columns} setColumns={setColumns} onClose={handleColumnsClose} type="claim" />
            </Popover>
    </>
    )
}
import { Box, Grid, Typography } from '@mui/material';
import React, { useState,useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/reusable-components/buttons/CustomButton';
import { changeMultiSelectFormat } from 'src/helper';
import MultiSelect from 'src/reusable-components/multi-select';
import UserBreadcrumbs from 'src/reusable-components/navigation/Breadcrumbs';
import { CustomRadioGroup } from 'src/reusable-components/radios';
import TextField from 'src/reusable-components/text-field';
import { getNotificationStart } from 'src/slices/notification';
import { NOTIFICATION } from 'src/utils/constant';
import { GroupCheckBox } from 'src/reusable-components/checkboxes';
import ReactSelect from 'src/reusable-components/text-field/ReactSelect';
import _ from 'lodash';
import { getFacilities, getSpecialities,getInstances,getRoles} from 'src/slices/filter';
import { addUser } from 'src/slices/management';



const classes = {
  divider: {
    background: '#242424',
    width: '100%',
    height: '2px',
  },
  section: {
    borderBottom: '2px solid #242424',
    paddingBottom: '32px',
    marginBottom: '16px',
  },
  title: {
    color: '#242424',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '15px',
  },
  
  colon:{
    paddingRight: '10px',
    paddingLeft: '2px',
    float: 'right'
  }
};

const statusLabels = ['Active', 'Inactive', 'Locked'];

const personalInfos = [
  { name: 'firstName', label: 'First Name' },
  { name: 'lastName', label: 'Last Name' },
  { name: 'userName', label: 'Username' },
  { name: 'email', label: 'Email' },
];

const AddUser = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [rolesSelected, setRole] = useState([]);
  const [facilitySelected, setFacility] = useState([]);
  const [specialitySelected, setSpeciality] = useState([]);
  const [instanceSelected, setInstance] = useState([]);
  const [specialitySearch, setSpecialitySearch] = useState('');
  const [fsMapping, setFSMapping] = useState([]);
  const status = 'Activate';
  const {facilities,specialties,instances,roles} = useSelector((state) => state.filter);

  const handlePersonalInfo = (item) => {
    return (e) => {
      setPersonalInfo({ ...personalInfo, [item]: e.target.value });
      setError({ ...error, [item]: '' });
    };
  };
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
  });

useEffect(() => {
  setFacility([]);
  setSpeciality([]);
  dispatch(getInstances());
  if (instanceSelected !== '') {
    dispatch(getFacilities());
  }
}, [instanceSelected]); // eslint-disable-line

useEffect(() => {
  setSpeciality([]);
  if (facilitySelected !== '') {
    dispatch(getSpecialities());
  }
}, [facilitySelected]); // eslint-disable-line

useEffect(()=>{
  setFacility([]);
  setSpeciality([]);
  dispatch(getRoles());
},[]); // eslint-disable-line

const InstanceOptions =
    instances?.map((item,index) => ({
      value: index,
      label: item,
    })) || [];

const FacilityOptions =
    facilities?.map((item,index) => ({
      value: index,
      label: item,
    })) || [];
  
  const specialityOptions = specialties ? changeMultiSelectFormat(specialties, 'name') : [];
  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      roleName: '',
      status: 'Active',
      permission: [],
    },
  });
 


  const handleAddFSMapping = () => {
        const tempError = _.cloneDeep(error);
        tempError.fsMapping = '';
        if (instanceSelected === '' || !instanceSelected) {
          tempError.instance = 'This field is required.';
          tempError.facility = 'This field is required.';
          tempError.speciality = 'This field is required.';
        } else if (facilitySelected.length < 1) {
          tempError.facility = 'This field is required.';
          tempError.speciality = 'This field is required.';
        } else if (specialitySelected.length < 1) {
          tempError.speciality = 'This field is required.';
          // tempError.facility = '';
          // tempError.instance = '';
        } else {
          tempError.facility = '';
          tempError.speciality = '';
          tempError.instance = '';
          let temp = _.cloneDeep(fsMapping);
          let flag = false;
          temp.forEach((el) => {
            if (el.facility === facilitySelected.label) {
              flag = true;
              let origin = el.speciality;
              el.speciality = origin.concat(
                specialitySelected.filter((item) => origin.indexOf(item.value) < 0).map((item) => item.value)
              );
            }
          });
          if (!flag) {
            let new_mapping = {};
            new_mapping.instance = instanceSelected.label;
            new_mapping.facility = facilitySelected.label;
            new_mapping.speciality = specialitySelected.map((item) => item.value);
            temp.push(new_mapping);
          }
          setFSMapping([...temp]);
          setFacility(null);
          setSpeciality([]);
          setInstance(null);
        }
        setError(tempError);
      };
      
      const handleDeleteFSMapping = (index) => {
        let temp = _.cloneDeep(fsMapping);
        temp.splice(index, 1);
        setFSMapping([...temp]);
      };

      const handleCancel = () => {
            history('/dashboard/users');
          };
        
          const emailValidate = (email) => {
            return String(email)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
          };
          const nameValidate = (name) => {
            const regEx = /^[a-zA-Z0-9']*$/;
            let result = '';
            if (!name) {
              result = 'This field is required.';
            } else if (name.length < 3 || name.length > 30 || !regEx.test(name)) {
              result = 'Invalid Entry';
            }
            return result;
          };
          const handleValidation = (data) => {
            const temp = {};
            let valid = true;
            if (nameValidate(data.firstName)) {
              temp.firstName = nameValidate(data.firstName);
              valid = false;
            }
            if (nameValidate(data.lastName)) {
              temp.lastName = nameValidate(data.lastName);
              valid = false;
            }
            if (nameValidate(data.userName)) {
              temp.userName = nameValidate(data.userName);
              valid = false;
            }
            if (!emailValidate(data.email)) {
              temp.email = data.email ? 'Invalid Entry.' : 'This field is required.';
              valid = false;
            }
            if (data.roles.length < 1) {
              temp.role = 'This field is required.';
              valid = false;
            }
            if (!data.facilitiesSpeciality) {
              temp.instance = 'This field is required.';
              temp.facility = 'This field is required.';
              temp.speciality = 'This field is required.';
              valid = false;
            }
            setError({ ...temp });
            return valid;
          };
        
          const handleSubmit = async () => {
            let temp = {};
            temp.userName = personalInfo.userName;
            temp.firstName = personalInfo.firstName;
            temp.lastName = personalInfo.lastName;
            temp.email = personalInfo.email;
            if (fsMapping.length > 0) {
              let fsArray = [];
              fsMapping.forEach((item) => {
                let fsObj = {}
                fsObj['facility'] = item.facility;
                fsObj['speciality'] = item.speciality.toString();
                fsObj['instance'] = item.instance;
                fsArray.push(fsObj)
              });
              temp.facilitiesSpeciality = fsArray;
            }
            temp.roles = rolesSelected;
            temp.status = status;
            if (handleValidation(temp)) {
              setLoading(true);
              let createResult = await dispatch(addUser(temp));
              setLoading(false);
              if (createResult) {
                history('/dashboard/users');
                dispatch(getNotificationStart(NOTIFICATION.SUCCESS, 'User Created Successfully.'));
              }
            }
          };

  return (
    <Box px={7} pt={3}>
      <form>
        <Grid container justifyContent="space-between">
          <Grid item>
            <UserBreadcrumbs type="userAdd" />
          </Grid>
        </Grid>
        <Typography variant="h5" style={{ marginTop: 30 }}>
          Add User
        </Typography>
       
        <Box sx={classes.section} mt={2}>
          <Typography variant="subtitle1">Personal Information</Typography>
            <Box display="flex" mt={2} alignItems="flex-start" flexWrap="wrap">
              {personalInfos.map((item, index) => (
                <Box mr={2}>
                  <TextField
                    key={index}
                    value={personalInfo[item.name]}
                    placeholder={item.label}
                    fontSize={12}
                    label={item.label}
                    onChange={handlePersonalInfo(item.name)}
                    width={160}
                    error={error[item.name]}
                  />    
                </Box>
                            
              ))}
          </Box>
        </Box>
        <Box sx={classes.section}>
          <Typography variant="subtitle1">Roles</Typography>
            <Box display="flex" mt={2} alignItems="flex-start" flexWrap="wrap">
              {/* {roles.map((item, index) => ( */}
                <Box display="flex">
                  <GroupCheckBox
                    title=""
                    labelGroup={roles}
                    selectedLabels={rolesSelected}
                    setSelectedLabels={setRole}
                  />
                   {/* <CustomCheckBox
                    label={item}
                    key={index}
                    //  checked={selectedLabels?.indexOf(item) > -1}
                    //  onChange={(e) => handleChange(e, item)}
                  /> */}
                </Box>
              {/* ))} */}
               
          </Box>
          <Typography variant="caption" color="error">
            {error.role}
          </Typography>
        </Box>
        <Box className={classes.section}>
             <Typography variant="subtitle1">Facility & Specialty</Typography>
             <Box display="flex"justifyContent={"space-between"} mt={2}>
              <Box display="flex">
              <Box mr={4}>
                 <Typography variant="caption" style={{ color: '#878787' }}>
                   Instance
                 </Typography>
                 <Box mt={1}>
                   <ReactSelect
                     name="instance"
                     placeholder="Select instance"
                     options={InstanceOptions}
                     changeOption={(option) => {
                       setInstance(option);
                       setError({ ...error, instance: '' });
                     }}
                     value={instanceSelected}
                     size="small"
                     dropdownHeight="200px"
                   />
                   <Typography variant="caption" color="error">
                     {error.instance}
                   </Typography>
                 </Box>
               </Box>
               <Box mr={4}>
                 <Typography variant="caption" style={{ color: '#878787' }}>
                   Facility
                 </Typography>
                 <Box mt={1}>
                   <ReactSelect
                     name="facility"
                     placeholder="Select facility"
                     options={FacilityOptions}
                     changeOption={(option) => {
                       setFacility(option);
                       setError({ ...error, facility: '' });
                     }}
                     value={facilitySelected}
                     size="small"
                     dropdownHeight="200px"
                   />
                   <Typography variant="caption" color="error">
                     {error.facility}
                   </Typography>
                 </Box>
               </Box>
               <Box>
                 <Typography variant="caption" style={{ color: '#878787' }}>
                   Specialty
                 </Typography>
                 <Box mt={1}>
                   <MultiSelect
                     selectList={specialityOptions}
                     selected={specialitySelected}
                     setSelect={setSpeciality}
                     onChange={() => {
                       setError({ ...error, speciality: '' });
                     }}
                     search={specialitySearch}
                     setSearch={setSpecialitySearch}
                     placeholder="Select specialty"
                     size="small"
                     width="200px"
                   />
                   <Typography variant="caption" color="error">
                     {error.speciality}
                   </Typography>
                 </Box>
               </Box>
              </Box>
              <Box className={classes.btnGroup} mt={3}>
                <img
                      src="/images/add.svg"
                      onClick={handleAddFSMapping} 
                      alt="Add"
                      style={{cursor: 'pointer'}}
                    />
             </Box>
             </Box>
             {fsMapping.map((item, index) => (
               <Box className={classes.mapping} mt={2} display="flex" justifyContent={'space-between'} key={index}>
                 <Box>
                    <Box display='table'>
                        <Box display='table-cell'>
                            <Typography variant="body1" style={{ color: '#878787' }}>Instance<span style={classes.colon}>:</span></Typography>
                            <Typography variant="body1" style={{ color: '#878787' }}>Facility<span style={classes.colon}>:</span></Typography>
                            <Typography variant="body1" style={{ color: '#878787' }}>Specialty<span style={classes.colon}>:</span></Typography>
                        </Box>
                        <Box display='table-cell'>
                            <Typography variant="body1">{item.instance}</Typography>
                            <Typography variant="body1">{item.facility}</Typography>
                            <Typography variant="body1">{item.speciality.join(', ')}</Typography>
                        </Box>
                    </Box>
                 </Box>
                 <Box className={classes.icon} onClick={() => handleDeleteFSMapping(index)}>
                   <img
                      src="/images/trush.svg"
                      alt="trush"
                      style={{cursor: 'pointer'}}
                    />
                 </Box>
               </Box>
             ))}
           </Box>
        <Box mt={2}>
          <Typography sx={classes.title}>Status</Typography>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography sx={classes.title} style={{ marginBottom: '-2px' }}>
              Account status
            </Typography>
            <Controller
              control={control}
              name="status"
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomRadioGroup
                  direction="row"
                  ref={ref}
                  labels={statusLabels}
                  selectedLabel={value}
                  name={name}
                  setSelectedLabel={(e) => onChange(e)}
                />
              )}
            />
          </Box>
        </Box>
        {errors.status ? (
          <Typography variant="caption" style={{ color: 'red' }}>
            Status is required.
          </Typography>
        ) : null}
        <Box sx={classes.divider} mt={4} mb={2} />
        <Box display="flex" justifyContent="flex-end" mt={10}>
          <Button size="medium" color="secondary" text="Cancel" onClick={handleCancel}/>
          <Button size="medium" color="primary" text="Add user" type="button" onClick={handleSubmit} disabled={loading}/>
        </Box>
      </form>
    </Box>
  );
};

export default AddUser;
import { Box, Grid, Typography } from "@mui/material";
import UserBreadcrumbs from "src/reusable-components/navigation/Breadcrumbs";
const Header = ({requestId}) => {
    return (
        <Box width="100%">
          <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Grid container justifyContent="space-between">
              <Grid item>
                <UserBreadcrumbs type="transaction-details" param={requestId}/>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ fontSize: '24px' }}>Transactions Details</Typography>
          </Box>
        </Box>
    );
}
export default Header;
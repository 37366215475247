import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../utils/endPoints";
import { getNotificationStart } from "./notification";
import {NOTIFICATION} from '../utils/constant'
const initialState = {
  transactionDetails: []
};

const slice = createSlice({
  name: "transactiondetails",
  initialState,
  reducers: {
    initialize(state, action) {
      state.transactionDetails = [];
    },
    getTransactionDetails(state, action) {
      const payload = action.payload;
      state.transactionDetails = payload;
    }
  },
});

export const reducer = slice.reducer;

export const getTrasactionDetails = (clientRecordId) => async (dispatch) =>{
  await axios({
    method: 'POST',
    url: endPoints.GET_TRANSACTIONDETAILS,
    data: { 
      clientRecordId: clientRecordId
    }
  })
  .then(async (response) =>{
    dispatch(slice.actions.getTransactionDetails(response.data.TransactionStatus))
  })
  .catch((err) => {
    dispatch(
      getNotificationStart(NOTIFICATION.ERROR, err?.response?.data?.errorMessage || 'Error trying to fetch reports.')
    );
  });
}

export default slice;

import { useState,useEffect } from 'react';
import { Box, Typography } from '@mui/material'
import Icon from 'src/reusable-components/icons';
export default function CustomInput({
    isCurrency,
    onChange,
    disabled,
    onBlur,
    onFocus,
    onKeyDown,
    value,
    type: inputType,
    label,
    focusRef,
    strengthArr,
    width,
    height,
    margin,
    paddingRight,
    error,
    marginTop,
    noLabel
}) {
    const displayValue = value === 0 ? 0 : value || '';
    const [isFocused, setIsFocused] = useState(false);
    const [type, setType] = useState(inputType || 'text');
    const toggleType = () => {
        type === 'password' && setType('text');
        type === 'text' && setType('password');
    };
    useEffect(() => {
        if (isCurrency && !isFocused) {
          setType('text');
        } else if (isCurrency && isFocused) {
          setType('number');
        }
      }, [isCurrency, isFocused]);
    const getValue = () => (isFocused ? displayValue : isCurrency && displayValue ? displayValue : displayValue);
    const handleChange = (e) => {
        const intAndDecimal = e.target.value.split('.');
        if (inputType === 'number' && intAndDecimal.length === 2 && intAndDecimal[1].length > 2) {
          e.preventDefault();
        } else {
          onChange(e);
        }
    };
    const handleKeyDown = (e) => {
        onKeyDown && onKeyDown(e);
  };
    const classes = {
      icon: {
        position: 'absolute',
        right: '10px',
        top: '9px',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    }
    return (
        <Box sx={{
            width: width ? width : '100%',
            height: height ? height : '76px',
            color: '#878787',
            margin: margin ? margin : '0px',
            marginTop: marginTop ? marginTop : '5px'
        }}>
        <Typography sx={{fontSize: '12px',
                padding: noLabel ? '0px' : '5px 0px'
            }}>{label}
        </Typography>
            <div style={{ position: 'relative' }}>
            <input
                  style={{
                      width: '100%',
                      paddingLeft: '10px',
                      borderRadius: '4px',
                      height: '36px',
                      border: 'solid 1px',
                      borderColor: error ? '#F84F4F' : '#878787',
                      WebkitText: 'square',
                      paddingRight: paddingRight || '10px',
                      fontFamily: 'Inter',
                      fontWeight: 500
            }}
              ref={focusRef}
              type={type}
              onChange={handleChange}
              disabled={disabled}
              onBlur={(e) => {
                onBlur && onBlur(e);
                if (e.target.value === '' && inputType === 'number') {
                  let event = {};
                  event.target = {};
                  event.target.value = 0;
                  onChange(event);
                }
                setIsFocused(false);
              }}
              onFocus={(e) => {
                onFocus && onFocus(e);
                setIsFocused(true);
              }}
              onKeyDown={handleKeyDown}
              value={getValue()}
                />
                {inputType === 'password' && (
                    <Icon
                      name={type === 'password' ? 'visibility-off' : 'visibility-on'}
                      className={classes.icon}
                      onClick={toggleType}
                      size="md"
                    />
                    )}
        </div>
        <div style={{color: 'red',
              fontSize: '12px',
              lineHeight: '12px',}}>{error}</div>
        </Box>
    )
    
}
import { Box } from "@mui/material"
import Header from './Header'
import Details from "./Details"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getTrasactionDetails } from "src/slices/transactionDetails"
import { useParams } from "react-router-dom"
export default function TransactionDetails() {
    const { tid } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTrasactionDetails(tid))
    },[dispatch,tid]);
    return (
        <Box display="flex">
            <Box px={7} flex="1" sx={{
                height: 'calc(100vh - 55px)',
                overflow: 'auto',
            }}>
            <Header requestId={tid}/>
            <Details requestId={tid} />
            </Box>
        </Box>
    )
}
import React, { useState } from 'react';
import { useTheme , Box, Card, Collapse, Grid, Typography } from '@mui/material';
import { NOTIFICATION } from 'src/utils/constant';
import Icon from 'src/reusable-components/icons';
import Button from '../../buttons/CustomButton';
import { useDispatch , useSelector} from 'react-redux';
import { getNotificationStart } from 'src/slices/notification';
import { resetPasswordMailRequest } from 'src/slices/userInfo';


const User = ({data}) => {
    const theme = useTheme();
    const classes = {
        header: {
          backgroundColor: '#A53DB6',
          color: '#FFFFFF',
          cursor: 'pointer',
        },
        bottom: {
          backgroundColor: '#F3F1EE',
          color: '#355EEF',
          justifyContent: 'flex-end',
        },
        colon:{
          paddingRight: '10px',
          paddingLeft: '2px',
          float: 'right'
        }
      };
    //   const {data} = useSelector((state) => state.userDetails);


  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const handleActivate = async () => {
    // const temp = {};
    // temp.status = 'Activated';
    // temp.userId = data.id;
    // temp.reason = '';
    // const res = await dispatch(updateStatus(temp));
    // if (res) {
    //   dispatch(getUserCard({ userUUID }));
    // } else {
    //   dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Action Failed. Please try again.'));
    // }
  };

  const handleResetPassword = async () => {
    let temp = {};
    temp.resetMailID = data.Email;
    const res = await dispatch(resetPasswordMailRequest(temp));
    if (res) {
      dispatch(getNotificationStart(NOTIFICATION.SUCCESS, 'Email has been sent. Please check your email.'));
    } else {
      dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Action Failed. Please try again.'));
    }
  };

  return (
    <Card>
      <Box px={3} py={1.2} display="flex" sx={classes.header} onClick={handleClick}>
        <Icon name="more" size="md" color="white" />
        <Typography variant="subtitle1" style={{ marginLeft: 15 }}>
          User: {data.name}
        </Typography>
      </Box>
      <Box>
        <Collapse in={isOpen}>
          <Box px={3} py={4} style={{ backgroundColor: '#FBFAF9' }}>
            <Box display="flex" minHeight="100px">
            <Grid container>
              <Grid item md={4} xs={12}>
                  <Box display='table'>
                      <Box display='table-cell'>
                          <Typography variant="body1" style={{ color: '#878787' }}>Username <span style={classes.colon}>:</span></Typography>
                          <Typography variant="body1" style={{ color: '#878787' }}>Role <span style={classes.colon}>:</span></Typography>
                      </Box>
                      <Box display='table-cell'>
                          <Typography variant="body1">{data.userName}</Typography>
                          <Typography variant="body1">{data.role?data.role.toString():''}</Typography>
                      </Box>
                  </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                  <Box display='table'>
                      <Box display='table-cell'>
                          <Typography variant="body1" style={{ color: '#878787' }}>Email <span style={classes.colon}>:</span></Typography>
                      </Box>
                      <Box display='table-cell'>
                          <Typography variant="body1">{data.Email}</Typography>
                      </Box>
                  </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                  <Box display='table'>
                      <Box display='table-cell'>
                          <Typography variant="body1" style={{ color: '#878787' }}>Specialty <span style={classes.colon}>:</span></Typography>
                      </Box>
                      <Box display='table-cell'>
                          <Typography variant="body1">{data.speciality}</Typography>
                      </Box>
                  </Box>
              </Grid>
              </Grid>
            </Box>
          </Box>
          <Box px={3} py={1.2} display="flex" sx={classes.bottom}>
            <Typography variant="overline" style={{ marginRight: 16, cursor: 'pointer' }} onClick={handleResetPassword}>
              Reset Password
            </Typography>
            {/* {data.status === 'Deactivated' && (
              <Button size="small" color="primary" text="Activate" onClick={handleActivate} />
            )}
            {data.status === 'Locked' && <Button size="small" color="primary" text="Unlock" onClick={handleActivate} />} */}
          </Box>
        </Collapse>
      </Box>
    </Card>
  );
};

export default User;

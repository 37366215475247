import {useTheme, Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch ,useSelector} from 'react-redux';
import CustomButton from 'src/reusable-components/buttons/CustomButton';
import Icon from 'src/reusable-components/icons';
import { Status } from 'src/reusable-components/text-field';
import { getUserDetails, updateStatus } from 'src/slices/userInfo';
import { NOTIFICATION } from 'src/utils/constant';
import { getNotificationStart } from 'src/slices/notification';
import { useNavigate,useParams } from 'react-router-dom';

const classes = {
  paper: {
    position: 'absolute',
    width: '520px',
    maxWidth: 'calc(100% - 64px)',
    backgroundColor: '#fff',
    borderRadius: '5px',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    overflowY: 'auto',
    outline: 'none'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  header: {
    height: '40px',
    backgroundColor: '#e5e5e5',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
};

const EditStatus = (props) => {
  const { open, onCancel, userId, status } = props;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const { userDetails } = useSelector((state) => state.userdetails);
  const { uid } = useParams();
  const handleApply = async () => {
    
    const temp = {};
    temp.status = selected;
    temp.userId = uid;
    const res = await dispatch(updateStatus(temp));
    const userId = uid;
    if (res) {
      onCancel();
      dispatch(getUserDetails({userId}));
    } else {
      dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Action Failed. Please try again.'));
    }
    setOpenStatusModal(false);
  }

  useEffect(() => {
    setSelected(status);
  }, [open]); // eslint-disable-line

  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={classes.paper}>
        <Box sx={classes.header}>
          <Typography sx={classes.label}>Status</Typography>
          <Icon name="cancel" size="sm" sx={classes.closeIcon} onClick={onCancel} />
        </Box>
        <Box padding={3}>
          <Box sx={classes.item}>
            <Box display="grid" flex={1.5}>
              <Typography sx={classes.label}>Change Status to</Typography>
            </Box>
            <Box display="grid" flex={3}>
              <Status placeholder={selected} selected={selected} setSelect={setSelected} size="small" type="active" width="100%" />
            </Box>
          </Box>
          <Box pt={5} pb={2} display="flex" justifyContent="flex-end" alignItems="center">
            <CustomButton text="Cancel" color="secondary" size="large" onClick={() => onCancel()} />
            <CustomButton text="Apply" color="primary" size="large" disabled={loading} onClick={handleApply} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditStatus;

import { Box, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import Button from 'src/reusable-components/buttons/CustomButton';
import MultiSelect from 'src/reusable-components/multi-select';
import UserBreadcrumbs from 'src/reusable-components/navigation/Breadcrumbs';
import Textfield from 'src/reusable-components/text-field';
import { getNotificationStart } from 'src/slices/notification';
import { NOTIFICATION } from 'src/utils/constant';
import LoadingScreen from 'src/components/LoadingScreen';
import EditRoleStatus from './roleDetails/EditRoleStatus';
import { getRoleDetailInfo, setRoleUpdatedStatus, updateRole } from 'src/slices/management';

const classes = {
  divider: {
    background: '#242424',
    width: '100%',
    height: '2px',
  },
  section: {
    borderBottom: '2px solid #242424',
    paddingBottom: '32px',
    marginBottom: '16px',
  },
  title: {
    color: '#242424',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '15px',
  },
  change_status: {
    textDecoration: 'underline',
    color: '#355eef',
    cursor: 'pointer',
  },
};

const EditRole = () => {
  const dispatch = useDispatch();
  const { rid } = useParams();
  const navigate = useNavigate();

  const { roleDetail, rolePermissions, roleUpdated } = useSelector((state) => state.management);

  useEffect(() => {
    dispatch(getRoleDetailInfo(rid));
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(getRoleDetailInfo(rid));
  }, [roleUpdated]); // eslint-disable-line

  const permissionOptions = () => {
    const options = rolePermissions.map((role) => ({
      value: role,
      id: role,
    }));
    return options;
  };

  const [updatedRole, setUpdatedRole] = useState({
    roleName: '',
    permission: [],
    status: '',
    roleId: rid,
  });

  const [validationError, setValidationError] = useState({
    roleName: '',
    permission: '',
  });

  const [updating, setUpdating] = useState(false);
  const [openRoleStatusUpdateModal, setOpenRoleStatusUpdateModal] = useState(false);
  const [permissionSearch, setPermissionSearch] = useState('');
  useEffect(() => {
    
    if (roleDetail.role.permissions) {
      setUpdatedRole({
        roleName: roleDetail.role.role,
        permission: [
          ...roleDetail.role.permissions?.split(',').map((e) => ({
            value: e,
            id: e,
          })),
        ],
        status: roleDetail.role.status,
        roleId: rid,
      });
    }
  }, [roleDetail]); // eslint-disable-line

  const onChangeInput = (name, value) => {
    setUpdatedRole({
      ...updatedRole,
      [name]: value,
    });
    if (value) {
      setValidationError({
        name: '',
      });
    }
  };

  const onSubmit = async () => {
    if (!updatedRole.roleName || !updatedRole.permission.length || !updatedRole.status) {
      setValidationError({
        roleName: updatedRole.roleName ? '' : 'Role name is required',
        permission: updatedRole.permission.length ? '' : 'Permission is required',
        status: updatedRole.status ? '' : 'Status is required.',
      });
      return;
    }
    setUpdating(true);

    dispatch(setRoleUpdatedStatus(false));
    const toBeUpdatedRole = {
      ...updatedRole,
      permission: updatedRole.permission.map((e) => e.value),
    };
    delete toBeUpdatedRole.status;

    const res = await updateRole(toBeUpdatedRole);
    if (res.status === 200) {
      dispatch(getNotificationStart(NOTIFICATION.SUCCESS, 'Role updated successfully'));
      dispatch(setRoleUpdatedStatus(true));
      setUpdating(false);
      navigate(-1);
    } else {
      dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Something went wrong. Please try again later.'));
      setUpdating(false);
    }
  };
  return (
    <Box px={7} pt={3}>
      <form>
        <Grid container justifyContent="space-between">
          <Grid item>
            <UserBreadcrumbs type="roleEdit" />
          </Grid>
        </Grid>
        <Typography variant="h5" style={{ marginTop: 30 }}>
          Edit Role
        </Typography>
        {updating || (!updatedRole.roleName && <LoadingScreen />)}
        <Box sx={classes.section} mt={5}>
          <Box display="flex" mt={2} alignItems="flex-start" flexWrap="wrap">
            <Box>
              <Typography sx={classes.title}>Role Name</Typography>
              <Textfield
                value={updatedRole.roleName}
                placeholder="Enter role name"
                width="220px"
                onChange={(e) => onChangeInput('roleName', e.target.value)}
                error={validationError.roleName}
                disabled
              />
            </Box>
            <Box ml={5}>
              <Typography sx={classes.title}>Permissions</Typography>
              <MultiSelect
                selectList={permissionOptions()}
                width="220px"
                style={{ marginTop: '20px' }}
                setSelect={(e) => onChangeInput('permission', e)}
                selected={[...updatedRole.permission]}
                search={permissionSearch}
                setSearch={setPermissionSearch}
                placeholder={'Permissions'}
                error={validationError.permission}
              />
              <Box mt={1}>
                <Typography variant="caption" style={{ color: '#878787' }}>
                  {updatedRole?.permission.map((e) => e.value).join(', ')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography sx={classes.title} style={{fontSize: 16}}>Status</Typography>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography sx={classes.title} style={{ marginBottom: '-2px' }}>
              Account status
            </Typography>
            <Typography ml={5} >
                {updatedRole?.status}
                <span style={classes.change_status} onClick={() => setOpenRoleStatusUpdateModal(true)}>(Change status)
                </span>
          </Typography>
          </Box>
        </Box>
        <Box sx={classes.divider} mt={4} mb={2} />
        <Box display="flex" justifyContent="flex-end" mt={10}>
          <Button size="medium" color="secondary" text="Cancel" />
          <Button size="medium" color="primary" text="Save" type="button" onClick={onSubmit} disabled={updating}
        />
        </Box>
      </form>
      <EditRoleStatus
        open={openRoleStatusUpdateModal}
        onCancel={() => setOpenRoleStatusUpdateModal(false)}
        roleId={rid}
        status={updatedRole?.status}
      />
    </Box>
  );
};

export default EditRole;

import { createTheme } from "@mui/material/styles";
import typography from "./typography"
import { colors } from "@mui/material";
import _ from "lodash";
import { softShadows } from "./shadows";
const baseOptions = {
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
            borderRadius: 3,
            overflow: 'hidden',
            },
        },
        MuiListItemIcon: {
            root: {
            minWidth: 32,
            },
        },
        MuiChip: {
            root: {
            backgroundColor: 'rgba(0,0,0,0.075)',
            },
        },
    },
}
const themeOptions =
{
    type: 'LIGHT',
    overrides: {
        MuiInputBase: {
            input: {
                '&::placeholder': {
                    opacity: 1,
                    color: colors.blueGrey[600],
                },
            },
        },
    },
    palette: {
        type: 'light',
        action: {
            active: colors.blueGrey[600],
        },
        background: {
            default: colors.common.white,
            dark: '#f3f4f4',
            paper: colors.common.white,
            black: '#000000',
            select: '#F8F1FF',
            green: '#07BDA7',
            'black-green': '#148578',
            gray: '#878787',
            darkGray: '#6D6D6D',
            yellow: '#F3BE00',
            gray_1: '#F9F9F9',
            gray_2: '#F3F4F4',
        },
        default: {
            main: '#000000',
        },
        primary: {
            main: '#773DFF',
        },
        secondary: {
            main: '#773DFF',
        },
        text: {
            default: '#6D6D6D',
            primary: colors.common.black,
            secondary: '#4770FF',
            gray: '#878787',
            neutral: '#242424',
            dark: '#242424',
            gray_1: '#6D6D6D',
            gray_2: '#878787',
            gray_3: '#A4A4A4',
            gray_4: '#BDBDBD',
            white: '#FFFFFF',
        },
        icon: {
            dark: '#6D6D6D',
            blue: '#4770FF',
        },
        line: {
            dark: '#242424',
            gray_1: '#D6D6D6',
            gray_2: '#E2E2E0',
        },
        button: {
            blue: '#355EEF',
            weak: '#DCF4FF',
        },
        accent: {
            status: {
                red: '#F84F4F',
                yellow: '#F38E00',
                green: '#46CC2E',
            },
            highlight: {
                yellow_1: '#F3BE00',
                yellow_2: '#E28B09',
                orange_1: '#F58749',
                orange_2: '#E55400',
                teal_1: '#07BDA7',
                teal_2: '#028B7B',
                purple_1: '#C95FFB',
                purple_2: '#A53DB6',
            },
        },
    },
    product: {
        practice: '#B4A291',
        redactit: '#16B7FF',
        measures: '#897EFF',
        radflo: '#F84F4F',
        coding: '#C95FFB',
        billing: '#07BDA7',
    },
    shadows: softShadows,
};
export const createUserTheme = () => {
    let theme = createTheme(_.merge({},baseOptions, themeOptions));
    return theme
}
import React from 'react';
import { Box } from '@mui/material';
import Client from './client';
import User from './user';


const Cards = ({ type, data, subType, setOpenContactBox, userUUID, id }) => (
  <Box>
    {type === 'client' && <Client data={data} subType={subType} />}
    {type === 'user' && <User data={data} />}
  </Box>
);

export default Cards;

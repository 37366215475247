export const getBreadCrumbData = (page,param) => {
    switch (page) {
        case 'home':
            return [
                {
                    name: 'Home',
                    link: '/dashboard/home',
                },
            ];
        case 'transaction':
            return [
                {
                    name: 'Transactions',
                    link: '/dashboard/transaction',
                },
            ];
        case 'transaction-details':
            return [
                {
                    name: 'Transactions',
                    link: '/dashboard/transaction',
                },
                {
                    name: 'Transactions Details',
                    link: `/dashboard/transaction/${param}`,
                },
            ];
        case 'users':
            return [
                {
                    name: 'Users',
                    link: '/dashboard/users',
                }
            ];
            case 'userDetails':
                return [
                    {
                        name: 'Users',
                        link: '/dashboard/users',
                    },
                    {
                        name: 'User Details',
                        link: `/dashboard/users/${param}`,
                    }
                ];
         case 'userAdd':
                return [
                    {
                        name: 'Users',
                        link: '/dashboard/users',
                    },
                    {
                        name: 'Add User',
                        link: '/dashboard/users/new',
                    },
                ];
        case 'userEdit':
            return [
                {
                name: 'Users',
                link: '/dashboard/users',
                },
                {
                name: 'User Details',
                link: `/dashboard/users/${param}`,
                },
                {
                name: 'Edit User',
                link: '#',
                },
            ];
        case 'roles':
            return [
                {
                    name: 'Roles',
                    link: '/dashboard/roles',
                },
            ];
        case 'roleDetails':
            return [
                {
                    name: 'Roles',
                    link: '/dashboard/roles',
                },
                {
                    name: 'Role Details',
                    link: `/dashboard/roles/${param}`,
                },
            ];
        case 'roleAdd':
            return [
                {
                    name: 'Roles',
                    link: '/dashboard/roles',
                },
                {
                    name: 'Add Role',
                    link: `/dashboard/roles/add`,
                },
            ];
        case 'roleEdit':
            return [
                {
                    name: 'Roles',
                    link: '/dashboard/roles',
                },
                {
                    name: 'Edit Role',
                    link: `/dashboard/roles/edit/${param}`,
                },
            ];
        case 'clients':
        return [
            {
                name: 'Client',
                link: '/dashboard/clients',
            },
        ];
        case 'clientDetails':
            return [
                {
                    name: 'Client',
                    link: '/dashboard/clients',
                },
                {
                    name: 'Client Details',
                    link: `/dashboard/clients/${param}`,
                },
        ];
        default:
            return [];
    }
    
  };
  

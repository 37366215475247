import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  notifications: [],
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getNotificationStart(state, action) {
      state.notifications.push(action.payload);
    },
    getNotificationEnd(state, action) {
      let newNotifications = state.notifications.filter((item, idx) => item.id !== action.payload);
      state.notifications = newNotifications;
    },
  },
});

export const reducer = slice.reducer;

export const getNotificationStart = (type, notification) => async (dispatch) => {
  let id = uuidv4();
  dispatch(slice.actions.getNotificationStart({ id, type, notification }));
};

export const getNotificationEnd = (id) => async (dispatch) => {
  dispatch(slice.actions.getNotificationEnd(id));
};

export default slice;

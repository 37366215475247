import { FormControlLabel, FormGroup, Switch,switchClasses  } from "@mui/material";
import { styled } from "@mui/material/styles";
const SwitchTextTrack = styled(Switch)(({ theme }) => ({
    width: 95,
    height: 25,
    padding: 0,
    [`& .${switchClasses.switchBase}`]: {
      padding: 3,
      color: "#fff",
      [`& .${switchClasses.input}`]: {
        left: '0%',
        width: '380%'
      },
    },
    
    [`& .${switchClasses.thumb}`]: {
      width: 19,
      height: 19,
      backgroundColor: "#fff",
    },
    [`& .${switchClasses.track}`]: {
      background: '#46CC2E',
      opacity: "1 !important",
      borderRadius: 20,
      position: "relative",
      "&:before, &:after": {
        display: "inline-block",
        position: "absolute",
        top: "50%",
        width: "50%",
        transform: "translateY(-50%)",
        color: "#fff",
        textAlign: "center",
        fontSize: "0.75rem",
        fontWeight: 500,
      },
      "&:before": {
        content: `"Encode"`,
        left: 15,
        top: 12,
        opacity: 0,
      },
      "&:after": {
        content: `"Decode"`,
        right: 17,
        top: 12
      },
    },
    [`& .${switchClasses.checked}`]: {
      [`&.${switchClasses.switchBase}`]: {
        transform: "translateX(70px)",
        [`& .${switchClasses.input}`]: {
          left: '-285%',
          width: '380%'
        },
      },
      [`& .${switchClasses.thumb}`]: {
        backgroundColor: "#fff",
      },
      [`& + .${switchClasses.track}`]: {
        background: `${theme.palette.button.blue} !important`,
        "&:before": {
          opacity: 1,
        },
        "&:after": {
          opacity: 0,
        },
      },
    },
}));

const SwitchToggle = ({checked, handleCheck}) =>{
    return(
        <FormGroup>
            <FormControlLabel
                control={
                    <SwitchTextTrack
                    // onChange={handleCheck}
                      checked={checked}
                      onClick={handleCheck} 
                    />
                }
            />
        </FormGroup>
    )
}
export default SwitchToggle
 const typography = {
    h1: {
        fontWeight: 700,
        fontSize: 68,
        lineHeight: '68px',
        fontFamily: 'Inter, sans-serif',
      },
      h2: {
        fontWeight: 600,
        fontSize: 50,
        lineHeight: '50px',
        fontFamily: 'Inter, sans-serif',
      },
      h3: {
        fontWeight: 700,
        fontSize: 42,
        lineHeight: '42px',
        fontFamily: 'Inter, sans-serif',
      },
      h4: {
        fontWeight: 700,
        fontSize: 36,
        lineHeight: '36px',
        fontFamily: 'Inter, sans-serif',
      },
      h5: {
        fontWeight: 700,
        fontSize: 22,
        lineHeight: '26px',
        fontFamily: 'Inter, sans-serif',
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '20px',
        fontFamily: 'Inter, sans-serif',
      },
      body1: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '22px',
        fontFamily: 'Inter, sans-serif',
      },
      body2: {
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '20px',
        fontFamily: 'Inter, sans-serif',
      },
      // selection of text in design system
      subtitle2: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '22px',
        fontFamily: 'Inter, sans-serif',
      },
      caption: {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '16px',
        fontFamily: 'Inter, sans-serif',
      },
      button: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        fontFamily: 'Inter, sans-serif',
      },
      overline: {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '20px',
        fontFamily: 'Inter, sans-serif',
        textTransform: 'none',
      },
      inherit: {
        fontWeight: 500,
        fontFamily: 'Inter, sans-serif',
     },
     info: {
         fontWeight: 500,
         fontSize: 13,
         lineHeight: '20px',
         fontFamily: 'Inter, sans-serif',
     }
}
export default typography
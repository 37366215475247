import { Box } from "@mui/material"
import { getClients } from "src/slices/filter";
import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import Filter from "./Filter"
import Header from './Header'
import Details from './Details'
import { getUsers,setPage,setLimit } from "src/slices/userlist";

export default function Users() {
    


    const { currentUserFilter, defaultUserFilter } = useSelector((state) => state.filter);
    const {page,limit} = useSelector((state)=>state.userlist);
    const dispatch = useDispatch();
    const getUserData = (currentFilter, pgNum, limNum,orderBy,direction) => {
        let pageNum = pgNum ? pgNum : page;
        let limitCount = limNum ? limNum : limit;
        if (Object.keys(currentFilter).length > 0) {
          let filter = {};
          currentFilter.client && (filter.client = currentFilter.client);
          currentFilter.roles && (filter.roles = currentFilter.roles);
          currentFilter.status && (filter.status = currentFilter.status);
          currentFilter.userName && (filter.userName = currentFilter.userName);
          currentFilter.lastName && (filter.lastName = currentFilter.lastName);
          currentFilter.firstName && (filter.firstName = currentFilter.firstName);
          currentFilter.email && (filter.email = currentFilter.email);
          filter.startRow = (pageNum - 1) * limitCount + 1;
          filter.rowsToFetch = limitCount;
          filter.sort = direction ? 'ASC' : 'DESC';
          filter.orderBy = orderBy || '';
          dispatch(getUsers(filter));
        }
    };
    const fetchDefaultUserData = (pgNum, limNum,orderBy,direction) => {
      getUserData(currentUserFilter, pgNum, limNum,orderBy,direction);
    };
    useEffect(() => {
        dispatch(getClients())
        getUserData(defaultUserFilter, 1, 50);
    },[]); // eslint-disable-line

    return (
        <Box display="flex">
            <Filter fetchUserData={getUserData} setPage={setPage} />
            <Box px={7} flex="1" sx={{
                height: 'calc(100vh - 55px)',
                overflow: 'auto',
            }}>
            <Header/>
            <Details 
              fetchUserData={fetchDefaultUserData}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
            </Box>
        </Box>
    )
}

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../utils/endPoints";
import { getNotificationStart } from "./notification";
import {NOTIFICATION} from '../utils/constant'
const initialState = {
  userDetails: []
};

const slice = createSlice({
  name: "userdetails",
  initialState,
  reducers: {
    initialize(state, action) {
      state.userDetails = [];
    },
    getUserDetails(state, action) {
      const payload = action.payload;
      state.userDetails = payload;

      // state.total = payload?.length || 0;
    }
  },
});

export const reducer = slice.reducer;

export const getUserDetails = (requestId) => async (dispatch) =>{
  await axios({
    method: 'POST',
    url: endPoints.GET_USERDETAILS,
    data: { ...requestId }
  })
  .then(async (response) =>{
    dispatch(slice.actions.getUserDetails(response.data.usersDetails))
  })
  .catch((err) => {
    dispatch(
      getNotificationStart(NOTIFICATION.ERROR, err?.response?.data?.errorMessage || 'Error trying to fetch reports.')
    );
  });
}

export const updateStatus = (data) => async (dispatch) => {
    try {
      await axios({
        method: 'POST',
        url: endPoints.UPDATE_USER_STATUS,
        data: { ...data },
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  export const resetPasswordMailRequest = (data) => async (dispatch) => {
    try {
      await axios({
        method: 'POST',
        url: endPoints.RESET_PASSWORD_MAIL_REQUEST,
        data: { ...data },
      });
      return true;
    } catch (error) {
      return false;
    }
  };

export default slice;

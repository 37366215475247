import { useTheme } from "@emotion/react";
import NavBar from "./NavBar";
import Header from "./Header";
import { Outlet,Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getFacilities, getClients, getInstances, getSpecialities, getTransactionTypes } from "src/slices/filter"
import { getPermissionsList, getDemoAPI } from "src/slices/management";


export default function Layout() {
    const theme = useTheme();
    const classes = {
    root: {
        backgroundColor: theme.palette.background.white,
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
        },
    content: {
        flex: '1',
        height: '100vh',
        maxHeight: '100vh',
        overflow: 'auto',
        width: '100%',
        paddingTop: 55,
        }
    }
    var token = localStorage.getItem('token');
    const dispatch = useDispatch();
    // eslint-disable-next-line
    useEffect(() => {
        dispatch(getFacilities())
        dispatch(getClients())
        dispatch(getSpecialities())
        dispatch(getInstances())
        dispatch(getTransactionTypes())
        dispatch(getPermissionsList())
        // dispatch(getDemoAPI())
        
    },[]); // eslint-disable-next-line
    return (
        <div style={classes.root}>
            <NavBar />
            <Header />
            <div style={classes.content}>
                {token ? <Outlet /> : <Navigate to='/auth/login' />}
            </div>
        </div>
    );
}
import FilteringList from "src/reusable-components/lists";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMemo } from "react";
import { changeMultiSelectFormat } from "src/helper";
import { setCurrentFilter } from "src/slices/filter";
const Filter = React.memo(() => {
    const dispatch = useDispatch();
    const setNewFilter = (curFilter) => {
        dispatch(setCurrentFilter(curFilter, 'role'));
      };
    const { rolePermissions } = useSelector((state) => state.management);
    const rolesData = useMemo(() => {
        let rolesData = {};
        rolesData.permissions = rolePermissions ? changeMultiSelectFormat(rolePermissions) : [];
        return rolesData;
    }, [rolePermissions]);
    return (
        <FilteringList 
            type="roles" 
            data={rolesData}
            setNewFilter={setNewFilter}
        />
    )
});
export default Filter
// get {id:, value:}, from {id:, name} ===================
export const changeMultiSelectFormat = (value) => {
    return value.map((item, idx) => ({
      id: idx,
      value: item,
    }));
};

export const changeReactSelectFormat = (value) => {
  return value.map((item,index) => ({
    value: index,
    label: item,
  }));
}

export const getObjectFromList = (objList, list, label) => {
  let result = [];
  list.forEach((item) => {
    let idx = objList.findIndex((obj) => obj[label]?.toString() === item?.toString());
    if (idx >= 0) result.push(objList[idx]);
  });
  return result;
};

export const getIdListFromObjList = (objList) => {
  return objList.map((item, idx) => item.id);
};

export const getValueListFromObjList = (objList) => {
  return objList.map((item, idx) => item.value);
};

export const capitalizeFirstLetter = (string) => {
  if(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

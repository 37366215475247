import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../utils/endPoints";
import { getNotificationStart } from "./notification";
import {NOTIFICATION} from '../utils/constant'
import { getLoadingStart,getLoadingEnd } from "./loading";
const initialState = {
  rolePermissions: [],
  roles : [],
  clients: [],
  totalRolesCount: 0,
  roleDetail : {
    "total": 0,
    "userDetailsMappedInRole": [
        {
            "speciality": "",
            "role": [],
            "userName": "",
            "userId": "",
            "facility": "",
            "email": "",
            "status": ""
        }
    ],
    "role": {
        "role": "",
        "permissions": "",
        "usersCount": "",
        "status": ""
    }
  },
  clientDetail: {},
  roleUpdated: false,
  clientUpdated: false,
};

const slice = createSlice({
  name: "management",
  initialState,
  reducers: {
    initialize(state, action) {
      state.rolePermissions = [];
      state.roles = [];
      state.clients = [];
      state.totalRolesCount = 0;
    },
    getPermissions(state, action) {
      const payload = action.payload;
      const temp = payload.sort();
      state.rolePermissions = temp;
      state.error = '';
    },
    getPermissionsFailure(state, action) {
      state.error = action.payload.error;
    },
    getRolesList(state, action) {
      const {total, roles} = action.payload;
      state.roles = roles;
      state.totalRolesCount = total;
    },
    getRoleDetails(state, action) {
      state.roleDetail = action.payload;
    },
    setRoleUpdated(state, action) {
      state.roleUpdated = action.payload;
    },
    setClientUpdated(state, action) {
      state.clientUpdated = action.payload;
    },
    getClientList(state, action) {
      const {clientDetails} = action.payload;
      state.clients = clientDetails;
    },
    getClientDetails(state, action) {
      state.clientDetail = action.payload;
    },
  },
});

export const reducer = slice.reducer;

//get Permissions List for filter
export const getPermissionsList = () => async (dispatch) => {
    await axios({
      method: 'GET',
      url: endPoints.GET_PERMISSIONSLIST
    })
      .then(async (res) => {
        dispatch(slice.actions.getPermissions(res.data.permissions || []));
      })
      .catch(async (err) => {
        dispatch(
          slice.actions.getPermissionsFailure({
            error: 'get Permission List failed',
          })
        );
      });
  };

  export const getRolesList = (filterData) => async (dispatch) => {
    dispatch(getLoadingStart('roles'));
    await axios({
      method: 'POST',
      url: endPoints.GET_ROLES,
      data: {
        ...filterData
      },
    })
      .then(async (res) => {
        dispatch(slice.actions.getRolesList(res.data));
        dispatch(getLoadingEnd('roles'));
      })
      .catch((err) => {
        dispatch(getLoadingEnd('roles'));
        dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Failed to get the roles list from the server.'));
      });
  };
  
  export const getDemoAPI = (filterData) => async (dispatch) => {
    await axios({
      method: 'POST',
      url: endPoints.UPDATE_ROLE,
      data: {
        roleId: 1,
        permission: ['read','write']
      },
    })
      .then(async (res) => {
      })
      .catch((err) => {
      });
  };

  export const addNewRole = async (data) => {
    return await axios({
      method: 'POST',
      data: data,
      url: endPoints.ADD_NEW_ROLE,
    });
  };

  export const getRoleDetailInfo = (roleId) => async (dispatch) => {
    dispatch(getLoadingStart('roles'));
    await axios({
      method: 'POST',
      url: endPoints.GET_ROLES_INFO,
      data:{
        roleId: roleId
      }
    })
      .then(async (res) => {
        dispatch(slice.actions.getRoleDetails(res.data));
        dispatch(getLoadingEnd('roles'));
      })
      .catch((err) => {
        dispatch(getLoadingEnd('roles'));
        dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Failed to get the role details from the server.'));
      });
  };

  export const setRoleUpdatedStatus = (value) => (dispatch) => {
    dispatch(slice.actions.setRoleUpdated(value));
  };

  export const setClientUpdatedStatus = (value) => (dispatch) => {
    dispatch(slice.actions.setClientUpdated(value));
  };

  export const updateRoleStatus = async (data) => {
    return await axios({
      method: 'POST',
      data,
      url: endPoints.UPDATE_ROLE_STATUS,
    });
  };

  export const updateClientStatus = async (data) => {
    return await axios({
      method: 'POST',
      data,
      url: endPoints.UPDATE_CLIENT_STATUS,
    });
  };

  export const updateRole = async (data) => {
    return await axios({
      method: 'POST',
      data: data,
      url: endPoints.UPDATE_ROLE,
    });
  };

  export const getClientsList = (filterData) => async (dispatch) => {
    dispatch(getLoadingStart('clients'));
    await axios({
      method: 'POST',
      url: endPoints.GET_CLIENTLIST,
      data: {
        ...filterData
      },
    })
      .then(async (res) => {
        dispatch(slice.actions.getClientList(res.data));
        dispatch(getLoadingEnd('clients'));
      })
      .catch((err) => {
        dispatch(getLoadingEnd('clients'));
        dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Failed to get the clients list from the server.'));
      });
  };

  export const getClientDetailInfo = (clientId) => async (dispatch) => {
    dispatch(getLoadingStart('clients'));
    await axios({
      method: 'POST',
      url: endPoints.GET_CLIENT_INFO,
      data:{
        clientId: clientId
      }
    })
      .then(async (res) => {
        dispatch(slice.actions.getClientDetails(res.data.clientDetails));
        dispatch(getLoadingEnd('clients'));
      })
      .catch((err) => {
        dispatch(getLoadingEnd('clients'));
        dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Failed to get the client details from the server.'));
      });
  };

  export const updateClientInfo = async (data) => {
    return await axios({
      method: 'POST',
      data,
      url: endPoints.UDPATE_CLIENT_INFO,
    });
  };

  export const addNewFacility = (requestData) => async (dispatch) => {
    await axios({
      method: 'POST',
      url: endPoints.ADD_NEW_FACILITY,
      data: {
        ...requestData
      },
    })
      .then(async (res) => {
        dispatch(setClientUpdatedStatus(true));
        dispatch(getNotificationStart(NOTIFICATION.SUCCESS, 'Added new facility successfully.'));
      })
      .catch((err) => {
        dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Action Failed. Please try again.'));
      });
  };

  export const updateFacility = (requestData) => async (dispatch) => {
    await axios({
      method: 'POST',
      url: endPoints.UPDATE_FACILITY,
      data: {
        ...requestData
      },
    })
      .then(async (res) => {
        dispatch(setClientUpdatedStatus(true));
        dispatch(getNotificationStart(NOTIFICATION.SUCCESS, 'Added new facility successfully.'));
      })
      .catch((err) => {
        dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Action Failed. Please try again.'));
      });
  };

  export const addNewInstance = (requestData) => async (dispatch) => {
    await axios({
      method: 'POST',
      url: endPoints.ADD_NEW_INSTANCE,
      data: {
        ...requestData
      },
    })
      .then(async (res) => {
        dispatch(setClientUpdatedStatus(true));
        dispatch(getNotificationStart(NOTIFICATION.SUCCESS, 'Added new instance successfully.'));
      })
      .catch((err) => {
        dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Action Failed. Please try again.'));
      });
  };

  export const updateInstance = (requestData) => async (dispatch) => {
    await axios({
      method: 'POST',
      url: endPoints.UPDATE_INSTANCE,
      data: {
        ...requestData
      },
    })
      .then(async (res) => {
        dispatch(setClientUpdatedStatus(true));
        dispatch(getNotificationStart(NOTIFICATION.SUCCESS, 'Updated the instance successfully.'));
      })
      .catch((err) => {
        dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Action Failed. Please try again.'));
      });
  };

  export const addUser = (data) => async (dispatch) => {
    try {
      await axios({
        method: 'POST',
        url: endPoints.ADD_USER,
        data: { ...data },
      });
      return true;
    } catch (err) {
      dispatch(getNotificationStart(NOTIFICATION.ERROR, err?.response?.data?.errorMessage));
      return false;
    }
  };
  
  export const editUser = (data) => async (dispatch) => {
    try {
      await axios({
        method: 'POST',
        url: endPoints.UPDATE_USER,
        data: { ...data },
      });
      return true;
    } catch (err) {
      dispatch(getNotificationStart(NOTIFICATION.ERROR, err?.response?.data?.errorMessage));
      return false;
    }
  };
  
export default slice;

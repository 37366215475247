import React, { useEffect } from "react";
import NProgress from "nprogress";
import { Box, LinearProgress } from "@mui/material";
import {useTheme} from "@mui/material";

const LoadingScreen = () => {
    const theme = useTheme();
    const classes = {
        root: {
            alignItems: "center",
            backgroundColor: theme.palette.background.default,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            minHeight: "100%",
            padding: theme.spacing(3),
          }
    };

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div style={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../utils/endPoints";
import moment from "moment";
const startDate = moment().subtract(30 - 1, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm');
const initialState = {
  facilities: [],
  specialties: [],
  clients: [],
  roles:[],
  instances: [],
  transactionTypes: [],
  defaultTransactionFilter:{
    fromDate: startDate,
    toDate: endDate,     
    startRow:1,       
    rowsToFetch:10,  
    facilities: [],
    specialities: [],
    visitId: "",
    response: "",
    transactionId: "",
    requestId: "",
    clients: [],
    instances: [],
    transactionTypes: []
  },
  currentTransactionFilter:{
    fromDate: startDate,
    toDate: endDate,     
    startRow:1,       
    rowsToFetch:10,  
    facilities: [],
    specialities: [],
    visitId: "",
    response: "",
    transactionId: "",
    requestId: "",
    clients: [],
    instances: [],
    transactionTypes: []
  },
  currentRoleFilter: {},
  currentClientFilter: {},
 defaultUserFilter:{   
    startRow:1,       
    rowsToFetch:10,  
    client: [],
    email:"",
    userName:"",
    LastName:"",
    fisrtName:"",
    roles:[],
    status:[]
  },
 currentUserFilter:{    
    startRow:1,       
    rowsToFetch:10,  
    client: [],
    email:"",
    userName:"",
    LastName:"",
    fisrtName:"",
    roles:[],
    status:[]
  },
};

const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    initialize(state, action) {
      state.facilities = [];
      state.specialties = [];
      state.clients = [];
      state.roles = [];
      state.instances = [];
      state.transactionTypes = [];
      state.defaultTransactionFilter = {
        fromDate: startDate,
        toDate: endDate,     
        startRow:1,       
        rowsToFetch:10,  
        facilities: [],
        specialities: [],
        visitId: "",
        response: "",
        transactionId: "",
        requestId: "",
        clients: [],
        instances: [],
        transactionTypes: []
      };
      state.currentTransactionFilter = {
        fromDate: startDate,
        toDate: endDate,     
        startRow:1,       
        rowsToFetch:10,  
        facilities: [],
        specialities: [],
        visitId: "",
        response: "",
        transactionId: "",
        requestId: "",
        clients: [],
        instances: [],
        transactionTypes: []
      };
      state.defaultUserFilter = {
        startRow:1,       
        rowsToFetch:10,  
        client: [],
        email:"",
        userName:"",
        LastName:"",
        fisrtName:"",
        roles:[],
        status:[]
      };
      state.currentUserFilter = {
        startRow:1,       
        rowsToFetch:10,  
        client: [],
        email:"",
        userName:"",
        LastName:"",
        fisrtName:"",
        roles:[],
        status:[]
      };
    },
    getFacilities(state, action) {
      const payload = action.payload.data;
      const temp = payload.sort();
      state.facilities = temp;
      state.error = '';
    },
    getFacilitiesFailure(state, action) {
      state.error = action.payload.error;
    },
    getSpecialities(state, action) {
      state.specialties = action.payload;
      state.error = '';
    },
    getSpecialtiesFailure(state, action) {
      state.error = action.payload.error;
    },
    getClients(state, action) {
      const payload = action.payload;
      const temp = payload.sort();
      state.clients = temp;
      state.error = '';
    },
    getClientsFailure(state, action) {
      state.error = action.payload.error;
    },
    getRoles(state, action) {
      const payload = action.payload;
      const temp = payload.sort();
      state.roles = temp;
      state.error = '';
    },
    getRolesFailure(state, action) {
      state.error = action.payload.error;
    },
    getInstances(state, action) {
      const payload = action.payload;
      const temp = payload.sort();
      state.instances = temp;
      state.error = '';
    },
    getInstancesFailure(state, action) {
      state.error = action.payload.error;
    },
    getTransactionTypes(state, action) {
      const payload = action.payload;
      const temp = payload.sort();
      state.transactionTypes = temp;
      state.error = '';
    },
    getTransactionTypesFailure(state, action) {
      state.error = action.payload.error;
    },
    setCurrentFilter(state, action) {
      if (action.payload.type === "transaction") {
        state.currentTransactionFilter = action.payload.filter;
      } 
      else if (action.payload.type === "role") {
        state.currentRoleFilter = action.payload.filter;
      }
      else if (action.payload.type === "client") {
        state.currentClientFilter = action.payload.filter;
      }
      else if (action.payload.type === "user") {
        state.currentUserFilter = action.payload.filter;
      }
    },
    setDefaultFilter(state, action) {
      if (action.payload.type === "transaction") {
        state.defaultTransactionFilter = action.payload.filter;
      } else if (action.payload.type === "user") {
        state.currentUserFilter = action.payload.filter;
      }
    },
  },
});

export const reducer = slice.reducer;

export const filterInitialize = () => async (dispatch) => {
  dispatch(slice.actions.initialize());
};
// To get the common data for filter rendering 
//get facilites for filter
export const getFacilities = (type) => async (dispatch) => {
  await axios({
    method: 'GET',
    url: endPoints.GET_FACILITY
  })
    .then(async (res) => {
      dispatch(slice.actions.getFacilities({ data: [...res.data.facilities], type }));
    })
    .catch(async (err) => {
      dispatch(
        slice.actions.getFacilitiesFailure({
          error: 'get facility with status failed',
        })
      );
    });
};

//get specialties for filter
export const getSpecialities = (data, type) => async (dispatch) => {
  await axios({
    method: 'GET',
    url: endPoints.GET_SPECIALTIES
  })
    .then(async (res) => {
      dispatch(slice.actions.getSpecialities(res.data.specialities || []));
    })
    .catch(async (err) => {
      dispatch(
        slice.actions.getSpecialtiesFailure({
          error: 'get specialty with facility ID failed',
        })
      );
    });
};

//get clients for filter
export const getClients = (data, type) => async (dispatch) => {
  await axios({
    method: 'GET',
    url: endPoints.GET_CLIENT
  })
    .then(async (res) => {
      dispatch(slice.actions.getClients(res.data.clients || []));
    })
    .catch(async (err) => {
      dispatch(
        slice.actions.getClientsFailure({
          error: 'get clients failed',
        })
      );
    });
};

//get roles for filter
export const getRoles = (data, type) => async (dispatch) => {
  await axios({
    method: 'GET',
    url: endPoints.GET_ROLE
  })
    .then(async (res) => {
      dispatch(slice.actions.getRoles(res.data.roles || []));
    })
    .catch(async (err) => {
      dispatch(
        slice.actions.getRolesFailure({
          error: 'get roles failed',
        })
      );
    });
};

//get instances for filter
export const getInstances = (data, type) => async (dispatch) => {
  await axios({
    method: 'GET',
    url: endPoints.GET_INSTANCE
  })
    .then(async (res) => {
      dispatch(slice.actions.getInstances(res.data.instances || []));
    })
    .catch(async (err) => {
      dispatch(
        slice.actions.getInstancesFailure({
          error: 'get instances failed',
        })
      );
    });
};

//get transactions Type for filter
export const getTransactionTypes = (data, type) => async (dispatch) => {
  await axios({
    method: 'GET',
    url: endPoints.GET_TRANSACTIONTYPE
  })
    .then(async (res) => {
      dispatch(slice.actions.getTransactionTypes(res.data.transactionType || []));
    })
    .catch(async (err) => {
      dispatch(
        slice.actions.getTransactionTypesFailure({
          error: 'get Transaction Types failed',
        })
      );
    });
};

export const  setCurrentFilter = (filter, type) => (dispatch) => {
  if (type) {
    dispatch(slice.actions.setCurrentFilter({ type, filter }));
  } else {
    dispatch(slice.actions.setCurrentFilter({ ...filter }));
  }
};
export default slice;

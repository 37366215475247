import React from 'react';
import Cards from 'src/reusable-components/cards';
import LoadingScreen from 'src/components/LoadingScreen';

const UserCard = ({ userCard }) => {
  if (userCard) {
    return <Cards type="user" data={userCard} />;
  } else {
    return <LoadingScreen />;
  }
};

export default UserCard;

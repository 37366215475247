import { Box } from '@mui/material';
import calendarize from 'calendarize';
import React, { useEffect, useState } from 'react';

import IconButton from '../buttons/CustomIconButton.jsx';
import Icon from '../icons/index.jsx';
import Select from '../text-field/customSelect/index.jsx';
import moment from 'moment';

const classes = {
  root: {
    width: '296px',
    height: '304px',
    background: '#fff',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '3px',
    '& .MuiMenu-paper': {
      height: '200px !important',
    },
    '& .MuiMenu-list': {
      height: '200px !important',
    },
  },
  datepicker: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '56px',
    alignItems: 'center',
  },
  month: {
    width: '104px',
    height: '32px',
    paddingRight: '5px',
    '& > .MuiSelect-selectMenu': {
      textOverflow: 'inherit!important',
      backgroundColor: '#fff',
    },
    '& > .MuiOutlinedInput-input': {
      padding: '0 5px !important',
    },
    '& > svg': {
      cursor: 'pointer',
    },
  },
  year: {
    width: '90px',
    height: '32px',
    paddingRight: '5px',
    '& > .MuiSelect-selectMenu': {
      textOverflow: 'inherit!important',
    },
    '& > .MuiOutlinedInput-input': {
      padding: '0 15px !important',
      backgroundColor: '#fff',
    },
  },
  calendar: {
    padding: '10px 20px',
  },
  day: {
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    '&:hover': {
      backgroundColor: '#DCF4FF',
    },
    borderRadius: '18px',
  },
  dayWidget: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
  },
  activeDayWidget: {
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '50%',
    border: '1px solid #355EEF',
  },
  disabledDayWidget: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '18px 18px',
    color: '#A4A4A4',
    cursor: 'pointer',
  },
  notSelectableDayWidget: {
    cursor: 'disabled',
    color: '#b2b2b2',
  },
  weekWidget: {
    width: '28px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#6d6d6d',
    textAlign: 'center',
    fontWeight: '500',
    fontFamily: 'Inter',
  },
  week: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
};

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
// const years = new Array(150).fill(1901).map((num, index) => {
//   return num + index;
// });

const Calendar = ({ setDate, date, handleClosePicker, futureSelect, startYear=1901 , ...props }) => {
  const now = date ? moment(date)?._d || new Date(date) : moment()?._d || new Date();
  const years = new Array(150).fill(startYear).map((num, index) => {
    return num + index;
  }).reverse();
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(months[now.getMonth()]);
  let totalDays = calendarize(moment(`${year}-${month}-01`)?._d || new Date(`${year}-${month}-01`), 0);
  const current = moment()?._d || new Date();
  const monthBack = () => {
    if (month !== 'January') {
      setMonth(months[months.indexOf(month) - 1]);
    } else {
      setMonth('December');
      setYear(parseInt(year) - 1);
    }
  };
  const monthForward = () => {
    const current = moment()?._d || new Date();
    if (!futureSelect) {
      if (year === current.getFullYear() && months[current.getMonth()] === month) {
        return;
      }
    }

    if (month !== 'December') {
      setMonth(months[months.indexOf(month) + 1]);
    } else {
      setMonth('January');
      setYear(parseInt(year) + 1);
    }
  };
  const [selectedDay, setSelectedDay] = useState(now.getDate());

  const preprocessDays = (daysArray) => {
    const normalYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const leapYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const length = daysArray.length;
    let monthNum = months.indexOf(month) + 1;
    let newDays = [];
    daysArray.map((week) => {
      let newWeek = [];
      week.map((day) => {
        return day === 0 ? newWeek.push({ out: day }) : newWeek.push({ in: day });
      });
      return newDays.push(newWeek);
    });
    let lastDayOfMonth = 0;

    if (year % 4 === 0 && year % 100 !== 0) {
      if (monthNum === 1) {
        lastDayOfMonth = 31;
      } else if (monthNum === 2) {
        lastDayOfMonth = 31;
      } else {
        lastDayOfMonth = leapYear[monthNum - 2];
      }
    } else {
      if (monthNum === 1) {
        lastDayOfMonth = 31;
      } else if (monthNum === 2) {
        lastDayOfMonth = 31;
      } else {
        lastDayOfMonth = normalYear[monthNum - 2];
      }
    }
    newDays[length - 1]
      .filter((day) => Object.keys(day)[0] === 'out')
      .map((day, index) => {
        return (day.out = index + 1);
      });
    const numOfDaysOfPrevMonth = newDays[0].filter((day) => Object.keys(day)[0] === 'out').length;
    newDays[0]
      .filter((day) => Object.keys(day)[0] === 'out')
      .map((day, index) => {
        return (day.out = lastDayOfMonth + index + 1 - numOfDaysOfPrevMonth);
      });
    return newDays;
  };
  totalDays = preprocessDays(totalDays);

  useEffect(() => {
    const numMonth = months.indexOf(month) + 1;
    const selectedDate =
      moment(numMonth + '-' + selectedDay + '-' + year)?._d || new Date(numMonth + '-' + selectedDay + '-' + year);
    const now = moment()?._d || new Date();
    if (!futureSelect && selectedDate > now) {
      return;
    }
    setDate(
      year +
        '-' +
        (numMonth < 10 ? '0' + numMonth : numMonth) +
        '-' +
        (selectedDay < 10 ? '0' + selectedDay : selectedDay)
    );
  }, [year, month, selectedDay]); //eslint-disable-line

  const handleDayClick = (day) => {
    const key = Object.keys(day)[0];
    const value = Object.values(day)[0];

    if (checkNotSelectable(day)) return;

    if (key === 'in') {
      setSelectedDay(value);
    } else if (key === 'out') {
      if (value < 7) {
        monthForward();
        setSelectedDay(value);
      } else {
        monthBack();
        setSelectedDay(value);
      }
    }
    if (key === 'in') {
      const numMonth = months.indexOf(month) + 1;
      //const selectedDate = new Date(numMonth + '-' + value + '-' + year);
      const selectedDate =
        moment(numMonth + '-' + value + '-' + year)?._d || new Date(numMonth + '-' + value + '-' + year);
      if (handleClosePicker) {
        if (selectedDate > (moment()?._d || new Date()) && !futureSelect) {
          return;
        }
        handleClosePicker();
      }
    }
  };

  const checkNotSelectable = (dayOnCalendar) => {
    const now = moment()?._d || new Date();

    const currentDate =
      moment(year + '-' + months[months.indexOf(month)] + '-' + dayOnCalendar.in)?._d ||
      new Date(year + '-' + months[months.indexOf(month)] + '-' + dayOnCalendar.in);

    if (!futureSelect && currentDate >= now) {
      return true;
    }
    return false;
  };

  return (
    <Box sx={classes.root}>
      <div style={classes.datepicker}>
        <IconButton color="secondary" size="small" onClick={(e) => monthBack()}>
          <Icon name="chevron-left" size="sm" />
        </IconButton>
        <Select
          key={1}
          selectList={months}
          placeholder={month}
          setSelect={setMonth}
          selected={month}
          size="small"
          width="104px"
          helperText="this is for testing"
          dropHeight="200px"
        />
        <Select
          key={2}
          selectList={!futureSelect ? years.filter((year) => year < current.getFullYear() + 1) : years}
          placeholder={year}
          setSelect={setYear}
          selected={year}
          width="72px"
          size="small"
          helperText="this is for testing"
          dropHeight="200px"
        />
        <IconButton color="secondary" size="small" onClick={() => monthForward()}>
          {' '}
          <Icon name="chevron-right" size="sm" />
        </IconButton>
      </div>
      <div style={classes.calendar}>
        <div style={classes.week}>
          {daysOfWeek.map((day, index) => {
            return (
              <div style={classes.weekWidget} key={index}>
                {day}
              </div>
            );
          })}
        </div>
        {totalDays.map((row, index) => {
          return (
            <div style={classes.week} key={index}>
              {row.map((day, index) => {
                return (
                  <div style={classes.day} key={index}>
                    <div
                      style={
                        Object.keys(day)[0] === 'in'
                          ? selectedDay === day.in
                            ? classes.activeDayWidget
                            : checkNotSelectable(day)
                            ? classes.notSelectableDayWidget
                            : classes.dayWidget
                          : classes.disabledDayWidget
                      }
                      key={index + 1000}
                      onClick={() => handleDayClick(day)}
                    >
                      {Object.values(day)[0]}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default Calendar;

import { Box, Grid, Typography, Tooltip } from "@mui/material";
import UserBreadcrumbs from "src/reusable-components/navigation/Breadcrumbs";
import Icon from "src/reusable-components/icons";
import CustomButton from "src/reusable-components/buttons/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
const Header = () => {
  const navigate = useNavigate();
    const handleCreateClick = () => {
      navigate('/dashboard/roles/new');
    };
    
    const { roles } = useSelector((state) => state.management);
    const headers = [
      {key: 'role', label: 'Role' },
      {key: 'permissions', label: 'Permission'},
      {key: 'usersCount', label: 'Users'},
      {key: 'status', label: 'Status'},
    ];
    
   const data = roles;
   const isValidExport = roles?.length > 0 ? true : false;
    return (
        <Box width="100%">
          <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Grid container justifyContent="space-between">
              <Grid item>
                <UserBreadcrumbs type="roles" />
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ fontSize: '24px' }}>Roles</Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Tooltip title="Download CSV" arrow placement="top">
                <Box width={24} height={24} 
                style={isValidExport ? { cursor: 'pointer' }:{ cursor: 'pointer', opacity:0.3}} 
                >
                <CSVLink data={data} headers={headers} filename={"Roles.csv"}  onClick={event => {
                    return isValidExport;
                  }}>
                    <Icon
                      name='file-csv'
                      size="lg"
                    />
                  </CSVLink>
                </Box>
            </Tooltip>
              <CustomButton
                size="small"
                color="primary"
                text="New"              
                onClick={handleCreateClick} style={{marginLeft:'15px'}}
              />
            </Box>
          </Box>
        </Box>
    );
}
export default Header;
import { useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import FilteringContent from "./content";
import { useSelector, useDispatch } from "react-redux";
import { changeNavbarShow, changeFilterShow } from "src/slices/layout";
export default function Filter({fetchTransactionData,setPage}) {
    const theme = useTheme();
    const classes = {
        fullFilter: {
            boxSizing: 'border-box',
            maxHeight: '100vh',
            width: '248px',
            paddingLeft: '21px',
            background: theme.palette.background.white,
            transition: 'width 0.3s',
        },
        smallFilter: {
            boxSizing: 'border-box',
            paddingLeft: '24px',
            width: '68px',
            textAlign: 'center',
            height: '100%',
            background: theme.palette.background.white,
            transition: 'width 0.3s',
        },
        content: {
            maxHeight: '100vh',
            height: '100vh',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#D2D2D2',
                borderRadius: '5px',
            },
        },
        title: {
            fontSize: '14px',
            fontWeight: 700,
            marginLeft: '25px',
            marginTop: '5px',
            height: '22px',
        },
  };
  const dispatch = useDispatch();
    const { isNavbarShow, isFilterShow } = useSelector((state) => state.layout);
    const handleFilter = () => {
        if (isFilterShow) {
        dispatch(changeFilterShow());
        }
    };

    const handleExpandFilter = () => {
        if (!isFilterShow) {
        dispatch(changeFilterShow());
        if (isNavbarShow) {
            dispatch(changeNavbarShow());
        }
        }
    };
    const content = (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          sx={classes.content}
          style={{ paddingRight: isFilterShow ? 24 : 0 }}
        >
          <Box display="flex" justifyContent="start" alignItems="center"  pb={4} pt={2}>
            <img src="/images/filter.svg" alt="filter" style={{ cursor: 'pointer' }} onClick={() => handleFilter()}/>
            <Typography gutterBottom sx={classes.title}>
              {isFilterShow ? 'Filter' : ' '}
            </Typography>
          </Box>
          <Box display={isFilterShow ? 'block' : 'none'}>
            <FilteringContent fetchTransactionData={fetchTransactionData} setPage={setPage}/>
          </Box>
        </Box>
    );
    return (
        <Box
          sx={isFilterShow ? classes.fullFilter : classes.smallFilter}
          style={{
            marginTop: -65,
            zIndex: 200,
            borderRight: '1px solid gray',
            paddingTop: 1,
            background: 'white',
            cursor: !isFilterShow ? 'pointer' : 'default',
          }}
          onClick={handleExpandFilter}
        >
          {content}
        </Box>
    );
}
import { Grid, useTheme } from "@mui/material";
import { Box, Typography , Card } from "@mui/material";
import { useSelector } from 'react-redux';
import Result from "./Result";

export default function Details({requestId}) {
    const theme = useTheme();
    const classes = {
        header: {
            backgroundColor: theme.palette.background.green,
            color: '#FFFFFF',
            justifyContent: 'space-between',
        },
        label: {
            color: theme.palette.text.gray,
        },
        pointer: {
            cursor: 'pointer',
        },
        card:{
            maxWidth: '100%',
            marginTop: '32px',
            backgroundColor: theme.palette.background.grey_1
        },
        colon:{
            paddingRight: '10px',
            paddingLeft: '2px',
            float: 'right'
          }
        
    }
    const {transactionDetails} = useSelector((state) => state.transactionDetails);
    const handleMissing = (value) => {
        return value ? value : "Missing"
    } 

    return (
        <Box sx={classes.card}>
            <Card>
                <Box px={3} py={1.2} display="flex" sx={classes.header}>
                    <Box display="flex" width={700} sx={classes.pointer}>
                        <Typography variant="subtitle1" style={{ marginLeft: 5 }}>
                            Request ID: <span>{requestId}</span>
                        </Typography>
                    </Box>
                </Box>
                <Box px={3} py={4}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <Box display='table'>
                                <Box display='table-cell'>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Client<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Instance<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Facility<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Specialty<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787', width: '91px' }}>Request ID<span style={classes.colon}>:</span></Typography>
                                </Box>
                                <Box display='table-cell'>
                                    <Typography variant="body1">{handleMissing(transactionDetails.client)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.instance)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.Facility)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.Specialty)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.requestId)}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box display='table'>
                                <Box display='table-cell'>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Request On<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Response On<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Duration<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787', width: '135px' }}>Transaction Type<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Client Record ID<span style={classes.colon}>:</span></Typography>
                                </Box>
                                <Box display='table-cell'>
                                    <Typography variant="body1">{handleMissing(transactionDetails.RequestOn)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.ResponseOn)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.Duration)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.TransactionType)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.clientrecordid)}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                         <Grid item md={4} xs={12}>
                            <Box display='table'>
                                <Box display='table-cell'>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Processing Status<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>API status<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Coding AI Status<span style={classes.colon}>:</span></Typography>
                                    <Typography variant="body1" style={{ color: '#878787' }}>Coding AI ID<span style={classes.colon}>:</span></Typography>
                                </Box>
                                <Box display='table-cell'>
                                    <Typography variant="body1">{handleMissing(transactionDetails.ProcessStatus)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.APIStatus)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.codingaiStatus)}</Typography>
                                    <Typography variant="body1">{handleMissing(transactionDetails.codingAiId)}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
            <Box mt={3}>
                <Result />
            </Box>
        </Box>
    );
}
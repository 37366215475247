import { Box,Grow } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Icon from '../icons';
import { stopPropagationKeyDown } from '../utils';
import {useTheme} from '@mui/material';

const CustomStatus = (props) => {
    const theme = useTheme();
    const classes = {
        root: {
          position: 'relative',
          width: '100%',
          cursor: 'pointer',
        },
        selectedItem: {
          border: 'solid 1px #D6D6D6',
          lineHeight: '0px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          '&:focus': {
            border: 'solid 2px #1E90FF',
            outline: 'none !important',
          },
        },
        selectListItem: {
          '&:focus': {
            backgroundColor: '#4169E133 !important',
            outline: 'none',
          },
        },
        selection:{
          borderRadius: '3px',
          border: 'solid 1px #D6D6D6',
          borderColor: props.error ? '#F84F4F !important' : '#D6D6D6',
          '&:hover': {
            backgroundColor: '#F3F4F4',
          },
        },
        dropList: {
          overflow: 'hidden',
          position: 'absolute',
          top: props.size === 'small' ? '32px' : '36px',
          width: '100%',
          zIndex: '20',
          backgroundColor: 'white !important',
          boxShadow: '0px 1px 8px #0000001A',
          border: 'none',
        },
        unselected: {
          '&:hover': {
            backgroundColor: theme.palette.background.gray_2,
          },
        },
        selected: {
          backgroundColor: '#DEDEDE',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        },
        item:{
          height: props.size === 'small' ? '32px' : '36px',
          padding: '6px 8px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          fontWeight: '500',
          fontSize: '13px',
          fontFamily: 'Inter',
          color: theme.palette.text.neutral,
        },
        fresh: {
          color: theme.palette.text.gray_3 + ' !important',
        },
        dropDown: {
          transition: '0.4s',
        },
        dropUp: {
          transform: 'rotate(180deg)',
        },
        error: {
          height: props.size === 'small' ? '32px' : '36px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          color: '#F84F4F',
          fontSize: '12px',
          fontFamily: 'Inter',
        },
      }
  const { selected, setSelect, placeholder, type, disable = false } = props;
  const selectList =
    type === 'active' ? selected === 'Activated' ? ['Deactivated','Locked']
        : selected === 'Deactivated' ? ['Activated','Locked'] : ['Activated','Deactivated']
      : ['SELECT', 'DUE', 'INPROGRESS', 'COMPLETE'];
  const [drop, setDrop] = useState(false);
  const rootRef = useRef();
  const selectedRef = useRef();
  const dropListRef = useRef();

  //close the dropdown by clicking outside
  const closeDropDown = (e) => {
    if (rootRef.current?.contains(e.target)) {
      rootRef.current?.classList.add('focused');
    } else {
      rootRef.current?.classList.remove('focused');
    }
    !rootRef.current?.contains(e.target) && setDrop(false);
  };

  const handleItemClick = (key) => {
    setSelect(selectList[key]);
    setDrop(false);
    selectedRef?.current?.focus();
  };

  const handleKeyDownOnSelectedItem = (e) => {
    stopPropagationKeyDown(e);
    if (e.key === 'Enter' || e.keyCode === 13 || e.key === ' ' || e.keyCode === 32) {
      setDrop(true);
    }
    if ((e.key === 'ArrowDown' || e.keyCode === 40) && drop) {
      dropListRef.current.childNodes[0].focus();
    }
  };

  const handleKeyDownOnSelectListItem = (e, index) => {
    stopPropagationKeyDown(e);
    if (e.key === 'Enter' || e.keyCode === 13 || e.key === ' ' || e.keyCode === 32) {
      handleItemClick(index);
    }
    if ((e.key === 'ArrowDown' || e.keyCode === 40) && drop && index < dropListRef.current.childNodes.length - 1) {
      dropListRef.current.childNodes[index + 1].focus();
    }
    if ((e.key === 'ArrowUp' || e.keyCode === 38) && drop && index > 0) {
      dropListRef.current.childNodes[index - 1].focus();
    }
  };

  const closeDropDownByESC = (e) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      setDrop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', closeDropDown);
    window.addEventListener('keydown', closeDropDownByESC);
    return () => {
      window.removeEventListener('click', closeDropDown);
      window.removeEventListener('keydown', closeDropDownByESC);
      rootRef.current = null;
      selectedRef.current = null;
    };
  }, []);


  return (
    <Box sx={classes.root} ref={rootRef} style={{opacity: disable ? '0.7' : '1', cursor: disable ? 'not-allowed' : 'pointer'}}>
      <Box
        sx={[classes.selection, classes.item, classes.selectedItem, (selected === "" ? classes.fresh : '')]}
        onClick={() => {!disable && setDrop(!drop)}}
        ref={selectedRef}
        onKeyDown={handleKeyDownOnSelectedItem}
        tabIndex="0"
      >
        <Box display="flex" alignItems="center">
          {selected === 'SELECT' && <Icon name="state-select" size="lg" mr="10" ml="2" />}
          {selected === 'DUE' && <Icon name="state-due" size="lg" mr="10" ml="2" />}
          {selected === 'INPROGRESS' && <Icon name="state-in-progress" size="lg" mr="10" ml="2" />}
          {selected === 'COMPLETE' && <Icon name="state-complete" size="lg" mr="10" ml="2" />}
          {selected === '' && <Icon name="state-select" size="lg" mr="10" ml="2" />}
          {selected === 'Activated' && <Icon name="success" size="lg" mr="10" ml="2" />}
          {selected === 'Deactivated' && <Icon name="reject" size="lg" mr="10" ml="2" />}
          {selected === 'Locked' && <Icon name="locked" size="lg" mr="10" ml="2" color="red" />}
          {selected !== "" ? selected : placeholder}
        </Box>
        <img src="/images/dropdown.svg" style={drop ? classes.dropUp : {}} alt="dropdown" />
      </Box>
      <Grow in={drop && !disable} style={{ transformOrigin: 'center top' }}>
        <Box sx={[classes.selection, classes.dropList]} ref={dropListRef}>
          {selectList.map((item, key) => (
            <Box
              key={key}
              onClick={() => handleItemClick(key)}
              id={key}
              onKeyDown={(e) => handleKeyDownOnSelectListItem(e, key)}
              sx={
                item !== selected
                  ? [classes.item, classes.unselected, classes.selectListItem]
                  : [classes.item, classes.selected, classes.selectListItem]
              }
              tabIndex="0"
            >
              {item === 'SELECT' && <Icon name="state-select" size="lg" mr="10" ml="2" />}
              {item === 'DUE' && <Icon name="state-due" size="lg" mr="10" ml="2" />}
              {item === 'INPROGRESS' && <Icon name="state-in-progress" size="lg" mr="10" ml="2" />}
              {item === 'COMPLETE' && <Icon name="state-complete" size="lg" mr="10" ml="2" />}
              {item === 'Activated' && <Icon name="success" size="lg" mr="10" ml="2" />}
              {item === 'Deactivated' && <Icon name="reject" size="lg" mr="10" ml="2" />}
              {item === 'Locked' && <Icon name="locked" size="lg" mr="10" ml="2" color="red" />}
              {item}
            </Box>
          ))}
        </Box>
      </Grow>
    </Box>
  );
};

export default CustomStatus;

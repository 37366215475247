import React, { useState } from 'react';
import { Box, Card, Collapse, Grid, Typography } from '@mui/material';
import Icon from 'src/reusable-components/icons';

const classes = {
  header: {
    backgroundColor: '#F3BE00',
    color: '#FFFFFF',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  link: {
    color: '#355EEF',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginTop: '16px',
  },
};

const Client = ({ data, setOpenContactBox }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card>
      <Box px={3} py={1.2} sx={classes.header} onClick={handleClick}>
        <Icon name="more" size="lg" color="white" />
        <Typography variant="subtitle1" style={{ marginLeft: 20 }}>
          Client: {data?.clientName}
        </Typography>
      </Box>
      <Box>
        <Collapse in={isOpen}>
          <Box px={3} py={4} style={{ backgroundColor: '#FBFAF9' }}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex">
                  <Typography variant="body1" style={{ color: '#878787', width: '70px' }}>
                    Email &nbsp;&nbsp;&nbsp;:
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      width: 'calc(100% - 70px)',
                      wordBreak: 'break-all',
                    }}
                  >
                    {data?.email}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
    </Card>
  );
};

export default Client;

import { Box } from "@mui/material"
import Filter from "./Filter"
import Header from './Header'
import Details from './Details'

export default function Roles() {
    return (
        <Box display="flex">
            <Filter />
            <Box px={7} flex="1" sx={{
                height: 'calc(100vh - 55px)',
                overflow: 'auto',
            }}>
            <Header/>
            <Details/>
            </Box>
        </Box>
    )
}

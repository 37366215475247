import { useTheme, IconButton } from '@mui/material';
import React from 'react';



const CustomIconButton = ({ color, size, disabled, onClick, children }) => {
  const theme = useTheme();
  const classes = {
    root: {
      borderRadius: '50%',
    },
    primary: {
      backgroundColor: theme.palette.button.blue,
      color: '#fff',
      '&:hover': {
        boxShadow: '0px 4px 10px rgba(71, 112, 255, 0.8)',
        backgroundColor: theme.palette.button.blue,
        color: '#fff',
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.button.blue,
        color: '#fff',
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    secondary: {
      backgroundColor: '#fff',
      color: theme.palette.button.blue,
      '&:focus': {
        backgroundColor: '#AFE7FF',
      },
      '&.Mui-disabled': {
        color: theme.palette.button.blue,
        opacity: 0.5,
      },
    },
    warning: {
      backgroundColor: '#F84F4F',
      color: '#ffffff',
      '&:hover': {
        boxShadow: '0px 4px 10px rgba(255,71, 112,  0.8)',
        backgroundColor: '#F83F3F',
        color: '#fff',
      },
    },
    large: {
      width: '36px',
      height: '36px',
    },
    medium: {
      width: '32px',
      height: '32px',
    },
    small: {
      width: '24px',
      height: '24px',
    },
  };
  return (
    <IconButton
      size={size}
       sx={[classes.root,classes[color],classes[size]]}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

export default CustomIconButton;

import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckBox from '../checkboxes';
import Icon from '../icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  alignItems: 'center',
  width: 270,
  '&:hover': {
    backgroundColor: '#F3F4F4',
  },
  paddingLeft: 8,
  height: 28,
  justifyContent: 'space-between',
  paddingRight: 12,
  cursor: 'pointer',
  boxShadow: isDragging ? '0px 1px 8px rgba(0, 0, 0, 0.15)' : '',
  ...draggableStyle,
});

const classes = {
  menuicon: {
    display: 'none',
  },
  item: {
    '&:hover': {
      backgroundColor: '#F3F4F4',
      '& .menu': {
        display: 'block',
      },
    },
  },
  drag: {
    position: 'relative',
  },
  line: {
    width: '100%',
    height: '1px',
    position: 'absolute',
    top: 27,
    background: '#355EEF',
  },
};

const CheckList = ({ setColumns, columns, onClose, type }) => {
  const handleChange = (index, event) => {
    columns[index].value = event.target.checked;
    setColumns([...columns]);

    /**
     * To save check list data to the local storage according to the type
     */
    // localStorage.setItem(`${type}CheckList`, JSON.stringify(columns));
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(columns, result.source.index, result.destination.index);

    setColumns(items);
    /**
     * To save check list data to the local storage according to the type
     */
    // localStorage.setItem(`${type}CheckList`, JSON.stringify(items));
  };

  return (
    <Box
      style={{
        width: 295,
        borderRadius: 6,
        boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
      }}
      pl={2}
      pt={2}
      pr={3}
      pb={3}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" style={{ fontWeight: 700, fontFamily: 'Inter' }}>
          Specify Columns
        </Typography>
        <img src="/images/close.svg" onClick={onClose} style={{ cursor: 'pointer' }} alt="close" />
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Box mt={3} {...provided.droppableProps} ref={provided.innerRef}>
              {columns.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div style={classes.drag}>
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        sx={classes.item}
                      >
                        <CheckBox
                          label={item.label}
                          disabled={false}
                          checked={item.value}
                          onChange={(e) => handleChange(index, e)}
                        />
                        {snapshot.isDragging ? (
                          <Icon name="menu" size="10" />
                        ) : (
                          <Icon name="menu" size="10" className={classes.menuicon} />
                        )}
                      </Box>
                      {snapshot.isDragging ? <div style={classes.line} /> : null}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default CheckList;

import { Box, Typography,Grow } from "@mui/material";
import { useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const CustomSelect = (props) => {
  const theme = useTheme();
  const classes = {
      root: {
        position: "relative",
        width: "100%",
        cursor: props.disabled ? "no-drop" : "pointer",
      },
      selectedItem: {
        border: "solid 1px #D6D6D6",
        lineHeight: "0px",
        "&:focus": {
          border: "solid 1px #1E90FF",
          outline: "none !important",
        },
      },
      selection: {
        borderRadius: "3px",
        border: "solid 1px #D6D6D6",
        borderColor: "#D6D6D6",
        overflow: "hidden",
        "&:hover": {
          backgroundColor: "#F3F4F4",
        },
      },
      dropList: {
        position: "absolute",
        top: props.size === "small" ? "32px" : "36px",
        width: "100%",
        zIndex: "20",
        backgroundColor: "white !important",
        boxShadow: "0px 1px 8px #0000001A",
        border: "none",
        maxHeight: props.dropHeight ? props.dropHeight : "auto",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          borderRadius: "5px",
          backgroundColor: "#F8F8F8",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E8E8E8",
          borderRadius: "5px",
        },
      },
      unselected: {
        overflow: "hidden",
        "&:hover": {
          backgroundColor: "#b8d3f8",
        },
      },
      selected: {
        backgroundColor: "#488ff4",
      },
      selectListItem: {
        "&:focus": {
          backgroundColor: "#4169E133 !important",
          outline: "none",
        },
      },
      item: {
        height: props.size === "small" ? "32px" : "36px",
        padding: "6px 8px",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        fontWeight: "500",
        fontSize: "13px",
        fontFamily: "Inter",
        color: theme.palette.text.neutral,
      },
      fresh: {
        color: theme.palette.text.gray_3 + " !important",
      },
      disabled: {
        pointerEvents: "none",
        cursor: "no-drop !important",
      },
      dropDown: {
        transition: "0.4s",
      },
      dropUp: {
        transform: "rotate(180deg)",
      },
      error: {
        height: props.size === "small" ? "32px" : "36px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        color: "#F84F4F",
        fontSize: "12px",
        fontFamily: "Inter",
      },
      calendar: {},
  }
  const {
    selected,
    setSelect,
    onChange,
    placeholder,
    selectList,
    setSelectKey,
    name,
    disabled,
    isButton,
    isCalendar,
    setPosition,
  } = props;
  const [drop, setDrop] = useState(false);
  const [dirty] = useState(false);
  const rootRef = useRef();
  const selectedRef = useRef();
  const dropListRef = useRef();

  //close the dropdown by clicking outside
  const closeDropDown = (e) => {
    if (rootRef.current?.contains(e.target)) {
      rootRef.current?.classList.add('focused');
    } else {
      rootRef.current?.classList.remove('focused');
    }
    !rootRef.current?.contains(e.target) && setDrop(false);
  };

  const closeDropDownByESC = (e) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      setDrop(false);
    }
  };

  const handleItemClick = (key) => {
    if (onChange) onChange();
    if (selectList[key] === 'None') {
      setSelect && setSelect('');
      setSelectKey && setSelectKey(key);
    } else {
      setSelectKey && setSelectKey(key);
      setSelect && setSelect(selectList[key]);
    }
    setDrop(false);
    selectedRef?.current?.focus();
    // setDirty(true);
  };

  const handleKeyDownOnSelectedItem = (e) => {
    // stopPropagationKeyDown(e);
    if (e.key === 'Enter' || e.keyCode === 13 || e.key === ' ' || e.keyCode === 32) {
      setDrop(true);
    }
    if ((e.key === 'ArrowDown' || e.keyCode === 40) && drop) {
      dropListRef.current.childNodes[0].focus();
    }
  };

  const handleKeyDownOnSelectListItem = (e, index) => {
    // stopPropagationKeyDown(e);
    if (e.key === 'Enter' || e.keyCode === 13 || e.key === ' ' || e.keyCode === 32) {
      handleItemClick(index);
    }
    if ((e.key === 'ArrowDown' || e.keyCode === 40) && drop && index < dropListRef.current.childNodes.length - 1) {
      dropListRef.current.childNodes[index + 1].focus();
    }
    if ((e.key === 'ArrowUp' || e.keyCode === 38) && drop && index > 0) {
      dropListRef.current.childNodes[index - 1].focus();
    }
  };

  useEffect(() => {
    window.addEventListener('click', closeDropDown);
    window.addEventListener('keydown', closeDropDownByESC);
    return () => {
      window.removeEventListener('click', closeDropDown);
      window.removeEventListener('keydown', closeDropDownByESC);
      rootRef.current = null;
      selectedRef.current = null;
    };
  }, []);

  return (
    <Box sx={classes.root} ref={rootRef} name={name}>
      {isButton ? (
        <></>
      ) : (
        <Box
            sx={[classes.selection, classes.item, classes.selectedItem, (!(dirty || selected) || disabled ? classes.fresh : {}), (disabled ? classes.disabled : {})]}
          onClick={() => setDrop(!drop)}
          tabIndex="0"
          onKeyDown={handleKeyDownOnSelectedItem}
          ref={selectedRef}
        >
          {selected.length > 0 ? selected : placeholder}
          <img
            src="/images/dropdown.svg"
              style={{ ...classes.dropDown, ...(drop && classes.dropUp), ...{ marginLeft: 'auto' } }}
            alt="dropdown"
          />
        </Box>
      )}

      <Grow
        in={drop}
        style={{
          transformOrigin: "center top",
          width: isCalendar ? "max-content" : "",
          left: isCalendar ? "0" : "",
          right: isCalendar ? "0" : "",
          margin: isCalendar ? "auto" : "",
        }}
      >
        <Box
          sx={[classes.selection, classes.dropList]}
          ref={dropListRef}
          style={
            setPosition === "topRight"
              ? { marginTop: "-143px", marginLeft: "66px" }
              : ""
          }
        >
          {selectList.map((item, key) => {
            return (
              <Box
                key={key}
                id={key}
                onClick={() => handleItemClick(key)}
                onKeyDown={(e) => handleKeyDownOnSelectListItem(e, key)}
                minHeight={30}
                sx={
                  (item?.toString().toLowerCase() !== selected)?.toString().toLowerCase()
                    ? [classes.item,classes.unselected,classes.selectListItem]
                    : [classes.item,classes.selected,classes.selectListItem]
                }
                tabIndex="0"
                style={{ fontSize: isCalendar ? "14px" : "" }}
              >
                {item}
              </Box>
            )
            
          })}
        </Box>
      </Grow>
      {props.error ? (
        <Typography variant="caption" style={{ color: "red" }}>
          {props.error}
        </Typography>
      ) : null}
    </Box>
  );
};

export default CustomSelect;

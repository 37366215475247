export const endPoints = {
    BASE_URL: process.env.REACT_APP_API_URL,
    LOG_IN: '/login',
    GET_FACILITY: '/facilities',
    GET_SPECIALTIES: '/specialities',
    GET_TRANSACTIONS: '/transactions',
    GET_USERS: '/users',
    GET_USERDETAILS: '/userinfo',
    UPDATE_USER_STATUS: '/updateuserstatus',
    RESET_PASSWORD_MAIL_REQUEST: '/resetpassword',
    GET_TRANSACTIONDETAILS:'/transactiondetails',
    GET_CLIENT:'/clients',
    GET_ROLE:'/roleslist',
    GET_INSTANCE:'/instances',
    GET_TRANSACTIONTYPE:'/transactionType',
    ADD_USER:'/adduser',
   UPDATE_USER:'/updateuser',
    GET_PERMISSIONSLIST: '/permissions',
    GET_ROLES: '/roles',
    GET_ROLESLIST: '/roleslist',
    GET_ROLES_INFO: '/roleinfoanduserlist',
    UPDATE_ROLE_STATUS: '/updaterolestatus',
    ADD_NEW_ROLE: '/addrole',
    UPDATE_ROLE: '/updaterole',
    GET_CLIENTLIST: '/clientlist',
    GET_CLIENT_INFO: '/clientfacilityinstancedetails',
    UPDATE_CLIENT_STATUS: '/updateclientstatus',
    UDPATE_CLIENT_INFO: '/updateclientinfo',
    ADD_NEW_FACILITY: '/addfacility',
    ADD_NEW_INSTANCE: '/addinstance',
    UPDATE_FACILITY: '/updatefacility',
    UPDATE_INSTANCE: '/updateinstance',
}

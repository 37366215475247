import React from 'react';
import Card from 'src/reusable-components/cards';
import LoadingScreen from 'src/components/LoadingScreen';

const ClientCard = ({ clientDetail, setOpenContactBox }) => {
  if (clientDetail) {
    return <Card type="client" data={clientDetail} setOpenContactBox={setOpenContactBox} />;
  } else {
    return <LoadingScreen />;
  }
};

export default ClientCard;

import { Box,Button } from "@mui/material";

export default function CustomButton({onClick, disabled, label,btnRef, ...props }) {
    return (
        <Box sx={{
            width: '100%',
            borderRadius: '50px',
            overflow: 'hidden',
            margin: '10px 0px',
            backgroundColor: props.convert ? 'white' : "#4770FF",
            color: props.convert ? "#4770FF" : 'white',
            marginRight: props.mr ? props.mr : '0px'
        }}>
            <Button
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    backgroundColor: props.convert ? 'white' : '#4770FF',
                    color: props.convert ? '#4770FF' : 'white',
                    textTransform: 'none',
                    fontSize: props.size === 'small' ? '12px' : '14px',
                    lineHeight: '20px',
                    fontWeight: '500',
                    padding: props.size === 'small' ? '0px' : (theme) =>theme.spacing(1),
                    }}
                onClick={onClick} disabled={disabled} ref={btnRef}>
                {label}
            </Button>
        </Box>
    )
}
import { Box, Modal, Typography, Grid } from '@mui/material';
import React, {useState,useEffect } from 'react';
import CustomButton from 'src/reusable-components/buttons/CustomButton';
import Icon from 'src/reusable-components/icons';
import TextField from 'src/reusable-components/text-field';
import { setClientUpdatedStatus, updateClientInfo } from 'src/slices/management';
import { getNotificationStart } from 'src/slices/notification';
import { NOTIFICATION } from 'src/utils/constant';
import { useDispatch } from 'react-redux';


const classes = {
  paper: {
    position: 'absolute',
    width: '686px',
    maxWidth: 'calc(100% - 64px)',
    backgroundColor: '#fff',
    borderRadius: '5px',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    overflowY: 'auto',
    outline: 'none'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  header: {
    height: '40px',
    backgroundColor: '#e5e5e5',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
  centerBox:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  }
};

const ClientEditModal = (props) => {
  const { open, onCancel, clientId, email} = props;
  const [editEmail, setEditEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  const handleApply = async () => {
    dispatch(setClientUpdatedStatus(false));
    const data = {};
    data.clientId = clientId;
    data.email = editEmail;
    setLoading(true);
    const res = await updateClientInfo(data);
    setLoading(false);
    onCancel();
    if (res.status === 200) {
      onCancel();
      dispatch(setClientUpdatedStatus(true));
    } else {
      dispatch(getNotificationStart(NOTIFICATION.ERROR, 'Action Failed. Please try again.'));
    }
  };

  const emailChange = (e) => {
    setEditEmail(e.target.value)
  }

  useEffect(() => {
    setEditEmail(email);
  }, [open]); // eslint-disable-line

  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={classes.paper}>
        <Box sx={classes.header}>
          <Typography sx={classes.label}>Edit Client Name</Typography>
          <Icon name="cancel" size="sm" className={classes.closeIcon} onClick={onCancel} />
        </Box>
        <Box px={5} pt={3} sx={{flexGrow: 1}}>
            <Grid container spacing={3}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Email:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        onChange={emailChange}
                        value={editEmail}
                        size="medium"
                        placeholder=""
                        width="100%"
                        autoComplete="off"
                        />
                </Grid>
            </Grid>
          <Box pt={7} pb={2} display="flex" justifyContent="flex-end" alignItems="center">
            <CustomButton text="Cancel" color="secondary" size="large" onClick={() => onCancel()} />
            <CustomButton text="Save" color="primary" size="large" disabled={loading} onClick={handleApply} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ClientEditModal;

import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getRoleDetailInfo } from 'src/slices/management';
import LoadingScreen from 'src/components/LoadingScreen';

import Detail from './Details';
import Header from './Header';
import Users from './Users';
import CreateRole from '../Create';

const RoleDetail = () => {
  const { rid } = useParams();
  const dispatch = useDispatch();
  const { roleDetail, roleUpdated } = useSelector((state) => state.management);
  useEffect(() => {
    if (rid !== 'new' || roleUpdated) {
      dispatch(getRoleDetailInfo(rid));
    }
  }, [roleUpdated, rid]); // eslint-disable-line

  if (rid === 'new') return <CreateRole />;
  return !isEmpty(roleDetail) ? (
    <Box px={7}>
      <Header roleId={rid} role={roleDetail} />
      <Detail role={roleDetail.role} />
      <Users users={roleDetail.userDetailsMappedInRole} showRoleData={true} />
    </Box>
  ) : (
    <LoadingScreen />
  );
};

export default RoleDetail;

import { Box } from '@mui/material';
import React from 'react';
// for extra icons;
import { ReactComponent as ArrowDown } from './extra/arrow-down.svg';
import { ReactComponent as ArrowUp } from './extra/arrow-up.svg';
import { ReactComponent as UploadDocument } from './extra/upload.svg';
// for site mark

//for large icon
import { ReactComponent as LGAccountAdd } from './24/Account/Add.svg';
import { ReactComponent as LGAccountDelete } from './24/Account/Delete.svg';
import { ReactComponent as LGAccountRemove } from './24/Account/Remove.svg';
import { ReactComponent as LGAccountTick } from './24/Account/Tick.svg';
import { ReactComponent as LGArticleAdd } from './24/Article/Add.svg';
import { ReactComponent as LGChevronDown } from './24/Chevron/Down.svg';
import { ReactComponent as LGChevronLeft } from './24/Chevron/Left.svg';
import { ReactComponent as LGChevronRight } from './24/Chevron/Right.svg';
import { ReactComponent as LGChevronUp } from './24/Chevron/Up.svg';
import { ReactComponent as LGFileAdd } from './24/File/Add.svg';
import { ReactComponent as LGFileCSV } from './24/File/CSV.svg';
import { ReactComponent as LGFileDelete } from './24/File/Delete.svg';
import { ReactComponent as LGFileDenials } from './24/File/Denials.svg';
import { ReactComponent as LGFileDescriptions } from './24/File/Description.svg';
import { ReactComponent as LGFileMinus } from './24/File/Minus.svg';
import { ReactComponent as LGFilePDF } from './24/File/PDF.svg';
import { ReactComponent as LGFileRejections } from './24/File/Rejections.svg';
import { ReactComponent as LGFileRejectionsRight } from './24/File/RejectionsRight.svg';
import { ReactComponent as LGListOne } from './24/List/1.svg';
import { ReactComponent as LGListTwo } from './24/List/2.svg';
import { ReactComponent as LGLoaderOne } from './24/Loader/1.svg';
import { ReactComponent as LGLoaderTwo } from './24/Loader/2.svg';
import { ReactComponent as LGLocked } from './24/Lock/Locked.svg';
import { ReactComponent as LGOpen } from './24/Lock/Open.svg';
import { ReactComponent as LGMoreHorizontal } from './24/More/Horizontal.svg';
import { ReactComponent as LGMoreSquare } from './24/More/Square.svg';
import { ReactComponent as LGMoreVertical } from './24/More/Vertical.svg';
import { ReactComponent as LGNoteArticle } from './24/Note/Article.svg';
import { ReactComponent as LGStateComplete } from './24/State/Complete.svg';
import { ReactComponent as LGStateDue } from './24/State/Due.svg';
import { ReactComponent as LGStateInProgress } from './24/State/InProgress.svg';
import { ReactComponent as LGStateSelect } from './24/State/Select.svg';
import { ReactComponent as LGViewCards } from './24/View/Cards.svg';
import { ReactComponent as LGViewModule } from './24/View/Module.svg';
import { ReactComponent as LGVisibilityOff } from './24/Visibility/Off.svg';
import { ReactComponent as LGVisibilityOn } from './24/Visibility/On.svg';
import { ReactComponent as LGAccount } from './24/Account.svg';
import { ReactComponent as LGAddColumn } from './24/AddColumn.svg';
import { ReactComponent as LGAdd } from './24/Add.svg';
import { ReactComponent as LGAnalytics } from './24/Analytics.svg';
import { ReactComponent as LGBatches } from './24/Batches.svg';
import { ReactComponent as LGBell } from './24/Bell.svg';
import { ReactComponent as LGCalendar } from './24/Calendar.svg';
import { ReactComponent as LGCancel } from './24/Cancel.svg';
import { ReactComponent as LGCheck } from './24/Check.svg';
import { ReactComponent as LGColumns } from './24/Columns.svg';
import { ReactComponent as LGCompose } from './24/Compose.svg';
import { ReactComponent as LGConfirmation } from './24/Confirmation.svg';
import { ReactComponent as LGContentCopy } from './24/ContentCopy.svg';
import { ReactComponent as LGCopy } from './24/Copy.svg';
import { ReactComponent as LGCpu } from './24/Cpu.svg';
import { ReactComponent as LGCreate } from './24/Create.svg';
import { ReactComponent as LGDashboard } from './24/Dashboard.svg';
import { ReactComponent as LGDownloads } from './24/Downloads.svg';
import { ReactComponent as LGEdit } from './24/Edit.svg';
import { ReactComponent as LGEnter } from './24/Enter.svg';
import { ReactComponent as LGError } from './24/Error.svg';
import { ReactComponent as LGFavorites } from './24/Favorites.svg';
import { ReactComponent as LGFile } from './24/File.svg';
import { ReactComponent as LGFilter } from './24/Filter.svg';
import { ReactComponent as LGFlag } from './24/Flag.svg';
import { ReactComponent as LGGroup } from './24/Group.svg';
import { ReactComponent as LGHelp } from './24/Help.svg';
import { ReactComponent as LGHistory } from './24/History.svg';
import { ReactComponent as LGVoid } from './24/Void.svg';
import { ReactComponent as LGHourglass } from './24/Hourglass.svg';
import { ReactComponent as LGKey } from './24/Key.svg';
import { ReactComponent as LGLayers } from './24/Layers.svg';
import { ReactComponent as LGLibrary } from './24/Library.svg';
import { ReactComponent as LGMenu } from './24/Menu.svg';
import { ReactComponent as LGMic } from './24/Mic.svg';
import { ReactComponent as LGMore } from './24/More.svg';
import { ReactComponent as LGPatient } from './24/Patient.svg';
import { ReactComponent as LGPayment } from './24/Payment.svg';
import { ReactComponent as LGPending } from './24/Pending.svg';
import { ReactComponent as LGPin } from './24/Pin.svg';
import { ReactComponent as LGPlay } from './24/Play.svg';
import { ReactComponent as LGPrint } from './24/Print.svg';
import { ReactComponent as LGRejected } from './24/Rejected.svg';
import { ReactComponent as LGRemove } from './24/Remove.svg';
import { ReactComponent as LGRepeat } from './24/Repeat.svg';
import { ReactComponent as LGReportProblem } from './24/ReportProblem.svg';
import { ReactComponent as LGSearch } from './24/Search.svg';
import { ReactComponent as LGSecurity } from './24/Security.svg';
import { ReactComponent as LGSettings } from './24/Settings.svg';
import { ReactComponent as LGShare } from './24/Share.svg';
import { ReactComponent as LGSuccess } from './24/Success.svg';
import { ReactComponent as LGTrash } from './24/Trash.svg';
import { ReactComponent as LGView } from './24/View.svg';
import { ReactComponent as LGWarning } from './24/Warning.svg';
import { ReactComponent as LGWatch } from './24/Watch.svg';
import { ReactComponent as LGWindows } from './24/Windows.svg';

//for middle size icon
import { ReactComponent as MDAccountAdd } from './20/Account/Add.svg';
import { ReactComponent as MDAccountDelete } from './20/Account/Delete.svg';
import { ReactComponent as MDAccountRemove } from './20/Account/Remove.svg';
import { ReactComponent as MDAccountTick } from './20/Account/Tick.svg';
import { ReactComponent as MDArticleAdd } from './20/Article/Add.svg';
import { ReactComponent as MDChevronDown } from './20/Chevron/Down.svg';
import { ReactComponent as MDChevronLeft } from './20/Chevron/Left.svg';
import { ReactComponent as MDChevronRight } from './20/Chevron/Right.svg';
import { ReactComponent as MDChevronUp } from './20/Chevron/Up.svg';
import { ReactComponent as MDFileAdd } from './20/File/Add.svg';
import { ReactComponent as MDFileCSV } from './20/File/CSV.svg';
import { ReactComponent as MDFileDelete } from './20/File/Delete.svg';
import { ReactComponent as MDFileDenials } from './20/File/Denials.svg';
import { ReactComponent as MDFileDescriptions } from './20/File/Description.svg';
import { ReactComponent as MDFileMinus } from './20/File/Minus.svg';
import { ReactComponent as MDFilePDF } from './20/File/PDF.svg';
import { ReactComponent as MDFileRejections } from './20/File/Rejections.svg';
import { ReactComponent as MDListOne } from './20/List/1.svg';
import { ReactComponent as MDListTwo } from './20/List/2.svg';
import { ReactComponent as MDLoaderOne } from './20/Loader/1.svg';
import { ReactComponent as MDLoaderTwo } from './20/Loader/2.svg';
import { ReactComponent as MDLocked } from './20/Lock/Locked.svg';
import { ReactComponent as MDOpen } from './20/Lock/Unlock.svg';
import { ReactComponent as MDMoreHorizontal } from './20/More/Horizontal.svg';
import { ReactComponent as MDMoreSquare } from './20/More/Square.svg';
import { ReactComponent as MDMoreVertical } from './20/More/Vertical.svg';
import { ReactComponent as MDNoteArticle } from './20/Note/Article.svg';
import { ReactComponent as MDStateComplete } from './20/State/Complete.svg';
import { ReactComponent as MDStateDue } from './20/State/Due.svg';
import { ReactComponent as MDStateInProgress } from './20/State/InProgress.svg';
import { ReactComponent as MDStateSelect } from './20/State/Select.svg';
import { ReactComponent as MDViewCardOne } from './20/View/Cards-1.svg';
import { ReactComponent as MDViewCards } from './20/View/Cards.svg';
import { ReactComponent as MDViewModule } from './20/View/Module.svg';
import { ReactComponent as MDVisibilityOff } from './20/Visibility/Off.svg';
import { ReactComponent as MDVisibilityOn } from './20/Visibility/On.svg';
import { ReactComponent as MDAccount } from './20/Account.svg';
import { ReactComponent as MDAddColumn } from './20/AddColumn.svg';
import { ReactComponent as MDAdd } from './20/Add.svg';
import { ReactComponent as MDAnalytics } from './20/Analytics.svg';
import { ReactComponent as MDBatches } from './20/Batches.svg';
import { ReactComponent as MDBell } from './20/Bell.svg';
import { ReactComponent as MDCalendar } from './20/Calendar.svg';
import { ReactComponent as MDCancel } from './20/Cancel.svg';
import { ReactComponent as MDCheck } from './20/Check.svg';
import { ReactComponent as MDColumns } from './20/Columns.svg';
import { ReactComponent as MDCompose } from './20/Compose.svg';
import { ReactComponent as MDConfirmation } from './20/Confirmation.svg';
import { ReactComponent as MDContentCopy } from './20/ContentCopy.svg';
import { ReactComponent as MDCopy } from './20/Copy.svg';
import { ReactComponent as MDCpu } from './20/Cpu.svg';
import { ReactComponent as MDCreate } from './20/Create.svg';
import { ReactComponent as MDDashboard } from './20/Dashboard.svg';
import { ReactComponent as MDDownloads } from './20/Downloads.svg';
import { ReactComponent as MDEdit } from './20/Edit.svg';
import { ReactComponent as MDEnter } from './20/Enter.svg';
import { ReactComponent as MDError } from './20/Error.svg';
import { ReactComponent as MDFavorites } from './20/Favorites.svg';
import { ReactComponent as MDFile } from './20/File.svg';
import { ReactComponent as MDFilter } from './20/Filter.svg';
import { ReactComponent as MDFlag } from './20/Flag.svg';
import { ReactComponent as MDGroup } from './20/Group.svg';
import { ReactComponent as MDHelp } from './20/Help.svg';
import { ReactComponent as MDHistory } from './20/History.svg';
import { ReactComponent as MDHourglass } from './20/Hourglass.svg';
import { ReactComponent as MDKey } from './20/Key.svg';
import { ReactComponent as MDLayers } from './20/Layers.svg';
import { ReactComponent as MDLibrary } from './20/Library.svg';
import { ReactComponent as MDMenu } from './20/Menu.svg';
import { ReactComponent as MDMic } from './20/Mic.svg';
import { ReactComponent as MDMore } from './20/More.svg';
import { ReactComponent as MDPatient } from './20/Patient.svg';
import { ReactComponent as MDPayment } from './20/Payment.svg';
import { ReactComponent as MDPending } from './20/Pending.svg';
import { ReactComponent as MDPin } from './20/Pin.svg';
import { ReactComponent as MDPlay } from './20/Play.svg';
import { ReactComponent as MDPrint } from './20/Print.svg';
import { ReactComponent as MDRejected } from './20/Rejected.svg';
import { ReactComponent as MDRemove } from './20/Remove.svg';
import { ReactComponent as MDRepeat } from './20/Repeat.svg';
import { ReactComponent as MDReportProblem } from './20/ReportProblem.svg';
import { ReactComponent as MDSearch } from './20/Search.svg';
import { ReactComponent as MDSecurity } from './20/Security.svg';
import { ReactComponent as MDSettings } from './20/Settings.svg';
import { ReactComponent as MDShare } from './20/Share.svg';
import { ReactComponent as MDSuccess } from './20/Success.svg';
import { ReactComponent as MDTrash } from './20/Trash.svg';
import { ReactComponent as MDTrashRounded } from './20/TrashRounded.svg';
import { ReactComponent as MDView } from './20/View.svg';
import { ReactComponent as MDWarning } from './20/Warning.svg';
import { ReactComponent as MDWatch } from './20/Watch.svg';
import { ReactComponent as MDWindows } from './20/Windows.svg';

//for small size icon
import { ReactComponent as SMAccountAdd } from './16/Account/Add.svg';
import { ReactComponent as SMAccountDelete } from './16/Account/Delete.svg';
import { ReactComponent as SMAccountRemove } from './16/Account/Remove.svg';
import { ReactComponent as SMAccountTick } from './16/Account/Tick.svg';
import { ReactComponent as SMArticleAdd } from './16/Article/Add.svg';
import { ReactComponent as SMChevronDown } from './16/Chevron/Down.svg';
import { ReactComponent as SMChevronLeft } from './16/Chevron/Left.svg';
import { ReactComponent as SMChevronRight } from './16/Chevron/Right.svg';
import { ReactComponent as SMChevronRightActive } from './16/Chevron/Right-active.svg';
import { ReactComponent as SMChevronUp } from './16/Chevron/Up.svg';
import { ReactComponent as SMFileCSV } from './16/File/CSV.svg';
import { ReactComponent as SMFileDelete } from './16/File/Delete.svg';
import { ReactComponent as SMFileDenials } from './16/File/Denials.svg';
import { ReactComponent as SMFileDescriptions } from './16/File/Description.svg';
import { ReactComponent as SMFilePDF } from './16/File/PDF.svg';
import { ReactComponent as SMFileRejections } from './16/File/Rejections.svg';
import { ReactComponent as SMListOne } from './16/List/1.svg';
import { ReactComponent as SMListTwo } from './16/List/2.svg';
import { ReactComponent as SMLoaderOne } from './16/Loader/1.svg';
import { ReactComponent as SMLoaderTwo } from './16/Loader/2.svg';
import { ReactComponent as SMLocked } from './16/Lock/Locked.svg';
import { ReactComponent as SMOpen } from './16/Lock/Unlock.svg';
import { ReactComponent as SMMoreHorizontal } from './16/More/Horizontal.svg';
import { ReactComponent as SMMoreSquare } from './16/More/Square.svg';
import { ReactComponent as SMMoreVertical } from './16/More/Vertical.svg';
import { ReactComponent as SMNoteArticle } from './16/Note/Article.svg';
import { ReactComponent as SMStateComplete } from './16/State/Complete.svg';
import { ReactComponent as SMStateDue } from './16/State/Due.svg';
import { ReactComponent as SMStateInProgress } from './16/State/InProgress.svg';
import { ReactComponent as SMStateSelect } from './16/State/Select.svg';
import { ReactComponent as SMViewModule } from './16/View/Module.svg';
import { ReactComponent as SMVisibilityOff } from './16/Visibility/Off.svg';
import { ReactComponent as SMVisibilityOn } from './16/Visibility/On.svg';
import { ReactComponent as SMAccount } from './16/Account.svg';
import { ReactComponent as SMAddColumn } from './16/AddColumn.svg';
import { ReactComponent as SMAdd } from './16/Add.svg';
import { ReactComponent as SMAnalytics } from './16/Analytics.svg';
import { ReactComponent as SMBatches } from './16/Batches.svg';
import { ReactComponent as SMBell } from './16/Bell.svg';
import { ReactComponent as SMCalendar } from './16/Calendar.svg';
import { ReactComponent as SMCancel } from './16/Cancel.svg';
import { ReactComponent as SMCheck } from './16/Check.svg';
import { ReactComponent as SMColumns } from './16/Columns.svg';
import { ReactComponent as SMCompose } from './16/Compose.svg';
import { ReactComponent as SMConfirmation } from './16/Confirmation.svg';
import { ReactComponent as SMContentCopy } from './16/ContentCopy.svg';
import { ReactComponent as SMCopy } from './16/Copy.svg';
import { ReactComponent as SMCpu } from './16/Cpu.svg';
import { ReactComponent as SMCreate } from './16/Create.svg';
import { ReactComponent as SMDashboard } from './16/Dashboard.svg';
import { ReactComponent as SMDownloads } from './16/Downloads.svg';
import { ReactComponent as SMEdit } from './16/Edit.svg';
import { ReactComponent as SMEnter } from './16/Enter.svg';
import { ReactComponent as SMError } from './16/Error.svg';
import { ReactComponent as SMFavorites } from './16/Favorites.svg';
import { ReactComponent as SMFile } from './16/File.svg';
import { ReactComponent as SMFilter } from './16/Filter.svg';
import { ReactComponent as SMFlag } from './16/Flag.svg';
import { ReactComponent as SMGroup } from './16/Group.svg';
import { ReactComponent as SMGeneralSettings } from './16/general-settings.svg';
import { ReactComponent as SMHelp } from './16/Help.svg';
import { ReactComponent as SMHome } from './16/home.svg';
import { ReactComponent as SMHistory } from './16/History.svg';
import { ReactComponent as SMHourglass } from './16/Hourglass.svg';
import { ReactComponent as SMKey } from './16/Key.svg';
import { ReactComponent as SMLayers } from './16/Layers.svg';
import { ReactComponent as SMLibrary } from './16/Library.svg';
import { ReactComponent as SMMenu } from './16/Menu.svg';
import { ReactComponent as SMMic } from './16/Mic.svg';
import { ReactComponent as SMMore } from './16/More.svg';
import { ReactComponent as SMPatient } from './16/Patient.svg';
import { ReactComponent as SMPayment } from './16/Payment.svg';
import { ReactComponent as SMPending } from './16/Pending.svg';
import { ReactComponent as SMPin } from './16/Pin.svg';
import { ReactComponent as SMPlay } from './16/Play.svg';
import { ReactComponent as SMPrint } from './16/Print.svg';
import { ReactComponent as SMRejected } from './16/Rejected.svg';
import { ReactComponent as SMRemove } from './16/Remove.svg';
import { ReactComponent as SMRepeat } from './16/Repeat.svg';
import { ReactComponent as SMReportProblem } from './16/ReportProblem.svg';
import { ReactComponent as SMSearch } from './16/Search.svg';
import { ReactComponent as SMSecurity } from './16/Security.svg';
import { ReactComponent as SMSettings } from './16/Settings.svg';
import { ReactComponent as SMShare } from './16/Share.svg';
import { ReactComponent as SMSuccess } from './16/Success.svg';
import { ReactComponent as SMTrash } from './16/Trash.svg';
import { ReactComponent as SMView } from './16/View.svg';
import { ReactComponent as SMWarning } from './16/Warning.svg';
import { ReactComponent as SMWatch } from './16/Watch.svg';
import { ReactComponent as SMWindows } from './16/Windows.svg';

// for extra small icons
import { ReactComponent as XSChevronDown } from './12/Chevron/Filled/Down.svg';
import { ReactComponent as XSChevronUp } from './12/Chevron/Filled/Up.svg';
import { ReactComponent as XSCancel } from './12/Cancel.svg';
import { ReactComponent as XSManager } from './12/Manager.svg';
import { ReactComponent as XSMenu } from './12/Menu.svg';
import { ReactComponent as XSPlus } from './12/Plus.svg';
import { ReactComponent as XSGreenSquare } from './12/GreenSquare.svg';
import { ReactComponent as XSRedSquare } from './12/RedSquare.svg';
import { setMarginBottom, setMarginLeft, setMarginRight, setMarginTop } from '../utils/index';

const siteMark = ['billing-provider', 'facility', 'insurance', 'manager', 'site-patient', 'payor', 'physician'];

const setWidth = (props) => {
  if (siteMark.includes(props.name)) {
    switch (props.size) {
      case 'lg':
        return '24px';
      case 'md':
        return '20px';
      case 'sm':
        return '16px';
      case 'xs':
        return '12px';
      case 'default':
      case undefined:
        return '64px';
      default:
        return props.size + 'px';
    }
  } else {
    switch (props.size) {
      case 'lg':
        return '24px';
      case 'md':
        return '20px';
      case 'sm':
        return '16px';
      case 'xs':
        return '12px';
      case 'default':
      case undefined:
        return '24px';
      default:
        return props.size + 'px';
    }
  }
};

const Icon = (props) => {
  const { name, size, id } = props;
    const classes = {
        root: {
            display: 'inline-block',
            minWidth: setWidth(props),
            minHeight: setWidth(props),
            flexShrink: 0,
            width: setWidth(props),
            height: setWidth(props),
            marginTop: setMarginTop(props, '0px'),
            marginBottom: setMarginBottom(props, '0px'),
            marginRight: setMarginRight(props, '0px'),
            marginLeft: setMarginLeft(props, '0px'),
            '& svg': {
                width: '100%',
                height: '100%',
                display: 'flex',
                flexShrink: 0,
            },
        }
  };
  return (
    <Box sx={[classes.root, props.className]} onClick={props.onClick} data-testid={name} id={id} className={name}>
      {name === 'arrow-down' && <ArrowDown stroke={props.color || '#6D6D6D'} />}
      {name === 'arrow-up' && <ArrowUp stroke={props.color || '#6D6D6D'} />}
      {name === 'upload' && <UploadDocument />}
      {name === 'billing-provider' && <img src="/images/icons/64/BillingProvider.svg" alt="billing-provider" />}
      {name === 'facility' && <img src="/images/icons/64/Facility.svg" alt="facility" />}
      {name === 'insurance' && <img src="/images/icons/64/Insurance.svg" alt="insurance" />}
      {name === 'manager' && <img src="/images/icons/64/Manager.svg" alt="manager" />}
      {name === 'site-patient' && <img src="/images/icons/64/Patient.svg" alt="patient" />}
      {name === 'payor' && <img src="/images/icons/64/Payor.svg" alt="payor" />}
      {name === 'physician' && <img src="/images/icons/64/Physician.svg" alt="physician" />}
      {size === 'lg' && (
        <>
          {name === 'account-add' && <LGAccountAdd />}
          {name === 'account-delete' && <LGAccountDelete />}
          {name === 'account-remove' && <LGAccountRemove />}
          {name === 'account-tick' && <LGAccountTick />}
          {name === 'article-add' && <LGArticleAdd />}
          {name === 'chevron-down' && <LGChevronDown />}
          {name === 'chevron-left' && <LGChevronLeft />}
          {name === 'chevron-right' && <LGChevronRight />}
          {name === 'chevron-up' && <LGChevronUp />}
          {name === 'file-add' && <LGFileAdd />}
          {name === 'file-csv' && <LGFileCSV />}
          {name === 'file-delete' && <LGFileDelete />}
          {name === 'file-denials' && <LGFileDenials />}
          {name === 'file-description' && <LGFileDescriptions />}
          {name === 'file-minus' && <LGFileMinus />}
          {name === 'file-pdf' && <LGFilePDF />}
          {name === 'file-rejections' && <LGFileRejections />}
          {name === 'file-rejections-right' && <LGFileRejectionsRight />}
          {name === 'list-one' && <LGListOne />}
          {name === 'list-two' && <LGListTwo />}
          {name === 'loader-one' && <LGLoaderOne />}
          {name === 'loader-two' && <LGLoaderTwo />}
          {name === 'locked' && <LGLocked stroke={props.color || '#6D6D6D'} />}
          {name === 'open' && <LGOpen />}
          {name === 'more-horizontal' && <LGMoreHorizontal />}
          {name === 'more-square' && <LGMoreSquare />}
          {name === 'more-vertical' && <LGMoreVertical />}
          {name === 'note-article' && <LGNoteArticle />}
          {name === 'state-complete' && <LGStateComplete />}
          {name === 'state-in-progress' && <LGStateInProgress />}
          {name === 'state-due' && <LGStateDue />}
          {name === 'state-select' && <LGStateSelect />}
          {name === 'view-cards' && <LGViewCards />}
          {name === 'view-module' && <LGViewModule />}
          {name === 'visibility-off' && <LGVisibilityOff />}
          {name === 'visibility-on' && <LGVisibilityOn />}
          {name === 'account' && <LGAccount fill={props.color || '#6D6D6D'} />}
          {name === 'add-column' && <LGAddColumn />}
          {name === 'add' && <LGAdd stroke={props.color || '#6D6D6D'} />}
          {name === 'analytics' && <LGAnalytics />}
          {name === 'batches' && <LGBatches />}
          {name === 'bell' && <LGBell stroke={props.color || '#6D6D6D'} />}
          {name === 'calendar' && <LGCalendar />}
          {name === 'cancel' && <LGCancel stroke={props.color || '#6D6D6D'} />}
          {name === 'check' && <LGCheck />}
          {name === 'columns' && <LGColumns />}
          {name === 'compose' && <LGCompose />}
          {name === 'confirmation' && <LGConfirmation stroke={props.color || '#6D6D6D'} />}
          {name === 'content-copy' && <LGContentCopy />}
          {name === 'copy' && <LGCopy />}
          {name === 'cpu' && <LGCpu />}
          {name === 'create' && <LGCreate />}
          {name === 'dashboard' && <LGDashboard />}
          {name === 'downloads' && <LGDownloads />}
          {name === 'edit' && <LGEdit stroke={props.color || '#6D6D6D'} />}
          {name === 'enter' && <LGEnter />}
          {name === 'error' && <LGError stroke={props.color || '#6D6D6D'} />}
          {name === 'favorites' && <LGFavorites />}
          {name === 'file' && <LGFile />}
          {name === 'filter' && <LGFilter />}
          {name === 'flag' && <LGFlag />}
          {name === 'group' && <LGGroup />}
          {name === 'help' && <LGHelp />}
          {name === 'history' && <LGHistory />}
          {name === 'void' && <LGVoid />}
          {name === 'hourglass' && <LGHourglass stroke={props.color || '#6D6D6D'} />}
          {name === 'key' && <LGKey />}
          {name === 'layers' && <LGLayers />}
          {name === 'library' && <LGLibrary />}
          {name === 'menu' && <LGMenu />}
          {name === 'mic' && <LGMic />}
          {name === 'more' && <LGMore stroke={props.color || '#6D6D6D'} />}
          {name === 'patient' && <LGPatient />}
          {name === 'payment' && <LGPayment />}
          {name === 'pending' && <LGPending />}
          {name === 'pin' && <LGPin />}
          {name === 'play' && <LGPlay />}
          {name === 'print' && <LGPrint />}
          {name === 'reject' && <LGRejected />}
          {name === 'remove' && <LGRemove />}
          {name === 'repeat' && <LGRepeat />}
          {name === 'report-problem' && (
            <LGReportProblem
              stroke={props.color || '#6D6D6D'}
              // fill={props.color || "#6D6D6D"}
            />
          )}
          {name === 'search' && <LGSearch />}
          {name === 'security' && <LGSecurity />}
          {name === 'settings' && <LGSettings />}
          {name === 'share' && <LGShare />}
          {name === 'success' && <LGSuccess fill={props.color || '#46CC2E'} />}
          {name === 'trash' && <LGTrash stroke={props.color || '#6D6D6D'} />}
          {name === 'view' && <LGView />}
          {name === 'warning' && <LGWarning />}
          {name === 'watch' && <LGWatch />}
          {name === 'windows' && <LGWindows />}
        </>
      )}
      {size === 'md' && (
        <>
          {name === 'account-add' && <MDAccountAdd />}
          {name === 'account-delete' && <MDAccountDelete />}
          {name === 'account-remove' && <MDAccountRemove />}
          {name === 'account-tick' && <MDAccountTick />}
          {name === 'article-add' && <MDArticleAdd />}
          {name === 'chevron-down' && <MDChevronDown />}
          {name === 'chevron-left' && <MDChevronLeft />}
          {name === 'chevron-right' && <MDChevronRight />}
          {name === 'chevron-up' && <MDChevronUp />}
          {name === 'file-add' && <MDFileAdd />}
          {name === 'file-csv' && <MDFileCSV />}
          {name === 'file-delete' && <MDFileDelete />}
          {name === 'file-denials' && <MDFileDenials />}
          {name === 'file-description' && <MDFileDescriptions />}
          {name === 'file-minus' && <MDFileMinus />}
          {name === 'file-pdf' && <MDFilePDF />}
          {name === 'file-rejections' && <MDFileRejections />}
          {name === 'list-one' && <MDListOne />}
          {name === 'list-two' && <MDListTwo />}
          {name === 'loader-one' && <MDLoaderOne />}
          {name === 'loader-two' && <MDLoaderTwo />}
          {name === 'locked' && <MDLocked stroke={props.color || '#6D6D6D'} />}
          {name === 'open' && <MDOpen />}
          {name === 'more-horizontal' && <MDMoreHorizontal />}
          {name === 'more-square' && <MDMoreSquare />}
          {name === 'more-vertical' && <MDMoreVertical />}
          {name === 'note-article' && <MDNoteArticle />}
          {name === 'state-complete' && <MDStateComplete />}
          {name === 'state-in-progress' && <MDStateInProgress />}
          {name === 'state-due' && <MDStateDue />}
          {name === 'state-select' && <MDStateSelect />}
          {name === 'view-cards-one' && <MDViewCardOne />}
          {name === 'view-cards' && <MDViewCards />}
          {name === 'view-module' && <MDViewModule />}
          {name === 'visibility-off' && <MDVisibilityOff />}
          {name === 'visibility-on' && <MDVisibilityOn />}
          {name === 'account' && <MDAccount fill={props.color || '#6D6D6D'} />}
          {name === 'add-column' && <MDAddColumn />}
          {name === 'add' && <MDAdd stroke={props.color || '#6D6D6D'} />}
          {name === 'analytics' && <MDAnalytics />}
          {name === 'batches' && <MDBatches />}
          {name === 'bell' && <MDBell stroke={props.color || '#6D6D6D'} />}
          {name === 'calendar' && <MDCalendar />}
          {name === 'cancel' && <MDCancel stroke={props.color || '#6D6D6D'} />}
          {name === 'check' && <MDCheck />}
          {name === 'columns' && <MDColumns />}
          {name === 'compose' && <MDCompose />}
          {name === 'confirmation' && <MDConfirmation stroke={props.color || '#6D6D6D'} />}
          {name === 'content-copy' && <MDContentCopy />}
          {name === 'copy' && <MDCopy />}
          {name === 'cpu' && <MDCpu />}
          {name === 'create' && <MDCreate />}
          {name === 'dashboard' && <MDDashboard />}
          {name === 'downloads' && <MDDownloads />}
          {name === 'edit' && <MDEdit stroke={props.color || '#6D6D6D'} />}
          {name === 'enter' && <MDEnter />}
          {name === 'error' && <MDError stroke={props.color || '#6D6D6D'} />}
          {name === 'favorites' && <MDFavorites />}
          {name === 'file' && <MDFile />}
          {name === 'filter' && <MDFilter />}
          {name === 'flag' && <MDFlag />}
          {name === 'group' && <MDGroup />}
          {name === 'help' && <MDHelp />}
          {name === 'history' && <MDHistory />}
          {name === 'hourglass' && <MDHourglass fill={props.color || '#6D6D6D'} />}
          {name === 'key' && <MDKey />}
          {name === 'layers' && <MDLayers />}
          {name === 'library' && <MDLibrary />}
          {name === 'menu' && <MDMenu />}
          {name === 'mic' && <MDMic />}
          {name === 'more' && <MDMore fill={props.color || '#6D6D6D'} />}
          {name === 'patient' && <MDPatient />}
          {name === 'payment' && <MDPayment />}
          {name === 'pending' && <MDPending />}
          {name === 'pin' && <MDPin />}
          {name === 'play' && <MDPlay />}
          {name === 'print' && <MDPrint />}
          {name === 'reject' && <MDRejected />}
          {name === 'remove' && <MDRemove />}
          {name === 'repeat' && <MDRepeat />}
          {name === 'report-problem' && (
            <MDReportProblem
              stroke={props.color || '#6D6D6D'}
              // fill={props.color || "#6D6D6D"}
            />
          )}
          {name === 'search' && <MDSearch />}
          {name === 'security' && <MDSecurity />}
          {name === 'settings' && <MDSettings stroke={props.color || '#6D6D6D'} />}
          {name === 'share' && <MDShare />}
          {name === 'success' && <MDSuccess fill={props.color || '#46CC2E'} />}
          {name === 'trash' && <MDTrash stroke={props.color || '#6D6D6D'} />}
          {name === 'trash-rounded' && <MDTrashRounded />}
          {name === 'view' && <MDView />}
          {name === 'warning' && <MDWarning />}
          {name === 'watch' && <MDWatch />}
          {name === 'windows' && <MDWindows />}
        </>
      )}
      {size !== 'xl' && size !== 'lg' && size !== 'md' && size !== 'xs' && (
        <>
          {name === 'account-add' && <SMAccountAdd />}
          {name === 'account-delete' && <SMAccountDelete />}
          {name === 'account-remove' && <SMAccountRemove />}
          {name === 'account-tick' && <SMAccountTick />}
          {name === 'article-add' && <SMArticleAdd />}
          {name === 'chevron-down' && <SMChevronDown />}
          {name === 'chevron-left' && <SMChevronLeft />}
          {name === 'chevron-right' && <SMChevronRight />}
          {name === 'chevron-right-active' && <SMChevronRightActive/>}
          {name === 'chevron-up' && <SMChevronUp />}
          {name === 'file-csv' && <SMFileCSV />}
          {name === 'file-delete' && <SMFileDelete />}
          {name === 'file-denials' && <SMFileDenials />}
          {name === 'file-description' && <SMFileDescriptions />}
          {name === 'file-pdf' && <SMFilePDF />}
          {name === 'file-rejections' && <SMFileRejections />}
          {name === 'list-one' && <SMListOne />}
          {name === 'list-two' && <SMListTwo />}
          {name === 'loader-one' && <SMLoaderOne />}
          {name === 'loader-two' && <SMLoaderTwo />}
          {name === 'locked' && <SMLocked stroke={props.color || '#6D6D6D'} />}
          {name === 'open' && <SMOpen />}
          {name === 'more-horizontal' && <SMMoreHorizontal />}
          {name === 'more-square' && <SMMoreSquare />}
          {name === 'more-vertical' && <SMMoreVertical />}
          {name === 'note-article' && <SMNoteArticle />}
          {name === 'state-complete' && <SMStateComplete />}
          {name === 'state-in-progress' && <SMStateInProgress />}
          {name === 'state-due' && <SMStateDue />}
          {name === 'state-select' && <SMStateSelect />}
          {name === 'view-module' && <SMViewModule />}
          {name === 'visibility-off' && <SMVisibilityOff />}
          {name === 'visibility-on' && <SMVisibilityOn />}
          {name === 'account' && <SMAccount fill={props.color || '#6D6D6D'} />}
          {name === 'add-column' && <SMAddColumn />}
          {name === 'add' && <SMAdd stroke={props.color || '#6D6D6D'} />}
          {name === 'analytics' && <SMAnalytics />}
          {name === 'batches' && <SMBatches />}
          {name === 'bell' && <SMBell stroke={props.color || '#6D6D6D'} />}
          {name === 'calendar' && <SMCalendar />}
          {name === 'cancel' && <SMCancel stroke={props.color || '#6D6D6D'} />}
          {name === 'check' && <SMCheck />}
          {name === 'columns' && <SMColumns />}
          {name === 'compose' && <SMCompose />}
          {name === 'confirmation' && <SMConfirmation stroke={props.color || '#6D6D6D'} />}
          {name === 'content-copy' && <SMContentCopy />}
          {name === 'copy' && <SMCopy />}
          {name === 'cpu' && <SMCpu />}
          {name === 'create' && <SMCreate />}
          {name === 'dashboard' && <SMDashboard />}
          {name === 'downloads' && <SMDownloads />}
          {name === 'edit' && <SMEdit stroke={props.color || '#6D6D6D'} />}
          {name === 'enter' && <SMEnter />}
          {name === 'error' && <SMError stroke={props.color || '#6D6D6D'} />}
          {name === 'favorites' && <SMFavorites />}
          {name === 'file' && <SMFile />}
          {name === 'filter' && <SMFilter />}
          {name === 'flag' && <SMFlag />}
          {name === 'group' && <SMGroup />}
          {name === 'general-settings' && <SMGeneralSettings />}
          {name === 'help' && <SMHelp />}
          {name === 'home' && <SMHome />}
          {name === 'history' && <SMHistory />}
          {name === 'hourglass' && <SMHourglass stroke={props.color || '#6D6D6D'} />}
          {name === 'key' && <SMKey />}
          {name === 'layers' && <SMLayers />}
          {name === 'library' && <SMLibrary />}
          {name === 'menu' && <SMMenu />}
          {name === 'mic' && <SMMic />}
          {name === 'more' && <SMMore stroke={props.color || '#6D6D6D'} />}
          {name === 'patient' && <SMPatient />}
          {name === 'payment' && <SMPayment />}
          {name === 'pending' && <SMPending />}
          {name === 'pin' && <SMPin />}
          {name === 'play' && <SMPlay />}
          {name === 'print' && <SMPrint />}
          {name === 'reject' && <SMRejected />}
          {name === 'remove' && <SMRemove />}
          {name === 'repeat' && <SMRepeat />}
          {name === 'report-problem' && <SMReportProblem stroke={props.color || '#6D6D6D'} />}
          {name === 'search' && <SMSearch />}
          {name === 'security' && <SMSecurity />}
          {name === 'settings' && <SMSettings />}
          {name === 'share' && <SMShare />}
          {name === 'success' && <SMSuccess fill={props.color || '#46CC2E'} />}
          {name === 'trash' && <SMTrash stroke={props.color || '#6D6D6D'} />}
          {name === 'view' && <SMView />}
          {name === 'warning' && <SMWarning />}
          {name === 'watch' && <SMWatch />}
          {name === 'windows' && <SMWindows />}
        </>
      )}
      {size === 'xs' && (
        <>
          {name === 'chevron-down' && <XSChevronDown />}
          {name === 'chevron-up' && <XSChevronUp />}
          {name === 'cancel' && <XSCancel stroke={props.color || '#6D6D6D'} />}
          {name === 'manager' && <XSManager />}
          {name === 'plus' && <XSPlus />}
          {name === 'menu' && <XSMenu />}
          {name === 'green-square' && <XSGreenSquare />}
          {name === 'red-square' && <XSRedSquare />}
        </>
      )}
    </Box>
  );
};

export default Icon;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNavbarShow: true,
  isFilterShow: false,
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    initialize(state, action) {
      state.isNavbarShow = true;
      state.isFilterShow = false;
    },
    changeNavbarShow(state, action) {
      if (!state.isNavbarShow) {
        state.isFilterShow = false;
      }
      state.isNavbarShow = !state.isNavbarShow;
    },
    changeFilterShow(state, action) {
      state.isFilterShow = !state.isFilterShow;
    },
    setNavbarShow(state, action) {
      state.isNavbarShow = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const layoutInitialize = () => async (dispatch) => {
  dispatch(slice.actions.initialize());
};

export const changeNavbarShow = () => (dispatch) => {
  dispatch(slice.actions.changeNavbarShow());
};

export const setNavbarShow = (val) => (dispatch) => {
  dispatch(slice.actions.setNavbarShow(val));
};

export const changeFilterShow = () => (dispatch) => {
  dispatch(slice.actions.changeFilterShow());
};

export default slice;

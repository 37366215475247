import { useTheme } from "@emotion/react";
import { useState,useRef } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Popover, Box, Paper } from "@mui/material";
import UserSvg from "./UserSvg";
import UserTool from "./UserTool";
import { logOut } from "src/slices/user";
import { useDispatch } from "react-redux";
export default function Tool() {
  const dispatch = useDispatch();
    const theme = useTheme();
    const classes = {
        root: {
          display: 'flex',
          flexShrink: '0',
        },
        paper: {
          width: theme.spacing(4),
          height: theme.spacing(4),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: theme.spacing(1),
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#ECFFFF',
          },
        },
    };
    const menuItems = [
        {
          component: UserSvg,
          itemName: 'user',
          show: UserTool,
        }
    ];
    const [menuItem, setItem] = useState('');
    const anchorEl = useRef(null);
    const navigate = useNavigate();

    const handleClick = (item) => {
        setItem(item);
    };

    const handleClose = () => {
        setItem('');
    };

  const handleLogOut = async () => {
    localStorage.clear();
    await dispatch(logOut())
    navigate('/auth/login',{ replace: true });
  };
  return (
    <Box sx={classes.root} ref={anchorEl}>
      {menuItems.map((item, key) => (
        <React.Fragment key={key}>
          <Paper
            sx={classes.paper}
            style={{
              backgroundColor: menuItem === item.itemName ? '#DCF4FF' : '',
            }}
            elevation={0}
            onClick={(e) => {
              handleClick(item.itemName);
            }}
            key={key}
          >
            <item.component subfill={menuItem === item.itemName ? '#4770FF' : ''}></item.component>
          </Paper>
          <Popover
            id="menus"
            open={menuItem === item.itemName}
            anchorEl={anchorEl.current}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <item.show onClose={handleClose} handleLogOut={handleLogOut} />
          </Popover>
        </React.Fragment>
      ))}
    </Box>
  );
}
import { Box, InputBase , Popover, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import validator from 'validator';

import Icon from '../icons';
import EndDaySelector from './endSelectCalendar';
import RangeList from './range';
import StartDaySelector from './startSelectCalendar';
import { MONTHS } from './utils';
import { useTheme } from '@mui/material';



const DateRangePicker = (props) => {
  const theme = useTheme();
  const classes = {
    range:{
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: props.width === 100 ? 'auto' : '210px',
      height: '32px',
      border: '1px solid #D6D6D6',
      borderRadius: '3px',
      cursor: 'pointer',
  
      '&:focus,&:active,&:focus-within': {
        border: '1px solid #0000ff',
      },
    },
    input: {
      marginLeft: '3px',
      flex: 1,
      fontSize: '11.5px',
    },
    label: {
      fontSize: '11.5px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      color: theme.palette.text.gray,
      marginBottom: '10px',
    },
    icon: {
      cursor: 'pointer',
    },
    calendars: {
      display: 'flex',
      width: '754px',
      overflow: 'hidden',
      top: '36px',
      left: 0,
      backgroundColor: '#fff',
      zIndex: 10,
    },
    calendarsarea: {
      display: 'flex',
      width: '158px',
      top: '36px',
      left: 0,
      backgroundColor: '#fff',
      zIndex: 10,
    },
    panel: {
      display: 'flex',
      left: '178px',
    },
  };
  let {
    futureSelect,
    setError,
    dateRange, // [start, end]
    setDateRange, //
  } = props;
  const now = new Date();

  const [displayDate, setDisplayDate] = useState(dateRange[0] ? dateRange[0] + '~' + dateRange[1] : '');

  const [endDateMonth, setEndDateMonth] = useState(
    dateRange[1] ? MONTHS[Number(dateRange[1].split('-')[1]) - 1] : MONTHS[now.getMonth()]
  );
  const [endDateYear, setEndDateYear] = useState(dateRange[1] ? Number(dateRange[1].split('-')[0]) : now.getFullYear());
  const [endDateDay, setEndDateDay] = useState(dateRange[1] ? Number(dateRange[1].split('-')[2]) : now.getDate());

  const [startDateDay, setStartDateDay] = useState(dateRange[0] ? Number(dateRange[0].split('-')[2]) : now.getDate());
  const [startDateMonth, setStartDateMonth] = useState(
    dateRange[0] ? MONTHS[Number(dateRange[0].split('-')[1]) - 1] : MONTHS[now.getMonth()]
  );
  const [startDateYear, setStartDateYear] = useState(
    dateRange[0] ? Number(dateRange[0].split('-')[0]) : now.getFullYear()
  );

  const [calendarToggle, setCalendarToggle] = useState(false);
  const [range, setRange] = useState(-1);
  const [isValid, setIsValid] = useState(true);

  // Manage calendar component
  const ref2 = useOnclickOutside(() => setCalendarToggle(false));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = 'calendar-picker';

  //For setting date range in custom range.
  useEffect(() => {
    const now = moment().format('YYYY-MM-DD');
    let start = '';
    let end = '';

    if (range === 0) {
      setDateRange([now, now]);
      start = now;
      end = now;
      setDisplayDate(now + ' ~ ' + now);
    } else if (range > 0) {
      const day = moment().subtract(range, 'days').format('YYYY-MM-DD');
      if (range === 1) {
        setDateRange([day, day]);
        setDisplayDate(day + ' ~ ' + day);
        start = day;
        end = day;
      } else {
        const startDate = moment(now)
          .subtract(range - 1, 'days')
          .format('YYYY-MM-DD');
        start = startDate;
        end = now;
        setDateRange([start, end]);
        setDisplayDate(start + ' ~ ' + end);
      }
    } else if (range === 'last_month') {
      const endDate = moment(now).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      const startDate = moment(now).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      start = startDate;
      end = endDate;
      setDateRange([start, end]);
      setDisplayDate(start + ' ~ ' + end);
    } else if (range === 'this_month') {
      const startDate = moment(now).startOf('month').format('YYYY-MM-DD');
      start = startDate;
      end = now;
      setDateRange([start, now]);
      setDisplayDate(start + ' ~ ' + now);
    } else if (range === 'last_year') {
      const endDate = moment(now).subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
      const startDate = moment(now).subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
      start = startDate;
      end = endDate;
      setDateRange([start, end]);
      setDisplayDate(start + ' ~ ' + end);
    }
    setStartDateDay(Number(start.split('-')[2]));
    setStartDateMonth(MONTHS[Number(start.split('-')[1]) - 1]);
    setStartDateYear(Number(start.split('-')[0]));
    setEndDateDay(Number(end.split('-')[2]));
    setEndDateMonth(MONTHS[Number(end.split('-')[1]) - 1]);
    setEndDateYear(Number(end.split('-')[0]));
    if (setError) setError(false);
  }, [range]); //eslint-disable-line

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setDisplayDate(dateRange[0] + ' ~ ' + dateRange[1]);
    }
    if (!dateRange[0] && !dateRange[1]) {
      setDisplayDate('');
      setRange(-1);
    }
  }, [dateRange]);

  const handleClosePicker = () => {
    setAnchorEl(null);
  };
  // When hitting Enter, we get the start and end date.
  const validDisplayPattern = /^\d{4}\-\d{2}\-\d{2}\s?~\s?\d{4}\-\d{2}\-\d{2}$/; //eslint-disable-line
  // On inputting value in input.
  const onInputDate = (e) => {
    setDisplayDate(e.target.value);
    const displayDate = e.target.value;

    if (displayDate) {
      const isValid = validDisplayPattern.test(displayDate);
      const tomorrow = moment(now).add(1, 'day').format('YYYY-MM-DD');

      if (isValid) {
        const first = displayDate.split('~')[0].trim();
        const second = displayDate.split('~')[1].trim();
        if (
          validator.isDate(first, { format: 'YYYY-MM-DD' }) &&
          validator.isDate(second, { format: 'YYYY-MM-DD' }) &&
          new Date(first) <= new Date(second) &&
          new Date(second) < new Date(tomorrow)
          // moment(first)?.startOf('day') <= moment(second)?.startOf('day') &&
          // moment(second)?.startOf('day') < moment(tomorrow)?.startOf('day')
        ) {
          setIsValid(true);
          if (setError) setError(false);
          setDateRange([first, second]);
          setStartDateDay(Number(first.split('-')[2]));
          setStartDateMonth(MONTHS[Number(first.split('-')[1]) - 1]);
          setStartDateYear(Number(first.split('-')[0]));
          setEndDateDay(Number(second.split('-')[2]));
          setEndDateMonth(MONTHS[Number(second.split('-')[1]) - 1]);
          setEndDateYear(Number(second.split('-')[0]));
          setRange(null);
        } else {
          setIsValid(false);
          if (setError) {
            setError(true);
          }
        }
      } else {
        if (setError) {
          setError(true);
        }
        setIsValid(false);
      }
    } else {
      if (setError) setError(false);
      setIsValid(true);
      setDateRange(['', '']);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      const isValid = validDisplayPattern.test(displayDate);
      if (isValid) {
        const first = displayDate.split('~')[0].trim();
        const second = displayDate.split('~')[1].trim();
        if (
          validator.isDate(first, { format: 'YYYY-MM-DD' }) &&
          validator.isDate(second, { format: 'YYYY-MM-DD' }) &&
          new Date(first) < new Date(second) &&
          new Date(second) <= new Date()
          // moment(first)?.startOf('day') < moment(second)?.startOf('day') &&
          // moment(second)?.startOf('day') <= moment()?.startOf('day')
        ) {
          setDateRange([first, second]);
          setRange(null);
        } else {
          setIsValid(false);
          if (setError) {
            setError(true);
          }
        }
      } else {
        setIsValid(false);
        if (setError) {
          setError(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!displayDate) {
      setIsValid(true);
    }
  }, [displayDate]);

  return (
    <Box sx={classes.root}>
      <Box sx={classes.range} tabIndex={1}>
        <InputBase
          sx={classes.input}
          placeholder={'YYYY-MM-DD~YYYY-MM-DD'}
          value={displayDate}
          onChange={onInputDate}
          onKeyDown={onKeyDown}
        />
        {isValid ? (
          <Icon
            name={'calendar'}
            size="sm"
            sx={classes.icon}
            onClick={(e) => {
              setAnchorEl(e.currentTarget.parentElement);
            }}
          />
        ) : (
          <Icon
            name={'error'}
            size="md"
            color="red"
            sx={classes.icon}
            onClick={(e) => {
              setIsValid(true);
              setDisplayDate('');
              setRange(null);
              if (setError) setError(false);
            }}
          />
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorReference="anchorEl"
        >
          <Box sx={calendarToggle ? classes.calendars : classes.calendarsarea}>
            <RangeList
              setRange={setRange}
              setToggle={setCalendarToggle}
              toggle={calendarToggle}
              handleClosePicker={handleClosePicker}
              isTimePicker={props.timepicker}
            />
            {calendarToggle ? (
              <Box sx={classes.panel} ref={ref2}>
                <StartDaySelector
                  setDate={setDateRange}
                  range={dateRange}
                  setMonth={setStartDateMonth}
                  setYear={setStartDateYear}
                  month={startDateMonth ? startDateMonth : MONTHS[now.getMonth()]}
                  year={startDateYear ? startDateYear : now.getFullYear()}
                  issecondCalendar={true}
                  futureSelect={futureSelect ? futureSelect : false}
                  endDateMonth={endDateMonth ? endDateMonth : MONTHS[now.getMonth()]}
                  endDateYear={endDateYear ? endDateYear : now.getFullYear()}
                  endDateDay={endDateDay ? endDateDay : now.getDate()}
                  selectedDay={startDateDay ? startDateDay : now.getDate()}
                  isFirstCalendar={true}
                  setSelectedDay={setStartDateDay}
                  key={1}
                  isTimePicker={props.timepicker}
                />
                <EndDaySelector
                  setDate={setDateRange}
                  range={dateRange}
                  month={endDateMonth ? endDateMonth : MONTHS[now.getMonth()]}
                  year={endDateYear ? endDateYear : now.getFullYear()}
                  startDateYear={startDateYear ? startDateYear : now.getFullYear()}
                  startDateMonth={startDateMonth ? startDateMonth : MONTHS[now.getMonth()]}
                  startDateDay={startDateDay ? startDateDay : now.getDate()}
                  setMonth={setEndDateMonth}
                  setYear={setEndDateYear}
                  setSelectedDay={setEndDateDay}
                  selectedDay={endDateDay ? endDateDay : now.getDate()}
                  isFirstCalendar={false}
                  futureSelect={futureSelect ? futureSelect : false}
                  key={2}
                  isTimePicker={props.timepicker}
                />
              </Box>
            ) : null}
          </Box>
        </Popover>
      </Box>
      {!isValid && (
        <Typography variant="caption" style={{ color: 'red', paddingLeft: '5px' }}>
          Invalid date format
        </Typography>
      )}
    </Box>
  );
};

DateRangePicker.propTypes = {
  width: PropTypes.number, // Set width of component
  futureSelect: PropTypes.bool, // Show the years more than current year or not.
  setError: PropTypes.func,
  error: PropTypes.bool,
  dateRange: PropTypes.array.isRequired,
  setDateRange: PropTypes.func.isRequired,
};

export default DateRangePicker;


import { Box, Typography } from "@mui/material";
// import CustomButton from "src/components/CustomButton";
import CustomButton from "src/reusable-components/buttons/CustomButton";
import TextField from "src/reusable-components/text-field";
import MultiSelect from "src/reusable-components/multi-select";
import { useEffect, useState } from "react";
import { GroupCheckBox } from "src/reusable-components/checkboxes";
import { getRoles ,getClients} from "src/slices/filter";
import { useDispatch, useSelector } from 'react-redux';
import { changeMultiSelectFormat, getValueListFromObjList } from "src/helper";
import { STATUS_PAST } from "src/utils/constant";

export default function UsersFilter({setNewFilter,setPage,fetchData}) {
  const classes = {
    root: {
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      backgroundColor: '#FFFFFF',
      paddingLeft: '3px',
      paddingBottom: '30px',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D2D2D2',
        borderRadius: '5px',
      },
    },
    footer: {
      background: '#FFFFFFDD',
      position: 'fixed',
      bottom: '-20px',
    },
    error: {
      color: 'red',
    },
    body: {
      paddingBottom: '45px'
    }
  };
  const dispatch = useDispatch();
  const [clientSelected, setClient] = useState([]);
  const [clientSearch, setClientSearch] = useState('');
  const [rolesSelected, setRoles] = useState([]);
  const [rolesSearch, setRolesSearch] = useState('');
  const [status, setStatus] = useState([]);
  const [userName, setUserName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');

  const {roles,clients} = useSelector((state) => state.filter);

  const getCurrentData = () => {
    var statusArr = status.includes('All') ? STATUS_PAST : status;
    let temp = {};
    temp.clients = getValueListFromObjList(clientSelected);
    temp.userName = userName;
    temp.firstName = firstName;
    temp.lastName = lastName;
    temp.email = email;
    temp.roles = getValueListFromObjList(rolesSelected);
    temp.status = statusArr;
    return temp;
  };

  const handleFind = () => {
   let currentFilter = getCurrentData();
    setNewFilter(currentFilter);
    dispatch(setPage(1));
    fetchData(currentFilter, 1);
    // props.setNewFilter(currentFilter);
    // props.setPage(1);
    // props.fetchData(currentFilter, 1);
  };

  const rolesOptions = roles ? changeMultiSelectFormat(roles, 'name') : [];
  const clientsOptions = clients ? changeMultiSelectFormat(clients, 'name') : [];

  useEffect(()=>{
    dispatch(getRoles());
    dispatch(getClients());
  },[]) // eslint-disable-line
  return (
    <Box sx={classes.root} tabIndex="100">
      <Box sx={classes.body}>
        <Box>
          <Box py={1}>
            <Typography variant="subtitle2">Client</Typography>
          </Box>
          <MultiSelect
            selectList={clientsOptions}
            selected={clientSelected}
            setSelect={setClient}
            search={clientSearch}
            setSearch={setClientSearch}
            placeholder="Select"
            size="small"
            width="200px"
          />
        </Box>
        <Box mt={2}>
          <Box py={1}>
            <Typography variant="subtitle2">Username</Typography>
          </Box>
          <TextField
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              value={userName}
              size="small"
              placeholder=""
              width="195px"
              autoComplete="off"
            />
        </Box>
        <Box mt={2}>
          <Box py={1}>
            <Typography variant="subtitle2">Email</Typography>
          </Box>
          <TextField
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              size="small"
              placeholder=""
              width="195px"
              autoComplete="off"
            />
        </Box>
        <Box mt={2}>
          <Box py={1}>
            <Typography variant="subtitle2">First Name</Typography>
          </Box>
          <TextField
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
              size="small"
              placeholder=""
              width="195px"
              autoComplete="off"
            />
        </Box>
        <Box mt={2}>
            <Box py={1}>
            <Typography variant="subtitle2">Last Name</Typography>
          </Box>
          <TextField
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastName}
              size="small"
              placeholder=""
              width="195px"
              autoComplete="off"
            />   
        </Box>
        <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Roles</Typography>
            </Box>
            <MultiSelect
              selectList={rolesOptions}
              selected={rolesSelected}
              setSelect={setRoles}
              search={rolesSearch}
              setSearch={setRolesSearch}
              placeholder="Select"
              size="small"
              width="200px"
            />
        </Box>
        <Box mt={2}>
          <GroupCheckBox
            title="Status"
            labelGroup={['All','Activated', 'Deactivated', 'Locked']}
            selectedLabels={status}
            setSelectedLabels={setStatus}
          />
        </Box>
        
      </Box>
      <Box my={3} sx={classes.footer}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton size="medium" width="200px" color="primary" text="Fetch" onClick={handleFind}/>
        </Box>
        <Box mt={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
        </Box>
      </Box>
    </Box>
  )
}
import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import NotificationItem from './NotificationItem';

const Notifications = () => {
  const notifications = useSelector((state) => state.notification.notifications);
  return (
    <Box
      position="fixed"
      width="fit-content"
      top="115px"
      zIndex="10000"
      style={{
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    >
      <Box display="flex" justifyContent="center">
        <Box width="fit-content">
          {notifications.map((item) => (
            <NotificationItem key={item.id} id={item.id} type={item.type} content={item.notification} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Notifications;

import FilteringList from "src/reusable-components/lists";
import { useMemo } from "react";
import { useSelector,useDispatch } from "react-redux";
import React from "react";
import { setCurrentFilter } from "src/slices/filter";
import { changeMultiSelectFormat } from "src/helper";
const Filter = React.memo(({ fetchTransactionData, setPage }) => {
    const dispatch = useDispatch();
    const {
        facilities,
        specialties,
        clients,
        instances,
        transactionTypes
    } = useSelector((state) => state.filter);
    const transactionData = useMemo(() => {
        let transactionData = {};
        transactionData.facilities = facilities ? changeMultiSelectFormat(facilities) : [];
        transactionData.specialties = specialties ? changeMultiSelectFormat(specialties) : [];
        transactionData.clients = clients ? changeMultiSelectFormat(clients) : [];
        transactionData.instances = instances ? changeMultiSelectFormat(instances) : [];
        transactionData.transactionTypes = transactionTypes ? changeMultiSelectFormat(transactionTypes) : [];
        return transactionData;
    }, [facilities, specialties, clients, instances, transactionTypes]);

    const setNewFilter = (curFilter) => {
        dispatch(setCurrentFilter(curFilter, 'transaction'));
    };
    return (
        <FilteringList 
            type="transaction" 
            data={transactionData} 
            fetchData={fetchTransactionData}
            setPage={setPage}
            setNewFilter={setNewFilter}
        />
    )
});
export default Filter

import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { getClientDetailInfo } from 'src/slices/management';

import Detail from './Details';
import Header from './Header';
import ClientCard from './ClientCard';

const ClientDetail = () => {
  const { cid } = useParams();
  const dispatch = useDispatch();
const [openContactBox, setOpenContactBox] = useState(false);
const { clientDetail, clientUpdated } = useSelector((state) => state.management);
  useEffect(() => {
    if (cid !== 'new' || clientUpdated) {
      dispatch(getClientDetailInfo(cid));
    }
  }, [clientUpdated, cid]); // eslint-disable-line
  
  return !isEmpty(clientDetail) ? (
    <Box px={7}>
      <Header clientId={cid} client={clientDetail}/>
      <Box mt={3}>
        <ClientCard clientDetail={clientDetail} setOpenContactBox={setOpenContactBox} />
      </Box>
      <Box mt={4}>
        <Detail clientDetail={clientDetail} clientId={cid}/>
      </Box>
    </Box>
  ) : (
    <LoadingScreen />
  );
};

export default ClientDetail;

import { Box, InputBase, Popover, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import validator from 'validator';

import Icon from '../icons';
import Calendar from './calendar';

const CustomDatePicker = ({ date, setDate, onChange, futureSelect, error, setError, width, height, marginBottom, disabled=false, startYear=1901, blockSchedule }) => {
    const theme = useTheme();
    const classes = {
        range: {
          padding: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '200px',
          height: '32px',
          border: '1px solid #D6D6D6',
          borderRadius: '3px',
          position: 'relative',
      
          '&:focus,&:active,&:focus-within': {
            border: '1px solid #0000ff',
          },
        },
        icon: {
          cursor: 'pointer',
        },
        input: {
          marginLeft: theme.spacing(1),
          flex: 1,
          '&.Mui-disabled': {
            color: theme.palette.text.primary,
          },
        },
        label: {
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 500,
          fontFamily: 'Inter',
          lineHeight: '16px',
          color: theme.palette.text.gray,
          marginBottom: '10px',
        },
        select: {
          width: '296px',
          position: 'absolute',
          top: 36,
          backgroundColor: '#fff',
          zIndex: 10,
          left: 0,
        },
    };
  const [toggle, setToggle] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClosePicker = () => {
    setAnchorEl(null);
  };
  /**
   * For manual input in date picker.
   */
  const [isValid, setIsValid] = useState(true);

  const validDatePattern = /^\d{4}\-\d{2}\-\d{2}$/; //eslint-disable-line

  const onInputChange = (event) => {
    if (onChange) onChange();
    const temp = event.target.value?.toString()?.trim() || '';
    setDate(temp);

    if (validDatePattern.test(temp)) {
      if (validator.isDate(temp, { format: 'YYYY-MM-DD' })) {
        if (futureSelect) {
          setIsValid(true);
        } else {
          const inputDate = new Date(temp);
          if (inputDate > new Date()) {
            setIsValid(false);
          } else {
            setIsValid(true);
          }
        }
      }
    } else if (!temp) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (validDatePattern.test(date)) {
        if (validator.isDate(date, { format: 'YYYY-MM-DD' }) && new Date(date) <= new Date()) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else if (!date) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  };

  useEffect(() => {
    if (!date) setIsValid(true);
    if (onChange) onChange();
  }, [date]); // eslint-disable-line

  useEffect(() => {
    if (isValid) {
      if (setError) setError(false);
    } else {
      if (setError) setError(true);
    }
  }, [isValid]); // eslint-disable-line

  return (
    <Box style={{opacity: disabled ? '0.7': '', backgroundColor: disabled? '#EFEFEF': '', pointerEvents: disabled? 'none': ''}}>
      <Box sx={classes.range} style={{ width, height, marginBottom }} tabIndex={1}>
        <InputBase
          sx={classes.input}
          placeholder={'YYYY-MM-DD'}
          value={date}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
        {isValid ? (
          <Icon
            name="calendar"
            size="md"
            className={classes.icon}
            onClick={(e) => {
              setToggle(!toggle);
              setAnchorEl(e.currentTarget.parentElement);
            }}
          />
        ) : (
          <Icon
            name={'error'}
            size="md"
            color="red"
            className={classes.icon}
            onClick={(e) => {
              setIsValid(true);
              setDate('');
            }}
          />
        )}

        <Popover
          open={open && !disabled}
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorReference="anchorEl"
        >
          <Calendar setDate={setDate} date={date} handleClosePicker={handleClosePicker} futureSelect={futureSelect} startYear={startYear}/>
        </Popover>
      </Box>
      {!isValid && (
        <Typography variant="caption" style={{ color: 'red', paddingLeft: '5px' }}>
          Invalid date format
        </Typography>
      )}
      {error && isValid ? (
        <Typography variant="caption" style={{ color: 'red' }}>
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};

export default CustomDatePicker;

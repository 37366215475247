import { useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import CustomPagination from "src/reusable-components/pagination";
import { useState, useMemo } from "react";
import UserResults from "./UserResults";
export default function Users({ users, showRoleData }) {
    const theme = useTheme();
    const classes = {
        label: {
            color: theme.palette.text.gray,
        }
    }
    const [sortHead, setSortHead] = useState({
        label: 'userName',
        up: true,
      });
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const totalCount = users?.length || 0;
    
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const userList = useMemo(() => {
        const results = users?.slice(limit * (page - 1), limit * page);

        if (sortHead.label === 'userName' && results) {
            return results.sort((a, b) => (a.userName < b.userName ? (sortHead.up ? -1 : 1) : sortHead.up ? 1 : -1));
        }
        return results;
    }, [sortHead, users, limit, page]);

    return (
        <Box mt={6}>
            <Typography variant="h5" style={{ color: '#242424', fontWeight: 700}}>
                Users
            </Typography>
            <Box display="flex" alignItems="end" justifyContent="space-between" pb={2} style={{marginTop: '-20px'}}>
                <Box display="flex" alignItems="end" height={64} pt={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={classes.label}>
                            <span>{totalCount} User(s) found</span>
                        </Typography>
                    </Box>
                </Box>
                <Box pt={2} display="flex" justifyContent="flex-end" alignItems="end">
                    <CustomPagination
                        onLimitChange={(e) => setLimit(e.target.value)}
                        limit={limit}
                        page={page}
                        onPageChange={handlePageChange}
                        totalNum={totalCount}
                    />
                </Box>
            </Box>
            <UserResults results={userList} sortHead={sortHead} setSortHead={setSortHead} showRoleData={showRoleData} />
        </Box>
    );
}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: false,
  transaction: false,
  roles: false,
  clients: false,
};

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    initialize(state, action) {
      state.auth = false;
      state.transaction = false;
      state.roles = false;
      state.clients = false;
    },
    getLoadingStart(state, action) {
      state[action.payload] = true;
    },
    getLoadingEnd(state, action) {
      state[action.payload] = false;
    },
  },
});

export const reducer = slice.reducer;

export const loadingInitialize = () => async (dispatch) => {
  dispatch(slice.actions.initialize());
};

export const getLoadingStart = (loadingItem) => async (dispatch) => {
  dispatch(slice.actions.getLoadingStart(loadingItem));
};

export const getLoadingEnd = (loadingItem) => async (dispatch) => {
  dispatch(slice.actions.getLoadingEnd(loadingItem));
};

export default slice;

import { Box, Typography,Pagination,PaginationItem,Select,MenuItem } from '@mui/material';
import { ReactComponent as DownChevron } from '../icons/16/Chevron/Down.svg';
const CustomPagination = ({ limit, onLimitChange, totalNum, page, onPageChange }) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography>Rows Per Page</Typography>
        <Select
          value={limit}
          size="small"
          selectprops={{ native: true }}
          style={{ width: 80, marginLeft: 20, height: 30, marginBottom: 4, borderRadius: 6 }}
          onChange={onLimitChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          IconComponent={DownChevron}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
        </Select>
      </Box>
      {Math.ceil(totalNum / limit) > 1 && (
        <Pagination
          count={Math.ceil(totalNum / limit)}
          page={page}
          sx={{
            ul: {
              "& .Mui-selected": {
                background: "#DCF4FF"
              }
            }
          }}
          onChange={onPageChange}
          size="large"
          renderItem={(item) => <PaginationItem {...item} />}
        />
      )}
    </>
  );
};

export default CustomPagination;


import { Box, Typography } from "@mui/material";
import CustomButton from "src/reusable-components/buttons/CustomButton";
import TextField from "src/reusable-components/text-field";
import DateRangePicker from "src/reusable-components/daterangepicker";
import MultiSelect from "src/reusable-components/multi-select";
import Select from "src/reusable-components/text-field/customSelect";
import { useEffect, useState } from "react";
import { getNotificationStart } from "src/slices/notification";
import {NOTIFICATION} from 'src/utils/constant'
import { useDispatch } from "react-redux";
import moment from "moment";
export default function TransactionFilter({data: filterData,fetchData,setNewFilter,setPage}) {
  const dispatch = useDispatch();
  const classes = {
    root: {
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      backgroundColor: '#FFFFFF',
      paddingLeft: '3px',
      paddingBottom: '30px',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D2D2D2',
        borderRadius: '5px',
      },
    },
    footer: {
      background: '#FFFFFFDD',
      position: 'fixed',
      bottom: '-20px',
    },
    error: {
      color: 'red',
    },
    body: {
      paddingBottom: '45px'
    }
  };
  const status = ['Success', 'Failure'];
  const [dateRangeError, setDateRangeError] = useState(false);
  const [dateRange, setDateRange] = useState(['', '']);
  const [facilitySelected, setFacility] = useState([]);
  const [facilitySearch, setFacilitySearch] = useState('');
  const [specialtySelected, setSpecialty] = useState([]);
  const [specialtySearch, setSpecialtySearch] = useState('');
  const [statusSelected, setStatus] = useState(['Success']);
  const [clientSelected, setClient] = useState([]);
  const [clientSearch, setClientSearch] = useState('');
  const [instanceSelected, setInstance] = useState([]);
  const [instanceSearch, setInstanceSearch] = useState('');
  const [transactionTypeSelected, setTransactionType] = useState([]);
  const [transactionTypeSearch, setTransactionTypeSearch] = useState('');
  const [requestIDSelected, setRequestID] = useState('');
  const [transactionSelected, setTransaction] = useState('');
  const [visitSelected, setVisit] = useState('');
  const [instanceId, setInstanceId] = useState('');

  const requestIdChange = (e) => {
      setRequestID(e.target.value);
  }

  const transactionChange = (e) => {
      setTransaction(e.target.value);
  }

  const visitChange = (e) => {
      setVisit(e.target.value);
  }

  const instanceIdChange = (e) => {
    setInstanceId(e.target.value);
  }

  useEffect(()=>{
    const startDate = moment().subtract(30 - 1, 'days').startOf('day').format('YYYY-MM-DD');
    const endDate = moment().endOf('day').format('YYYY-MM-DD');
    setDateRange([startDate, endDate]);
  },[]);
  const getCurrentData = () => {
    let temp = {};
    temp.fromDate = dateRange[0]?`${dateRange[0]} 00:00`:"";
    temp.toDate = dateRange[1]?`${dateRange[1]} 23:59`:"";
    temp.facilities = formatArray(facilitySelected);
    temp.specialities = formatArray(specialtySelected);
    temp.requestId = requestIDSelected;
    temp.transactionId = transactionSelected;
    temp.visitId = visitSelected
    temp.response = statusSelected ? statusSelected.toString() : "";
    temp.clients = formatArray(clientSelected);
    temp.instances = formatArray(instanceSelected);
    temp.transactionType = formatArray(transactionTypeSelected);
    return temp;
  };

  const checkValid = (currentFilter) => {
    if (!(currentFilter?.fromDate?.trim() && currentFilter?.toDate?.trim())) {
      dispatch(
        getNotificationStart(
          NOTIFICATION.ERROR,
          'Date Range is required.'
        )
      );
      return false;
    }
    else if(dateRangeError){
      return false;
    } 
    return true;
  }

  const handleFind = () => {
    let currentFilter = getCurrentData();
    if (checkValid(currentFilter)) {
      setNewFilter(currentFilter);
      dispatch(setPage(1));
      fetchData(currentFilter, 1);
    }
  };

  const formatArray = (result) => {
    let temp = [];
    result.map((item)=>{
      return temp.push(item.value)
    });
    return temp;
  }
  return (
    <Box sx={classes.root} tabIndex="100">
      <Box sx={classes.body}>
      <Box>
          <Box py={1}>
            <Typography variant="subtitle2">Client</Typography>
          </Box>
          <MultiSelect
            selectList={filterData.clients}
            selected={clientSelected}
            setSelect={setClient}
            search={clientSearch}
            setSearch={setClientSearch}
            placeholder="Select"
            size="small"
            width="200px"
          />
        </Box>
        <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Instance</Typography>
            </Box>
            <MultiSelect
            selectList={filterData.instances}
            selected={instanceSelected}
            setSelect={setInstance}
            search={instanceSearch}
            setSearch={setInstanceSearch}
            placeholder="Select"
            size="small"
            width="200px"
          />
        </Box>
        <Box mt={2}>
          <Box py={1}>
            <Typography variant="subtitle2">Facility</Typography>
          </Box>
          <MultiSelect
            selectList={filterData.facilities}
            selected={facilitySelected}
            setSelect={setFacility}
            search={facilitySearch}
            setSearch={setFacilitySearch}
            placeholder="Select facility"
            size="small"
            width="200px"
          />
        </Box>
        <Box mt={2}>
          <Box py={1}>
            <Typography variant="subtitle2">Specialty</Typography>
          </Box>
          <MultiSelect
            selectList={filterData.specialties}
            selected={specialtySelected}
            setSelect={setSpecialty}
            search={specialtySearch}
            setSearch={setSpecialtySearch}
            placeholder="Select Specialty"
            size="small"
            width="200px"
          />
        </Box>
        <Box mt={2}>
          <Box py={1}>
            <Typography variant="subtitle2">Instance ID/Key</Typography>
          </Box>
          <TextField
            onChange={instanceIdChange}
            value={instanceId}
            size="small"
            placeholder="Enter visit id"
            width="195px"
            autoComplete="off"
          />
        </Box>
        <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Date Range</Typography>
            </Box>
          <DateRangePicker
            setError={setDateRangeError}
            error={dateRangeError}
            dateRange={dateRange}
            setDateRange={setDateRange}
            width={100}
            timepicker={false}
          />
        </Box>
        <Box mt={2}>
          <Box py={1}>
            <Typography variant="subtitle2">Request ID</Typography>
          </Box>
          <TextField
            onChange={requestIdChange}
            value={requestIDSelected}
            size="small"
            placeholder="Enter request id"
            width="195px"
            autoComplete="off"
          />
        </Box>
        <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Transaction Type</Typography>
            </Box>
            <MultiSelect
            selectList={filterData.transactionTypes}
            selected={transactionTypeSelected}
            setSelect={setTransactionType}
            search={transactionTypeSearch}
            setSearch={setTransactionTypeSearch}
            placeholder="Select"
            size="small"
            width="195px"
          />
        </Box>
        <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Transaction ID</Typography>
            </Box>
            <TextField
              onChange={transactionChange}
              value={transactionSelected}
              size="small"
              placeholder="Enter transaction id"
              width="195px"
              autoComplete="off"
            />
        </Box>
        <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Visit ID</Typography>
            </Box>
            <TextField
              onChange={visitChange}
              value={visitSelected}
              size="small"
              placeholder="Enter visit id"
              width="195px"
              autoComplete="off"
            />
          </Box>
          <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Response</Typography>
            </Box>
            <Select
              selectList={status}
              placeholder='Select'
              setSelect={setStatus}
              selected={statusSelected}
              size="small"
              width="auto"
              height = "32px"
              helperText="Status"
              dropHeight="200px"
            />
        </Box>
      </Box>
      <Box my={3} sx={classes.footer}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton size="medium" width="200px" color="primary" text="Fetch" onClick={handleFind}/>
        </Box>
        <Box mt={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <CustomButton size="medium" color="secondary" text="Reset" />
          <CustomButton size="medium" color="secondary" text="Save" /> */}
        </Box>
      </Box>
    </Box>
  )
}
import { Button, useTheme } from "@mui/material";
import React from "react";
const CustomButton = ({
  size,
  color,
  text,
  disabled,
  loading,
  btnRef,
  style,
  loadingText,
  component,
  to,
  type,
  onClick,
  isCalendar,
  ...props
}) => {
  const theme = useTheme();
    const classes = {
        root: {
            textAlign: "center",
            letterSpacing: "0.025em",
            textTransform: "capitalize",
            width: `${props.width}`,
            position: "relative",
          },
          primary: {
            backgroundColor: theme.palette.button.blue,
            color: "#fff",
            fontFamily: "Inter",
            fontStyle: "normal",
            "&:hover": {
              boxShadow: "0px 4px 10px rgba(71, 112, 255, 0.8)",
              backgroundColor: theme.palette.button.blue,
              color: "#fff",
            },
            "&.Mui-disabled": {
              backgroundColor: theme.palette.button.blue,
              color: "#fff",
              opacity: 0.5,
              cursor: "not-allowed",
            },
          },
          secondary: {
            backgroundColor: "#fff",
            color: theme.palette.button.blue,
            fontFamily: "Inter",
            fontStyle: "normal",
            "&.Mui-disabled": {
              color: theme.palette.button.blue,
              opacity: 0.5,
            },
          },
          large: {
            padding: "8px 24px",
            borderRadius: "18px",
            height: "36px",
            fontSize: "14px",
            fontFamily: "Inter",
            lineHeight: "20px",
          },
          medium: {
            padding: "6px 24px",
            borderRadius: "16px",
            height: "32px",
            fontSize: "12px",
            fontFamily: "Inter",
            lineHeight: "20px",
          },
          small: {
            padding: "2px 16px",
            borderRadius: "12px",
            fontSize: "12px",
            fontFamily: "Inter",
            height: "24px",
          },
          uponLoader: {
            position: "absolute",
          },
    }
  return (
    <Button
      size={size}
      sx={[classes.root,classes[color],classes[size]]}
      disabled={disabled}
      onClick={onClick}
      component={component}
      to={to}
      style={{ ...style, ...{ padding: isCalendar ? "2px 14px" : "" } }}
      ref={btnRef}
      type={type}
    >
      {text}
    </Button>
  );
};

export default CustomButton;

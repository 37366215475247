import React from 'react';
import { Box } from '@mui/material';
import Icon from 'src/reusable-components/icons';

const classes = {
  loader: {
    animationName: 'mymove',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    '@keyframes mymove': {
      '100%': { transform: 'rotate(360deg)' },
    }
  },
  
};

const Loading = () => {
  return (
    <Box mx={1}>
      <Icon name="loader-one" size="lg" className={classes.loader} />
    </Box>
  );
};

export default Loading;

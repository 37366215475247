import FilteringList from "src/reusable-components/lists";
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from "react";
import React from "react";
import { changeMultiSelectFormat } from "src/helper";
import { setCurrentFilter } from "src/slices/filter";
const Filter = React.memo(({ fetchUserData, setPage }) => {

    const dispatch = useDispatch();
    const {clients} = useSelector((state) => state.filter);
    const clientsData = useMemo(() => {
        let clientsData = {};
        clientsData.clients = clients ? changeMultiSelectFormat(clients) : [];
        return clientsData;
    }, [clients]);
    const setNewFilter = (curFilter) => {
        dispatch(setCurrentFilter(curFilter, 'user'));
    };
    return (
        <FilteringList 
            type="users" 
            data= {clientsData}
            fetchData={fetchUserData}
            setPage={setPage}
            setNewFilter={setNewFilter}
        />
    )
});
export default Filter
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Tooltip, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeNavbarShow } from "src/slices/layout";
import Icon from "src/reusable-components/icons";
export default function NavBar() {
    const theme = useTheme();
    const classes = {
        fullMenu: {
            boxSizing: 'border-box',
            width: '200px',
            paddingLeft: '16px',
            paddingRight: '16px',
            textAlign: 'center',
            height: '100vh',
            background: theme.palette.background.dark,
            border: 'none',
            transition: 'width 0.3s',
            flexShrink: '0'
            },
        smallMenu: {
            boxSizing: 'border-box',
            width: '72px',
            paddingLeft: '16px',
            paddingRight: '16px',
            textAlign: 'center',
            height: '100vh',
            background: theme.palette.background.dark,
            border: 'none',
            transition: 'width 0.3s',
            flexShrink: '0'
        },
         menuItemWrapper: {
            paddingRight: '2px',
            '&:hover > div': {
                display: 'block',
            },
        },
        menuItem: {
            height: '32px',
            borderRadius: '3px',
            paddingLeft: '15px',
            textDecoration: 'none',
            '&:hover': {
                background: 'rgb(0, 0, 0, 0.1)',
            },
            },
        subwrapper: {
            position: 'absolute',
            display: 'none',
            top: 0,
            left: 160,
            paddingLeft: '40px',
            zIndex: 999,
            },
        subwrapper1: {
            position: 'absolute',
            display: 'none',
            top: 0,
            left: 30,
            paddingLeft: '40px',
            zIndex: 999,
            },
        submenu: {
            borderRadius: '5px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 1)',
            width: '200px',
            padding: '10px',
            background: 'white',
            position: 'absolute',
            left: 8,
        },
        active: {
            background: theme.palette.background.green,
        },
        menuTitle: {
            fontSize: '14px',
            marginLeft: '26px',
            transition: 'padding 0.3s',
            },
        subTitle: {
            textDecoration: 'none',
            marginBottom: '4px',
            padding: '5px 7px',
            borderRadius: '5px',
            color: 'black',
            '&:hover': {
                background: '#deebff',
                textDecoration: 'none',
            },
        },
        label:{
          fontWeight: 600
        }
    }
    const sectionsList = [
        {
            title: 'Home',
            icon: '/images/home.svg',
            activeIcon: '/images/home-active.svg',
            href: '/dashboard/home',
        },
        {
            title: 'Trasaction',
            icon: '/images/transaction.svg',
            activeIcon: '/images/transaction-active.svg',
            href: '/dashboard/transaction',
        },
        {
          title: 'Management',
          icon: '/images/general-settings.svg',
          activeIcon: '/images/general-settings-active.svg',
          href:'management',
          subsections: [
            {
              title: 'Users',
              href: '/dashboard/users',
            },
            {
              title: 'Roles',
              href: '/dashboard/roles',
            },
            {
              title: 'Clients',
              href: '/dashboard/clients',
            }
          ],
        }
  ];
  const dispatch = useDispatch();
  const { isNavbarShow } = useSelector((state) => state.layout);
  const location = useLocation();
  const handleMenu = () => {
    dispatch(changeNavbarShow());
  };
  const isActive = (href) => {
    if((location.pathname.includes(href) && href !== '/dashboard') ||
    (location.pathname === '/dashboard' && href === '/dashboard')){
      return true;
    }
    else if(href === 'management'){
     if(location.pathname.includes('users') || location.pathname.includes('roles') || location.pathname.includes('clients')){
        return true;
     }
    }
  }
    
    
    const content = (
        <Box height="100%" display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
          sx={classes.menuItem}
          onClick={() => handleMenu()}
            style={{ paddingLeft: 12 }}
            mb={4}
            mt={1}
          >
            <img src="/images/menu.svg" alt="menu" style={{ cursor: 'pointer' }} />
          </Box>
          <Box>
            {sectionsList.map((section, index) => (
              <Box position="relative" sx={classes.menuItemWrapper} key={section?.href + section?.title || index}>
                <Tooltip
                  title={section.title}
                  arrow
                  placement={section.title === 'Management' ? 'top' : 'right' }
                  disableHoverListener={isNavbarShow}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    component={RouterLink}
                    to={section.title === 'Management' ? '#' : section.href || '#'}
                    position="relative"
                    sx={classes.menuItem}
                    justifyContent="start"
                    style={{
                      paddingLeft: isNavbarShow ? 12 : 0,
                      justifyContent: isNavbarShow ? 'start' : 'center',
                      background: isActive(section.href) ? theme.palette.background.green : ''
                    }}
                  >
                  <img
                    src={isActive(section.href) ? section.activeIcon : section.icon}
                    alt={`menu-${section.title}`}
                    style={{ width: '18px', height: '18px' }}
                  />
                  <Typography
                    sx={classes.menuTitle}
                    style={{
                      color: isActive(section.href) ? '#FFFFFF' : '#6D6D6D',
                      display: isNavbarShow ? 'block' : 'none',
                    }}
                  >
                    {section.title}
                  </Typography>
                  {section.subsections && isNavbarShow && (isActive(section.href) ? <Icon name="chevron-right-active" size="small" ml={4} /> : <Icon name="chevron-right" size="small" ml={4} />)}
                </Box>
                </Tooltip>
                {section.subsections && (
                <Box sx={isNavbarShow ? classes.subwrapper : classes.subwrapper1}>
                  <Box sx={classes.submenu}>
                    {section.subsections.map((item, index) =>
                        <Box
                          key={item?.href + item?.title || index}
                          display="flex"
                          alignItems="center"
                          component={RouterLink}
                          to={item.href}
                          justifyContent="start"
                          sx={classes.subTitle}
                        >
                          <Typography>{item.title}</Typography>
                        </Box>
                    )}
                  </Box>
                </Box>
              )}
              </Box>
            ))}
          </Box>
          <Box
            display="flex"
            position="absolute"
            bottom={20}
            alignItems="center"
            sx={classes.menuItem}
            style={{ padding: 8, left: '18px' }}
          >
            <img src="/images/brandmark.svg" alt="brandmark" />
            <Box
              style={{
                color: '#6D6D6D',
                marginLeft: isNavbarShow ? 10 : 0,
              }}
            >
              <Typography
                style={{
                  fontSize: 10,
                  lineHeight: 1.5,
                  fontWeight: 500
                }}
              >
                {isNavbarShow ? <>POWERED BY <span style={classes.label}>BUDDI AI</span><br /><span>18/04/2024</span></> : ''}
              </Typography>
              <Typography
                style={{
                  fontSize: 10,
                  lineHeight: 1.5,
                }}
              >
                {/* {isNavbarShow ? clientInformation.deploymentDate || '--' : ''} */}
              </Typography>
            </Box>
          </Box>
        </Box>
  );
  return (
    <Box sx={isNavbarShow ? classes.fullMenu : classes.smallMenu} flexShrink="0">
      {content}
    </Box>
  )
}
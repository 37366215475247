import { useTheme } from "@emotion/react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography,Popover,Box } from "@mui/material";
import Icon from "src/reusable-components/icons";
import Loading from "src/components/Loading";
import { useState } from "react";
export default function Details({role}) {
    const HEADERS = [
        { id: 0, name: 'roles', label: 'Role', value: true, sort: true },
        { id: 1, name: 'permissions', label: 'Permission', value: true, sort: false },
        { id: 2, name: 'usersCout', label: 'Users', value: true, sort: false },
        { id: 3, name: 'status', label: 'Status', value: true, sort: false }
      ];
    const theme = useTheme();
    const classes = {
        root: {},
        headCell: {
            color: theme.palette.text.gray,
            padding: '0 10px 5px 0',
            fontSize: '12px',
        },
        headcell: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            flexWrap: 'nowrap',
            justifyContent: 'start',
            whiteSpace: 'nowrap',
        },
        headlabel: {
            fontSize: '12px',
            marginRight: '8px'
        },
        highlightheadlabel: {
            color: '#355EEF',
        },
        bodyCell: {
            verticalAlign: 'middle',
            padding: '9px 15px 9px 0',
            whiteSpace: 'nowrap',
        },
        blueValue: {
            color: theme.palette.text.secondary,
            marginBottom: 0,
            cursor: 'pointer',
        },
        blackValue: {
            color: theme.palette.text.primary,
            marginBottom: 0,
        },
        selected: {
            backgroundColor: 'transparent !important',
            border: `1px solid ${theme.palette.button.blue}`,
        },
        hover: {
            '&:hover': {
            backgroundColor: theme.palette.button.weak,
            },
        },
        dropDown: {
            transition: '0.4s',
        },
        dropUp: {
            transform: 'rotate(180deg)',
        },
        labelIcon: {
            borderRadius: '25px',
            height: '25px',
            width: '25px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
        },
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const [columns, setColumns] = useState(HEADERS);
    const open = Boolean(anchorEl);
    const id = open ? 'edit-columns' : undefined;
    const handleColumns = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handleColumnsClose = () => {
        setAnchorEl(null);
      };

    const StatusBox = () => {
        return <Box display="flex">
                    <Typography display="inline">{role?.status} &nbsp;</Typography>
                    {role?.status?.toLowerCase() === 'activated' && <Icon name="success" size="md"className={{marginLeft: '26px'}} />}
                    {role?.status?.toLowerCase() === 'locked' && <Icon name="locked" size="md" color="red" className={{marginLeft: '40px'}} />}
                    {role?.status?.toLowerCase() === 'deactivated' && <Icon name="reject" size="md" ml={10} color="red"/>}
                </Box>
    }
    if (!role) return <Loading />;
    return (
        <Box style={{ marginTop: 28 }}>
        <Table>
            <TableHead style={{ borderBottom: '2px solid black' }}>
            <TableRow>
                {HEADERS.map((el) => (
                <TableCell key={el.id} sx={classes.headCell}>
                    <div style={classes.headcell}>
                    <Typography sx={classes.headlabel}>{el.label}</Typography>
                    </div>
                </TableCell>
                ))}
            </TableRow>
            </TableHead>
            <TableBody>
            <TableRow hover style={{ cursor: 'pointer' }}>
                <TableCell sx={classes.bodyCell}>
                <Typography variant="body1">
                    <span style={classes.blueValue}>{role.role}</span>
                </Typography>
                </TableCell>
                <TableCell sx={classes.bodyCell}>
                <Typography variant="body1">{role.permissions}</Typography>
                </TableCell>
                <TableCell sx={classes.bodyCell}>
                <Typography variant="body1">{role.usersCount}</Typography>
                </TableCell>
                <TableCell sx={classes.bodyCell} style={{width: '0%'}}>
                <StatusBox />
                </TableCell>
            </TableRow>
            </TableBody>
        </Table>
    </Box>
    )
}
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import UserBreadcrumbs from "src/reusable-components/navigation/Breadcrumbs";
import Icon from "src/reusable-components/icons";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
const Header = () => {
    const { clients } = useSelector((state) => state.management);
    const headers = [
      {key: 'client', label: 'Name' },
      {key: 'email', label: 'Contact Email'},
      {key: 'instancesCount', label: '# Instances'},
      {key: 'facilitiesCount', label: '# Facilities'},
      {key: 'status', label: 'Status'},
    ];
    
   const data = clients;
   const isValidExport = clients?.length > 0 ? true : false;
    return (
        <Box width="100%">
          <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Grid container justifyContent="space-between">
              <Grid item>
                <UserBreadcrumbs type="clients" />
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ fontSize: '24px' }}>Client</Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Tooltip title="Download CSV" arrow placement="top">
                <Box width={24} height={24} 
                style={isValidExport ? { cursor: 'pointer' }:{ cursor: 'pointer', opacity:0.3}} 
                >
                 <CSVLink data={data} headers={headers} filename={"Clients.csv"}  onClick={event => {
                    return isValidExport;
                  }}>
                    <Icon
                      name='file-csv'
                      size="lg"
                    />
                  </CSVLink>
                </Box>
            </Tooltip>
            </Box>
            
          </Box>
        </Box>
    );
}
export default Header;
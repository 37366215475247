
import { Box, Typography } from "@mui/material";
import CustomButton from "src/reusable-components/buttons/CustomButton";
import TextField from "src/reusable-components/text-field";
import MultiSelect from "src/reusable-components/multi-select";
import { useState } from "react";
import { GroupCheckBox } from "src/reusable-components/checkboxes";
export default function RolesFilter({data: filterData, setNewFilter}) {
  const classes = {
    root: {
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      backgroundColor: '#FFFFFF',
      paddingLeft: '3px',
      paddingBottom: '30px',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D2D2D2',
        borderRadius: '5px',
      },
    },
    footer: {
      background: '#FFFFFFDD',
      position: 'fixed',
      bottom: '-20px',
    },
    error: {
      color: 'red',
    },
    body: {
      paddingBottom: '45px'
    }
  };
  const [role,setRole] = useState("");
  const [permissionSelected, setPermission] = useState([]);
  const [permissionSearch, setPermissionSearch] = useState('');
  const [status, setStatus] = useState([]);

  const roleChange = (e) => {
    setRole(e.target.value);
  }

  const onFind = () => {
    setNewFilter({
      role: role,
      status: status,
      permissions: permissionSelected.map((el) => el.value),
      sort: 'asc',
      orderBy: 'role',
    });
  };

  return (
    <Box sx={classes.root} tabIndex="100">
      <Box sx={classes.body}>
      <Box>
          <Box py={1}>
            <Typography variant="subtitle2">Role</Typography>
          </Box>
          <TextField
              onChange={roleChange}
              value={role}
              size="small"
              placeholder="Enter role name"
              width="195px"
              autoComplete="off"
            />
        </Box>
        <Box mt={2}>
          <GroupCheckBox
            title="Status"
            labelGroup={['Activated', 'Deactivated']}
            selectedLabels={status}
            setSelectedLabels={setStatus}
          />
        </Box>
        <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Permission</Typography>
            </Box>
            <MultiSelect
            selectList={filterData.permissions}
            selected={permissionSelected}
            setSelect={setPermission}
            search={permissionSearch}
            setSearch={setPermissionSearch}
            placeholder="Select"
            size="small"
            width="200px"
          />
        </Box>
      </Box>
      <Box my={3} sx={classes.footer}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton size="medium" width="200px" color="primary" text="Fetch" onClick={onFind}/>
        </Box>
        <Box mt={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
        </Box>
      </Box>
    </Box>
  )
}
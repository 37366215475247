import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const RadioIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  }));
  
  const RadioCheckedIcon = styled(RadioIcon)({
    backgroundColor: '#355EEF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    }
  });
  
  function StyledRadio(props) {
    const classes = {
        root: {
            '&:hover': {
            backgroundColor: 'transparent',
            },
            '&.MuiRadio-root': {
            padding: props?.nomargin ? '3px' : '3px 8px 3px 3px',
            margin: props?.nomargin ? '0px 0px 0px 10px' : '0px 0px 0px 20px',
            },
        }
    }
    return (
      <Radio
        sx={classes.root}
        disableRipple
        color="default"
        checkedIcon={<RadioCheckedIcon />}
        icon={<RadioIcon />}
        {...props}
      />
    );
  }

export default function CustomizedRadios(props) {
    const classes = {
        root: {
            '&:hover': {
            backgroundColor: 'transparent',
            },
            '&.MuiRadio-root': {
            padding: props?.nomargin ? '3px' : '3px 8px 3px 3px',
            margin: props?.nomargin ? '0px 0px 0px 10px' : '0px 0px 0px 20px',
            },
        },
        groupLabel: {
            fontSize: '14px',
            fontWeight: 700,
            color: '#242424',
            lineHeight: '22px',
            fontFamily: 'Inter',
        },
        icon: {
            borderRadius: '50%',
            width: '16px',
            height: '16px',
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        },
        checkedIcon: {
            backgroundColor: '#355EEF',
            '&::before': {
            display: 'block',
            width: '16px',
            height: '16px',
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
            },
        },
        groupContainer:{
            marginLeft: `${props.ml}px`,
            marginTop: `${props.mt}px`,
        },
        selectContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '22px',
            color: '#242424',
            marginBottom: '14px',
        },
        radioWrapper:{
            display: props.row ? 'block' : 'flex',
            gridGap: '12px',
        },
    };

  const { groupLabel, value, onChange, direction, radioNodes } = props;

  return (
    <FormControl component="fieldset">
      <div component="legend" style={classes.groupLabel}>
        {groupLabel}
      </div>
      <RadioGroup
        value={value}
        onChange={onChange}
        aria-label="gender"
        row={direction === 'row' ? true : false}
        name="customized-radios"
        sx={classes.radioWrapper}
      >
        {radioNodes.map((node, index) => {
          return (
            <div key={index}>
              <FormControlLabel
                value={node.label}
                control={<StyledRadio />}
                label={node.label}
                disabled={node.disabled}
              />
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

export const CustomRadioGroup = React.forwardRef((props, forwardRef) => {
  const { title, labels, setSelectedLabel, selectedLabel, direction, error } = props;
  const classes = {
    root: {
        '&:hover': {
        backgroundColor: 'transparent',
        },
        '&.MuiRadio-root': {
        padding: props?.nomargin ? '3px' : '3px 8px 3px 3px',
        margin: props?.nomargin ? '0px 0px 0px 10px' : '0px 0px 0px 20px',
        },
    },
    groupLabel: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#242424',
        lineHeight: '22px',
        fontFamily: 'Inter',
    },
    icon: {
        borderRadius: '50%',
        width: '16px',
        height: '16px',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    },
    checkedIcon: {
        backgroundColor: '#355EEF',
        '&:before': {
        display: 'block',
        width: '16px',
        height: '16px',
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
        },
    },
    groupContainer:{
        marginLeft: `${props.ml}px`,
        marginTop: `${props.mt}px`,
    },
    selectContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#242424',
        marginBottom: '14px',
    },
    radioWrapper:{
        display: props.row ? 'block' : 'flex',
        gridGap: '12px',
    },
};

  const handleChange = (e) => {
    if (e.target.checked) {
      setSelectedLabel(e.target.value);
    } else {
      setSelectedLabel('');
    }
  };

  return (
    <Box sx={classes.groupContainer} ref={forwardRef}>
      {title && <Typography sx={classes.title}>{title}</Typography>}
      <Box sx={classes.selectContainer}>
        <RadioGroup value={selectedLabel} row={direction === 'row' ? true : false}>
          {labels.map((label, index) => {
            return (
              <FormControlLabel
                key={index}
                sx={{textTransform: 'capitalize'}}
                control={<StyledRadio />}
                label={label}
                onChange={(e) => handleChange(e)}
                value={label}
              />
            );
          })}
        </RadioGroup>
      </Box>
      {error && (
        <Typography variant="caption" style={{ color: 'red' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
});

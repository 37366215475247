import { useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import CustomPagination from "src/reusable-components/pagination";
import Result from "./Result";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useState } from "react";
import Loading from "src/components/Loading";
import { getRolesList } from "src/slices/management";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
export default function Details() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = {
        label: {
            color: theme.palette.text.gray,
        }
    }
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sortHead, setSortHead] = useState({
        label: 'role',
        up: true,
    });
    const rolesLoading = useSelector((state) => state.loading.roles);
    const {roles} = useSelector((state)=>state.management);
    const { currentRoleFilter } = useSelector((state) => state.filter);
    const [results, setResults] = useState([]);
    const totalCount = roles?.length || 0;

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };

    useEffect(() => {
        dispatch(
            getRolesList({
            role: '',
            permissions: [],
            status: [],
            sort: 'asc',
            orderBy: 'role',
            })
        );
    }, []); // eslint-disable-line

    useEffect(() => {
        !isEmpty(currentRoleFilter) && dispatch(getRolesList(currentRoleFilter));
    }, [currentRoleFilter]); // eslint-disable-line

    useEffect(() => {
        const paginatedResults = roles ? roles.slice(limit * (page - 1), limit * page): [];
        setResults([...paginatedResults].sort((a, b) => a.role.toLowerCase() > b.role.toLowerCase() ? 1 : -1));
      }, [limit, page, roles]); // eslint-disable-line
    
      useEffect(() => {
        setPage(1);
      }, [totalCount]);
    
    return (
        <Box maxWidth="100%">
            <Box display="flex" alignItems="end" justifyContent="space-between" pb={2}>
                <Box display="flex" alignItems="end" height={64} pt={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={classes.label}>
                            <span>{totalCount} Role(s) found</span>
                        </Typography>
                        {rolesLoading && <Loading />}
                    </Box>
                </Box>
                <Box pt={2} display="flex" justifyContent="flex-end" alignItems="end">
                    <CustomPagination
                        onLimitChange={(e) => {
                            setLimit(e.target.value);
                            setPage(1);
                          }}
                          limit={limit}
                          page={page}
                          onPageChange={handlePageChange}
                          totalNum={totalCount}
                    />
                </Box>
            </Box>
            <Box pb={5} style={{ overflowX: 'auto', marginBottom: '15px' }} width={'100%'} height={window.innerHeight - 225 + 'px'}>
                <Result 
                results={results}
                sortHead={sortHead}
                setSortHead={setSortHead}
                setResults={setResults}
                />
            </Box>
        </Box>
    );
}
import { Box, Modal, Typography, Grid } from '@mui/material';
import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from 'src/reusable-components/buttons/CustomButton';
import Icon from 'src/reusable-components/icons';
import _ from 'lodash';
import CustomDatePicker from 'src/reusable-components/datepicker';
import TextField from 'src/reusable-components/text-field';
import Select from "src/reusable-components/text-field/customSelect";
import { STATUS_PAST_V2 } from 'src/utils/constant';
import { setClientUpdatedStatus, updateInstance } from 'src/slices/management';
import moment from 'moment';
import { addNewInstance } from 'src/slices/management';

const classes = {
  paper: {
    position: 'absolute',
    width: '686px',
    maxWidth: 'calc(100% - 64px)',
    backgroundColor: '#fff',
    borderRadius: '5px',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    overflowY: 'auto',
    outline: 'none'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  header: {
    height: '40px',
    backgroundColor: '#e5e5e5',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
  centerBox:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  }
};

const InstanceModal = (props) => {
  const dispatch = useDispatch();
  const { type, onCancel, instanceData } = props;
  const [open, setOpen] = useState(false);
  const { clients } = useSelector((state) => state.filter);
  const [instanceName, setInstanceName] = useState('');
  const [loading, setLoading] = useState(false);
  const authenticationModes = ['PASSIVE'];
  const [authMode, setAuthMode] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const validationGroups = ['MCME'];
  const [validation, setValidation] = useState('');
  const [expiry, setExpiry] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [client, setClient] = useState('');
  const [error, setError] = useState({});
  const isEdit = type === 'Edit' ? true : false;
  const handleValidation = (data) => {
    const temp = {};
    let valid = true;
    if(type === 'Add'){
      if (!data.instanceName) {
        temp.instanceName = 'This field is required.';
        valid = false;
      }
      if (authMode?.length === 0) {
        temp.authenticationMode = 'This field is required.';
        valid = false;
      }
      if (validation?.length === 0) {
        temp.validatorGroup = 'This field is required.';
        valid = false;
      }
      if (client?.length === 0) {
        temp.client = 'This field is required.';
        valid = false;
      }
    }
    
    if (statusSelected?.length === 0) {
      temp.status = 'This field is required.';
      valid = false;
    }
    
    if (expiry?.length === 0) {
      temp.expiry = 'This field is required.';
      setExpiryError('This field is required.');
      valid = false;
    }
    setError({ ...temp });
    return valid;
  }

  const handleApply = async () => {
    dispatch(setClientUpdatedStatus(false));
    const data = {};
    data.validTo = moment(expiry).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    data.status = statusSelected !== "" ? statusSelected.toLowerCase() : statusSelected;
    if(type === 'Add'){
        data.instanceName = instanceName;
        data.validatorGroup = validation !== "" ? validation : validation;
        data.client = client !== "" ? client.toLowerCase() : client;
        data.authenticationMode = authMode !== "" ? authMode : authMode;
    }
    if(handleValidation(data)){
      setLoading(true);
      if(type === 'Add'){
        await dispatch(addNewInstance(data)); 
      }
      else{
        data.instanceId = instanceData?.id;
        await dispatch(updateInstance(data)); 
      }
      
      setLoading(false);
      onCancel();
    }
  };

  const handleErrorChange = (type) => {
    let errTemp = _.cloneDeep(error);
    switch(type){
      case 'auth':{
        errTemp.authenticationMode = '';
        break;
      }
      case 'status': {
        errTemp.status = '';
        break;
      }
      case 'validator': {
        errTemp.validatorGroup = '';
        break;
      }
      case 'client': {
        errTemp.client = '';
        break;
      }
      case 'expiry': {
        errTemp.expiry = '';
        setExpiryError('');
        break;
      }
      default:
    }
    setError({ ...errTemp });
  }

  const init = () => {
    if(type === 'Edit'){
      setInstanceName(instanceData?.instanceId);
      setExpiry(instanceData.expiryOn ? moment(instanceData.expiryOn).format('YYYY-MM-DD') : '');
      setAuthMode(instanceData?.authenticateMode.toUpperCase());
      setStatusSelected(instanceData?.status);
      setValidation('MCME');
      setClient(instanceData?.client);
    }
    else{
      setInstanceName('');
      setExpiry('');
      setAuthMode('');
      setStatusSelected('');
      setValidation('');
      setClient('');
    }
    setError({});
  }
  useEffect(()=>{
    setOpen(type !== '');
    init();
  },[type]); // eslint-disable-line
  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={classes.paper}>
        <Box sx={classes.header}>
          <Typography sx={classes.label}>{`${type} Instance`}</Typography>
          <Icon name="cancel" size="sm" className={classes.closeIcon} onClick={onCancel} />
        </Box>
        <Box px={5} pt={3} sx={{flexGrow: 1}}>
            <Grid container spacing={3}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Instance Name:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                          let errTemp = _.cloneDeep(error);
                          errTemp.instanceName = '';
                          setError({ ...errTemp });
                        }}
                        value={instanceName}
                        size="medium"
                        placeholder=""
                        width="100%"
                        autoComplete="off"
                        disabled={isEdit}
                        />
                        <Typography variant="caption" color="red">
                          {error.instanceName}
                        </Typography>
                </Grid>
                {/* <Grid item xs={2} sx={classes.centerBox}>
                    <CustomButton text="Generate" color="primary" size="small" />
                </Grid>
                <Grid item xs={2} sx={classes.centerBox}>
                    <CustomButton text="Verify" color="primary" size="small" />
                </Grid> */}
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Client:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Select
                       selectList={clients}
                        placeholder='Select'
                        setSelect={setClient}
                        selected={client}
                        width="auto"
                        height = "36px"
                        helperText="Status"
                        dropHeight="200px"
                        error={error.client}
                        onChange={() => handleErrorChange('client')}
                        disabled={isEdit}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Expiry On:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <CustomDatePicker date={expiry} setDate={setExpiry} error={expiryError} setError={setExpiryError} onChange={() => handleErrorChange('expiry')} futureSelect='true' width='100%' height='36px'/>
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Authentication Mode:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Select
                        selectList={authenticationModes}
                        placeholder='Select'
                        setSelect={setAuthMode}
                        selected={authMode}
                        width="auto"
                        height = "36px"
                        helperText="Status"
                        dropHeight="200px"
                        error={error.authenticationMode}
                        onChange={() => handleErrorChange('auth')}
                        disabled={isEdit}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Status:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Select
                        selectList={STATUS_PAST_V2}
                        placeholder='Select'
                        setSelect={setStatusSelected}
                        selected={statusSelected}
                        width="auto"
                        height = "36px"
                        helperText="Status"
                        dropHeight="200px"
                        error={error.status}
                        onChange={() => handleErrorChange('status')}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Validation Group:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Select
                        selectList={validationGroups}
                        placeholder='Select'
                        setSelect={setValidation}
                        selected={validation}
                        width="auto"
                        height = "36px"
                        helperText="Status"
                        dropHeight="200px"
                        error={error.validatorGroup}
                        onChange={() => handleErrorChange('validator')}
                        disabled={isEdit}
                    />
                </Grid>
            </Grid>
          <Box pt={7} pb={2} display="flex" justifyContent="flex-end" alignItems="center">
            <CustomButton text="Cancel" color="secondary" size="large" onClick={() => onCancel()} />
            <CustomButton text="Save" color="primary" size="large" disabled={loading} onClick={handleApply} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default InstanceModal;

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { getBreadCrumbData } from 'src/utils/data';
import { useTheme } from '@mui/material';

const UserBreadcrumbs = ({ type, param, refNum, ...props }) => {
    const theme = useTheme();
    const classes = {
        label: {
            color: theme.palette.text.neutral,
            '&:hover': {
              color: '#355EEF',
              textDecoration: 'none',
            },
          }
    }
  const homeData = [
    {
      name: 'Home',
      link: '/dashboard',
    },
  ];
  const breadcrumbData = [...homeData, ...getBreadCrumbData(type, param, refNum)];
  return (
    <Breadcrumbs separator={<div style={{ color: 'black' }}>•</div>} aria-label="breadcrumb">
      {breadcrumbData?.map((item, key) => {
        if (breadcrumbData.length > key + 1) {
          return item?.name === 'Home' ? (
            <Typography variant="caption" color="textPrimary" key={item.name}>
              {item.name}
            </Typography>
          ) : (
            <Link variant="caption" to={item.link} component={RouterLink} sx={classes.label} style={{textDecoration: 'none'}} key={item.link}>
              {item.name}
            </Link>
          );
        } else {
          return (
            <Typography variant="caption" color="textPrimary" key={item.name} sx={{color: theme.palette.text.default}}>
              {item.name}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
};

export default UserBreadcrumbs;

import FilteringList from "src/reusable-components/lists";
import React from "react";
import { useMemo } from "react";
import { changeMultiSelectFormat } from "src/helper";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentFilter } from "src/slices/filter";
const Filter = React.memo(() => {
    const dispatch = useDispatch();
    const {
        facilities,
        clients,
        instances
    } = useSelector((state) => state.filter);
    const clientData = useMemo(() => {
        let clientData = {};
        clientData.facilities = facilities ? changeMultiSelectFormat(facilities) : [];
        clientData.clients = clients ? changeMultiSelectFormat(clients) : [];
        clientData.instances = instances ? changeMultiSelectFormat(instances) : [];
        return clientData;
    }, [facilities, clients, instances]);

    const setNewFilter = (curFilter) => {
        dispatch(setCurrentFilter(curFilter, 'client'));
    };

    return (
        <FilteringList 
            type="clients" 
            data={clientData}
            setNewFilter={setNewFilter}
        />
    )
});
export default Filter
export const setMarginTop = (props, defaultValue) => {
    if (props.mt) {
      return props.mt + 'px';
    }
    if (props.my) {
      return props.my + 'px';
    }
    if (props.m) {
      return props.m + 'px';
    }
    return defaultValue;
  };
  
  export const setMarginRight = (props, defaultValue) => {
    if (props.mr) {
      return props.mr + 'px';
    }
    if (props.mx) {
      return props.mx + 'px';
    }
    if (props.m) {
      return props.m + 'px';
    }
    return defaultValue;
  };
  
  export const setMarginBottom = (props, defaultValue) => {
    if (props.mb) {
      return props.mb + 'px';
    }
    if (props.my) {
      return props.my + 'px';
    }
    if (props.m) {
      return props.m + 'px';
    }
    return defaultValue;
  };
  
  export const setMarginLeft = (props, defaultValue) => {
    if (props.ml) {
      return props.ml + 'px';
    }
    if (props.mx) {
      return props.mx + 'px';
    }
    if (props.m) {
      return props.m + 'px';
    }
    return defaultValue;
  };
  
  export const setPaddingTop = (props, defaultValue) => {
    if (props.pt) {
      return props.pt + 'px';
    }
    if (props.py) {
      return props.py + 'px';
    }
    if (props.p) {
      return props.p + 'px';
    }
    return defaultValue;
  };
  
  export const setPaddingRight = (props, defaultValue) => {
    if (props.pr) {
      return props.pr + 'px';
    }
    if (props.px) {
      return props.px + 'px';
    }
    if (props.p) {
      return props.p + 'px';
    }
    return defaultValue;
  };
  
  export const setPaddingBottom = (props, defaultValue) => {
    if (props.pb) {
      return props.pb + 'px';
    }
    if (props.py) {
      return props.py + 'px';
    }
    if (props.p) {
      return props.p + 'px';
    }
    return defaultValue;
  };
  
  export const setPaddingLeft = (props, defaultValue) => {
    if (props.pl) {
      return props.pl + 'px';
    }
    if (props.px) {
      return props.px + 'px';
    }
    if (props.p) {
      return props.p + 'px';
    }
    return defaultValue;
  };
  
  export const stopPropagationKeyDown = (e) => {
    if (
      e.key === 'Enter' ||
      e.keyCode === 13 ||
      e.key === ' ' ||
      e.keyCode === 32 ||
      e.key === 'ArrowDown' ||
      e.keyCode === 40 ||
      e.key === 'ArrowUp' ||
      e.keyCode === 38
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  
import {Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import React, { useState } from 'react';
import CustomButton from "src/reusable-components/buttons/CustomButton";
import { useSelector,useDispatch } from 'react-redux';
import {useTheme} from '@mui/material';
import Result from './Result';
import InstanceModal from './InstanceModal';
import FacilityModal from './FacilityModal';
  
const Details = ({ clientDetail, clientId }) => {
    const theme = useTheme();
    const classes = {
        root: {},
        headCell: {
          color: theme.palette.text.gray,
          padding: '0 10px 5px 10px',
          fontSize: 12,
          whiteSpace: 'nowrap',
        },
        headlabel: {
          fontSize: 12,
          marginRight: 8,
        },
        bodyCell: {
          verticalAlign: 'baseline',
          padding: '8px 10px',
          whiteSpace: 'nowrap',
          '& p': {
            maxWidth: '300px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }
        },
        newButton: {
          background: theme.palette.text.secondary,
          color: 'white',
          height: 24,
          borderRadius: 36,
          textTransform: 'capitalize',
          '&:hover': {
            background: theme.palette.text.secondary,
            opacity: '0.9',
          },
        },
    };
    const dispatch = useDispatch();
    const [instanceModal, setInstanceModal] = useState('');
    const [instanceData, setInstanceData] = useState({});
    const [facilityModal, setFacilityModal] = useState('');
    const [facilityData, setFacilityData] = useState({});

    const openInstance = (data) => {
      setInstanceModal('Edit');
      setInstanceData(data);
    }

    const openFacility = (data) => {
      setFacilityModal('Edit');
      setFacilityData(data);
    }
    return (
      <>
        <Box mb={5}>
          <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Instances</Typography>
            {/* {(user?.permissions?.includes('Admin') || user?.permissions?.includes("Add/Update Facility")) && ( */}
            <CustomButton
              size="small"
              color="primary"
              text="Add New" 
              onClick={() => {
                setInstanceModal('Add');
              }}           
            />
             {/* )} */}
          </Box>
            <Box pb={5} style={{ overflowX: 'auto', paddingBottom: '20px' }} width={'100%'} maxHeight='210px'>
                <Result type='instance' clientDetail={clientDetail} openInstance={openInstance} />
            </Box>
        </Box>
        <Box mb={4}>
            <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Facilities</Typography>
                {/* {(user?.permissions?.includes('Admin') || user?.permissions?.includes("Add/Update Facility")) && ( */}
                <CustomButton
                    size="small"
                    color="primary"
                    text="Add New"  
                    onClick={() => {
                      setFacilityModal('Add');
                    }}     
                />
                {/* )} */}
            </Box>
            <Box pb={5} mt={3} style={{ overflowX: 'auto', paddingBottom: '20px' }} width={'100%'} maxHeight='210px'>
                <Result type='facility' clientDetail={clientDetail} openFacility={openFacility} />
            </Box>
        </Box>

        <InstanceModal
          type={instanceModal}
          onCancel={() => setInstanceModal('')}
          instanceData={instanceData}
          
        />
        <FacilityModal
          type={facilityModal}
          onCancel={() => setFacilityModal('')}
          facilityData={facilityData}
        />
      </>
    );
  };
  
  export default Details;
  
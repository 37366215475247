import { Box, useTheme, Typography } from "@mui/material";
import { useState,useEffect } from "react";
import CustomStatus from "./CustomStatus";
import React from "react";
import CustomTextField from "./CustomTextField";
const TextField = React.forwardRef((props, forwardRef) => {
  const theme = useTheme();
  const [type, setType] = useState(props.type);
  const [isFocused, setIsFocused] = useState(false);
    const {
        isCurrency,
        inputProps,
        onChange,
        error,
        placeholder = 'Input',
        disabled,
        readOnly,
        onKeyUp,
        onKeyDown,
        name,
        onBlur,
        onFocus,
        maxLength,
        autoFocus,
        label,
        onWheel
  } = props;
  const value = props.value === 0 ? props.value : props.value || '';

  useEffect(() => {
    if (isCurrency && !isFocused) {
      setType('text');
    } else if (isCurrency && isFocused) {
      setType('number');
    } else {
      setType(props.type || 'text');
    }
  }, [isCurrency, isFocused, props.type]);
    const classes = {
        root: {
            width: props.width ? props.width : '200px',
          },
        label: {
            height: props.size === 'small' ? '32px' : '36px',
            fontSize: props.fontSize ? props.fontSize : '14px',
            lineHeight: '22px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            color: theme.palette.text.gray,
            fontWeight: '500',
            whiteSpace: 'nowrap',
          },
        customInput: {
            width: '100%',
            padding: props.size === 'small' ? '6px' : '8px',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            fontFamily: 'Inter',
            height: props.size === 'small' ? '32px' : '36px',
            borderRadius: '3px',
            border: 'solid 1px',
            borderColor: '#D6D6D6'
          }
  }
    return (
      <Box sx={classes.root}>
      {props.label && <Typography sx={classes.label}>{props.label}</Typography>}
      <input
          style={classes.customInput}
          className="customInput"
          onBlur={(e) => {
          onBlur && onBlur(e);
          setIsFocused(false);
        }}
        onFocus={(e) => {
          onFocus && onFocus(e);
          setIsFocused(true);
        }}
        value={isFocused ? value : isCurrency && value !== '' && value !== undefined && label !== '# of Claims' ? Number(value).toFixed(2) : value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        type={type}
        readOnly={readOnly}
        name={name}
        maxLength={maxLength}
        ref={forwardRef}
        autoFocus={autoFocus}
        onWheel={onWheel}
        {...inputProps}
      />
      {error ? (
        <Typography variant="caption" style={{ color: 'red' }}>
          {error}
        </Typography>
      ) : null}
    </Box>
    )
});
export const Status = (props) => {
  const classes = {
    root: {
        width: props.width ? props.width : '200px',
      }
    };
  return (
    <Box sx={classes.root}>
      <CustomStatus
        placeholder={props.placeholder || 'SELECT'}
        selected={props.selected}
        setSelect={props.setSelect}
        label={props.label}
        type={props.type}
        disable={props.disable || false}
      />
    </Box>
  );
};
export const TextArea = (props) => {
  const theme = useTheme();
  const classes = {
    root: {
      width: props.width ? props.width : '200px',
    },
    label: {
      height: props.size === 'small' ? '32px' : '36px',
      fontSize: props.fontSize ? props.fontSize : '14px',
      lineHeight: '22px',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      color: theme.palette.text.gray,
      fontWeight: '500',
      whiteSpace: 'nowrap',
    }
  };
  return (
    <Box sx={classes.root}>
      {props.label && <Typography sx={classes.label}>{props.label}</Typography>}
      <CustomTextField
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        size={props.size}
        helperText={props.helperText}
        placeholder={props.placeholder}
        disabled={props.disabled}
        rows={props.rows}
        m={props.m}
        mx={props.mx}
        my={props.my}
        mt={props.mt}
        mr={props.mr}
        mb={props.mb}
        ml={props.ml}
        p={props.p}
        px={props.px}
        py={props.py}
        pt={props.pt}
        pr={props.pr}
        pb={props.pb}
        pl={props.pl}
      />
    </Box>
  );
};
export default TextField
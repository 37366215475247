import React from 'react';
import { Box, Typography } from '@mui/material';
import Icon from '../icons';

const classes = {
  error: {
    backgroundColor: 'rgba(248, 79, 79, 0.9)',
    color: 'white',
    borderRadius: '3px',
    justifyContent: 'space-between',
  },
  warning: {
    backgroundColor: 'rgba(230, 164, 37, 0.9)',
    color: 'white',
    borderRadius: '3px',
    justifyContent: 'space-between',
  },
  success: {
    backgroundColor: 'rgba(70, 204, 46)',
    color: 'white',
    borderRadius: '3px',
    justifyContent: 'space-between',
  },
  information: {
    backgroundColor: '#F3F4F4',
    color: '#6D6D6D',
    borderRadius: '3px',
    justifyContent: 'space-between',
  },
};

const colors = {
  error: 'white',
  warning: 'white',
  success: 'white',
  information: '#6D6D6D',
};

const iconNames = {
  error: 'error',
  warning: 'report-problem',
  success: 'confirmation',
  information: 'bell',
};

const Alerts = ({ px, type, style, iconMr, message, cancelIgnore, onCancel }) => {
  return (
    <Box px={px || 2} py={1.2} display="flex" sx={classes[type]} style={style}>
      <Box display="flex" alignItems="center">
        <Icon name={iconNames[type]} size="md" color={colors[type]} mr={iconMr || 12} />
        <Typography variant="body1">{message}</Typography>
      </Box>
      {!cancelIgnore && <Icon name="cancel" size="md" color={colors[type]} onClick={onCancel} />}
    </Box>
  );
};

export default Alerts;


import { Box, Typography } from "@mui/material";
import CustomButton from "src/reusable-components/buttons/CustomButton";
import Select from "src/reusable-components/text-field/customSelect";
import MultiSelect from "src/reusable-components/multi-select";
import { useState } from "react";
import { STATUS_PAST } from "src/utils/constant";
import { changeMultiSelectFormat } from "src/helper";
export default function ClientsFilter({data: filterData,setNewFilter}) {
  const classes = {
    root: {
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      backgroundColor: '#FFFFFF',
      paddingLeft: '3px',
      paddingBottom: '30px',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D2D2D2',
        borderRadius: '5px',
      },
    },
    footer: {
      background: '#FFFFFFDD',
      position: 'fixed',
      bottom: '-20px',
    },
    error: {
      color: 'red',
    },
    body: {
      paddingBottom: '45px'
    }
  };
  const [clientSelected, setClient] = useState([]);
  const [clientSearch, setClientSearch] = useState('');
  const [instanceSelected, setInstance] = useState([]);
  const [instanceSearch, setInstanceSearch] = useState('');
  const [facilitySelected, setFacility] = useState([]);
  const [facilitySearch, setFacilitySearch] = useState('');
  const status = changeMultiSelectFormat(STATUS_PAST)
  const [clientStatus, setClientStatus] = useState([]);
  const [statusSearch, setStatusSearch] = useState('');

  const onFind = () => {
    setNewFilter({
      client:  clientSelected.map((el) => el.value),
      instances:  instanceSelected.map((el) => el.value),
      facility:  facilitySelected.map((el) => el.value),
      clientStatus:  clientStatus.map((el) => el.value),
      startRow: 1,
      orderBy: 'status'
    });
  };

  return (
    <Box sx={classes.root} tabIndex="100">
      <Box sx={classes.body}>
      <Box>
          <Box py={1}>
            <Typography variant="subtitle2">Client</Typography>
          </Box>
          <MultiSelect
            selectList={filterData.clients}
            selected={clientSelected}
            setSelect={setClient}
            search={clientSearch}
            setSearch={setClientSearch}
            placeholder="Select"
            size="small"
            width="200px"
          />
        </Box>
        <Box mt={2}>
          <Box py={1}>
                <Typography variant="subtitle2">Instance</Typography>
              </Box>
              <MultiSelect
              selectList={filterData.instances}
              selected={instanceSelected}
              setSelect={setInstance}
              search={instanceSearch}
              setSearch={setInstanceSearch}
              placeholder="Select"
              size="small"
              width="200px"
            />
        </Box>
        <Box mt={2}>
          <Box py={1}>
            <Typography variant="subtitle2">Facility</Typography>
          </Box>
          <MultiSelect
            selectList={filterData.facilities}
            selected={facilitySelected}
            setSelect={setFacility}
            search={facilitySearch}
            setSearch={setFacilitySearch}
            placeholder="Select facility"
            size="small"
            width="200px"
          />
        </Box>
        <Box mt={2}>
            <Box py={1}>
              <Typography variant="subtitle2">Client Status</Typography>
            </Box>
            <MultiSelect
              selectList={status}
              selected={clientStatus}
              setSelect={setClientStatus}
              search={statusSearch}
              setSearch={setStatusSearch}
              placeholder="Select"
              size="small"
              width="200px"
            />
          </Box>
      </Box>
      <Box my={3} sx={classes.footer}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton size="medium" width="200px" color="primary" text="Fetch" onClick={onFind}/>
        </Box>
        <Box mt={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
        </Box>
      </Box>
    </Box>
  )
}
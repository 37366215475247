import Select,{
  components,
  DropdownIndicatorProps
}from 'react-select';
import Icon from '../icons';
import { Box, Typography } from '@mui/material';
import React from 'react';
const ReactSelect = (props) => {
  const {
    placeholder,
    name,
    options,
    changeOption,
    value,
    width,
    height = '32px',
    dropdownHeight,
    isDisabled,
    error,
    innerRef,
    isLogin,
    includeNull=false
  } = props;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      minHeight: "32px",
    }),
    control: (base, state) => ({
      ...base,
      height: height,
      width: width ? width: '200px',
      minHeight: '32px',
      borderRadius: isLogin ? '4px' : '3px',
      boxShadow: 'none',
      borderWidth: state.isFocused && isLogin ? "2px" : '1px',
      borderColor: error ? 'red' :state.isFocused ? isLogin ? "black" : '#0000FF' : isLogin ? "#878787" : "#D6D6D6",
      "&:hover": {
        backgroundColor: "#F3F4F4",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: height,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      color: '#777',
    }),
    singleValue: (base) => ({
      ...base,
      color: isDisabled ? '#707070' :'#000',
      fontSize: isLogin ? 12 : 14,
      fontWeight: isLogin ? 400 : 500,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    menu: (base) => ({
      ...base,
      width: width ? width: '200px',
      height: dropdownHeight,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: isLogin ? 12 : 14,
      height: dropdownHeight,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: isLogin ? 12 : 14,
      fontFamily: isLogin ? '' : 'Inter',
      fontWeight: isLogin ? 400 : 500,
      color: '#A4A4A4',
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };
  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        {/* <CaretDownIcon /> */}
        <Icon name="chevron-down" size="sm" />
      </components.DropdownIndicator>
    );
  };
  return (
    <Box>
      <Select
        placeholder={placeholder || 'Select'}
        options={includeNull ? [{}, ...options] : options}
        styles={customStyles}
        onChange={changeOption}
        name={name}
        components={{ DropdownIndicator }}
        value={value}
        isDisabled={isDisabled}
        ref={innerRef}
        menuPortalTarget={document.body}
      />
      {error ? (
        <Typography variant="caption" style={{ color: 'red' }}>
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};

export default ReactSelect;

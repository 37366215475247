import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Tool from "./Tool";
const filteredPaths = [
  '/dashboard/transaction',
  '/dashboard/users',
  '/dashboard/roles',
  '/dashboard/clients',
];
const Header = () =>{
    const theme = useTheme();
    const classes = {
        root: {
            position: 'fixed',
            boxSizing: 'border-box',
            paddingLeft: '48px',
            borderBottom: '1px solid gray',
            background: 'white',
            width: 'calc(100% - 72px)',
            zIndex: 100,
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
            },
        logo: {
            display: 'flex',
            alignItems: 'center',
            width: '300px',
            '& img': {
                marginRight: '15px',
            },
            },
        search: {
            width: '680px',
            height: '55px',
            minWidth: '600px',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
            },
        tool: {
            width: '240px',
            minWidth: '140px',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        }
  }
    const location = useLocation();
    const { isFilterShow, isNavbarShow } = useSelector((state) => state.layout);
    const [filter, setFilter] = useState(filteredPaths.includes(window.location.pathname));
    useEffect(() => {
      setFilter(filteredPaths.includes(location.pathname));
    }, [location.pathname]);
    let width = !filter
    ? isNavbarShow
      ? 'calc(100% - 200px)'
      : 'calc(100% - 72px)'
    : isNavbarShow
      ? 'calc(100% - 268px)'
      : isFilterShow
        ? 'calc(100% - 320px)'
        : 'calc(100% - 140px)';

    let left = !filter ? (isNavbarShow ? '200px' : '72px') : isNavbarShow ? '268px' : isFilterShow ? '320px' : '140px';
    return (
        <Box
          minHeight="55px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{
            width: width,
            transition: 'all 0.3s',
            left: left,
          }}
          sx={classes.root}
        >
          <Box sx={classes.logo}>
            <img src="/images/logo.svg" alt="logo" />
          </Box>
          <Box sx={classes.tool} display="flex" flexShrink="0">
            <Tool />
          </Box>
        </Box>
    );
}
export default Header;
import { useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import CustomPagination from "src/reusable-components/pagination";
import Result from "./Result";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClientsList } from "src/slices/management";
import Loading from "src/components/Loading";
import { isEmpty } from "lodash";
export default function Details() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = {
        label: {
            color: theme.palette.text.gray,
        }
    }
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sortHead, setSortHead] = useState({
        label: 'client',
        up: true,
    });
    const clientsLoading = useSelector((state) => state.loading.clients);
    const {clients} = useSelector((state)=>state.management);
    const { currentClientFilter } = useSelector((state) => state.filter);
    const [results, setResults] = useState([]);
    const totalCount = clients?.length || 0;

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };

    useEffect(() => {
        dispatch(
            getClientsList({
            client: [],
            instances: [],
            facility: [],
            clientStatus: [],
            startRow: 1,
            orderBy: 'status'
            })
        );
    }, []); // eslint-disable-line

    useEffect(() => {
        !isEmpty(currentClientFilter) && dispatch(getClientsList(currentClientFilter));
    }, [currentClientFilter]); // eslint-disable-line

    useEffect(() => {
        const paginatedResults = clients ? clients.slice(limit * (page - 1), limit * page): [];
        setResults([...paginatedResults].sort((a, b) => a.client.toLowerCase() > b.client.toLowerCase() ? 1 : -1));
      }, [limit, page, clients]); // eslint-disable-line
    
      useEffect(() => {
        setPage(1);
      }, [totalCount]);
    
    return (
        <Box maxWidth="100%">
            <Box display="flex" alignItems="end" justifyContent="space-between" pb={2}>
                <Box display="flex" alignItems="end" height={64} pt={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={classes.label}>
                            <span>{totalCount} Client(s) found</span>
                        </Typography>
                        {clientsLoading && <Loading />}
                    </Box>
                </Box>
                <Box pt={2} display="flex" justifyContent="flex-end" alignItems="end">
                    <CustomPagination
                        onLimitChange={(e) => {
                            setLimit(e.target.value);
                            setPage(1);
                          }}
                          limit={limit}
                          page={page}
                          onPageChange={handlePageChange}
                          totalNum={totalCount}
                    />
                </Box>
            </Box>
            <Box pb={5} style={{ overflowX: 'auto', marginBottom: '15px' }} width={'100%'} height={window.innerHeight - 225 + 'px'}>
                <Result 
                    results={results}
                    sortHead={sortHead}
                    setSortHead={setSortHead}
                    setResults={setResults}
                />
            </Box>
        </Box>
    );
}
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import Icon from 'src/reusable-components/icons';
import UserBreadcrumbs from 'src/reusable-components/navigation/Breadcrumbs';
import { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import ClientEditModal from './ClientEditModal';
import EditStatus from './EditStatus';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from 'src/helper';

const Header = ({client, clientId}) => {
  const theme = useTheme();
  const classes = {
    name: {
        color: theme.palette.text.gray,
        marginRight: '20px',
      },
      newButton: {
        background: theme.palette.text.secondary,
        color: 'white',
        height: '36px',
        width: '104px',
        borderRadius: '36px',
        textTransform: 'capitalize',
        '&:hover': {
          background: theme.palette.text.secondary,
          opacity: '0.9',
        },
      },
      status: {
        border: '1px solid #D6D6D6',
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 2px',
        fontWeight: '500',
        fontSize: '13px',
        fontFamily: 'Inter',
        color: theme.palette.text.neutral,
        height: '32px',
        width: '160px',
      }
  }
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const { clientDetail } = useSelector((state) => state.management);
  const [openClientEditModal, setOpenClientEditModal] = useState(false);
  const permissions = true;
  const [clientStatus, setClientStatus] = useState('');
  const [email, setEmail] = useState();

  useEffect(()=>{
    setClientStatus(capitalizeFirstLetter(clientDetail?.status));
    setEmail(client?.email);
  },[client,email]); // eslint-disable-line
  return (
    <Box>
      <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
        <Grid container justifyContent="space-between">
          <Grid item>
            <UserBreadcrumbs type="clientDetails" />
          </Grid>
        </Grid>
      </Box>
      <Box pt={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Client details</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
        {permissions && (
          <Box sx={classes.status}>
            <Box display="flex" alignItems="center">
              {client?.status?.toLowerCase() === 'activated' && <Icon name="success" size="lg" mr="6" ml="2" />}
              {client?.status?.toLowerCase() === 'deactivated' && <Icon name="reject" size="lg" mr="6" ml="2" />}
              {client?.status?.toLowerCase() === 'locked' && <Icon name="locked" size="lg" mr="6" ml="2" color="red" />}
              {capitalizeFirstLetter(client?.status)}
            </Box>
            <Tooltip title="Update Status" arrow placement="top">
              <img
                src="/images/edit.svg"
                alt="edit status"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenStatusModal(true)}
              />
            </Tooltip>
          </Box>
        )}

          <Tooltip title="Edit Role" arrow placement="top">
              <img
                src="/images/edit.svg"
                alt="edit status"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenClientEditModal(true)}
              />
            </Tooltip>
        </Box>
      </Box>
      <ClientEditModal
        open={openClientEditModal}
        onCancel={() => setOpenClientEditModal(false)}
        clientId={clientId}
        email={email}
      />
      <EditStatus
        open={openStatusModal}
        onCancel={() => setOpenStatusModal(false)}
        status={clientStatus}
        clientId={clientId}
      />
    </Box>
  );
};

export default Header;

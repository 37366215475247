import React from 'react';

const UserSvg = ({ fill, subfill }) => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill={fill ? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.83219 4.41601C4.83219 3.21962 5.80205 2.24976 6.99844 2.24976C8.19482 2.24976 9.16469 3.21962 9.16469 4.41601C9.16469 5.61239 8.19482 6.58225 6.99844 6.58225C5.80205 6.58225 4.83219 5.61239 4.83219 4.41601ZM6.99844 0.749756C4.97362 0.749756 3.33219 2.39119 3.33219 4.41601C3.33219 6.44082 4.97362 8.08225 6.99844 8.08225C9.02325 8.08225 10.6647 6.44082 10.6647 4.41601C10.6647 2.39119 9.02325 0.749756 6.99844 0.749756ZM3.67038 9.92347C1.87466 9.92347 0.418945 11.3792 0.418945 13.1749V16.5101C0.418945 16.9244 0.754732 17.2601 1.16895 17.2601H12.8423C13.2565 17.2601 13.5923 16.9244 13.5923 16.5101V13.1749C13.5923 11.3792 12.1366 9.92347 10.3409 9.92347H3.67038ZM1.91895 13.1749C1.91895 12.2076 2.70309 11.4235 3.67038 11.4235H10.3409C11.3082 11.4235 12.0923 12.2076 12.0923 13.1749V15.7601H1.91895V13.1749Z"
      fill={subfill ? subfill : '#6D6D6D'}
    />
  </svg>
);

export default UserSvg;
import { Box, Modal, Typography, Grid } from '@mui/material';
import React, {useEffect, useState } from 'react';
import CustomButton from 'src/reusable-components/buttons/CustomButton';
import Icon from 'src/reusable-components/icons';
import TextField from 'src/reusable-components/text-field';
import Select from "src/reusable-components/text-field/customSelect";
import MultiSelect from 'src/reusable-components/multi-select';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import validator from 'validator';
import { changeMultiSelectFormat, getObjectFromList } from 'src/helper';
import { STATUS_PAST_V2 } from 'src/utils/constant';
import { addNewFacility, setClientUpdatedStatus, updateFacility } from 'src/slices/management';
import { useDispatch } from 'react-redux';

const classes = {
  paper: {
    position: 'absolute',
    width: '686px',
    maxWidth: 'calc(100% - 64px)',
    backgroundColor: '#fff',
    borderRadius: '5px',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    overflowY: 'auto',
    outline: 'none'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  header: {
    height: '40px',
    backgroundColor: '#e5e5e5',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
  centerBox:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  buttonBox:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
};

const FacilityModal = (props) => {
  const dispatch = useDispatch();
  const { type, onCancel, facilityData } = props;
  const isEdit = type === 'Edit' ? true : false;
  const [open, setOpen] = useState(false);
  const [facilityId, setFacilityId] = useState('');
  const [facilityName, setFacilityName] = useState('');
  const [loading, setLoading] = useState(false);
  const {specialties,instances} = useSelector((state) => state.filter);
  const instanceOptions = instances ? changeMultiSelectFormat(instances) : [];
  const specialtyOptions = specialties ? changeMultiSelectFormat(specialties) : [];
  const [specialtySearch, setSpecialtySearch] = useState('');
  const [specialty, setSpecialty] = useState([]);
  const [instanceSearch, setInstanceSearch] = useState('');
  const [instance, setInstance] = useState([]);
  const [email, setEmail] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const [error, setError] = useState({});
  
  const emailValidate = (value) => {
    return validator.isEmail(value);
  };

  const handleValidation = (data) => {
    const temp = {};
    let valid = true;
    if(type === 'Add'){
      if (!data.facilityId) {
        temp.facilityId = 'This field is required.';
        valid = false;
      }
      if (!data.facilityName) {
        temp.facilityName = 'This field is required.';
        valid = false;
      }
      if (!data.facilityId) {
        temp.facilityId = 'This field is required.';
        valid = false;
      }
      if (!emailValidate(data.email)) {
        temp.email = data.email ? 'Invalid Entry.' : 'This field is required.';
        valid = false;
      }
    }
    
    if (specialty?.length === 0) {
      temp.specialty = 'This field is required.';
      valid = false;
    }
    if (statusSelected?.length === 0) {
      temp.status = 'This field is required.';
      valid = false;
    }
    if (instance?.length === 0) {
      temp.instance = 'This field is required.';
      valid = false;
    }
    setError({ ...temp });
    return valid;
  }
  const handleApply = async () => {
    dispatch(setClientUpdatedStatus(false));
    const data = {};
    if(type === 'Add'){
      data.facilityId = facilityId;
      data.facilityName = facilityName;
      data.email = email;
    }
    
    data.specialities = specialty.map((e) => e.value);
    data.instances = instance.map((e) => e.value);
    data.status = statusSelected !== "" ? statusSelected.toLowerCase() : statusSelected;
    if(handleValidation(data)){
      setLoading(true);
      if(type === 'Add'){
        await dispatch(addNewFacility(data));
      }
      else{
        data.facilityId = facilityData?.id;
        await dispatch(updateFacility(data));
      }
      setLoading(false);
      onCancel();
    }
  };
  
  const handleSpecialtyChange = (e) => {
    let errTemp = _.cloneDeep(error);
    errTemp.specialty = '';
    setError({ ...errTemp });
  }

  const handleInstanceChange = (e) => {
    let errTemp = _.cloneDeep(error);
    errTemp.instance = '';
    setError({ ...errTemp });
  }

  const handleSelectChange = (e) => {
    let errTemp = _.cloneDeep(error);
    errTemp.status = '';
    setError({ ...errTemp });
  }
  const init = () => {
    if(type === 'Edit'){
      let instanceArr = (facilityData?.instances).split(',')
      let initial_instances = getObjectFromList(instanceOptions, instanceArr.map((item)=>item.toUpperCase().trim()), 'value');
      setInstance(initial_instances);
      let specialtyArr = (facilityData?.specilaities).split(',')
      let initial_specialties = getObjectFromList(specialtyOptions, specialtyArr.map((item)=>item.toUpperCase().trim()), 'value');
      setSpecialty(initial_specialties);
      setStatusSelected(facilityData?.facilityStatus);
      setFacilityName(facilityData?.facilityName);
      setEmail(facilityData?.email);
    }
    else{
      setFacilityId('');
      setFacilityName('');
      setSpecialtySearch('');
      setSpecialty([]);
      setStatusSelected('');
      setEmail('');
      setInstance([]);
      setInstanceSearch('');
    }
    setError({});
  }
  useEffect(()=>{
    setOpen(type !== '');
    init();
  },[type]); // eslint-disable-line

  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={classes.paper}>
        <Box sx={classes.header}>
          <Typography sx={classes.label}>{`${type} Facility`}</Typography>
          <Icon name="cancel" size="sm" className={classes.closeIcon} onClick={onCancel} />
        </Box>
        <Box px={5} pt={3} sx={{flexGrow: 1}}>
            {!isEdit && <Grid container spacing={3}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Facility ID:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        onChange={(e) => {
                          setFacilityId(e.target.value);
                          let errTemp = _.cloneDeep(error);
                          errTemp.facilityId = '';
                          setError({ ...errTemp });
                        }}
                        value={facilityId}
                        size="medium"
                        placeholder=""
                        width="100%"
                        autoComplete="off"
                        disabled={isEdit}
                      />
                      <Typography variant="caption" color="red">
                        {error.facilityId}
                      </Typography>
                </Grid>
                <Grid item xs={4} sx={classes.buttonBox}>
                    <CustomButton text="Generate" color="primary" size="small" style={{marginRight: '15px'}}/>
                    <CustomButton text="Verify" color="primary" size="small" />
                </Grid>
            </Grid>}
            <Grid container spacing={3} pt={!isEdit && 2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Facility Name:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        onChange={(e) => {
                          setFacilityName(e.target.value);
                          let errTemp = _.cloneDeep(error);
                          errTemp.facilityName = '';
                          setError({ ...errTemp });
                        }}
                        value={facilityName}
                        size="medium"
                        placeholder=""
                        width="100%"
                        autoComplete="off"
                        disabled={isEdit}

                        />
                        <Typography variant="caption" color="red">
                          {error.facilityName}
                        </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Instance:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <MultiSelect
                      selectList={instanceOptions}
                      selected={instance}
                      setSelect={setInstance}
                      search={instanceSearch}
                      onChange={handleInstanceChange}
                      setSearch={setInstanceSearch}
                      placeholder="Select Instance"
                      width="100%"
                      error={error.instance}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Specialty:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <MultiSelect
                      selectList={specialtyOptions}
                      selected={specialty}
                      setSelect={setSpecialty}
                      search={specialtySearch}
                      onChange={handleSpecialtyChange}
                      setSearch={setSpecialtySearch}
                      placeholder="Select Specialty"
                      width="100%"
                      error={error.specialty}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Email:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        onChange={(e) => {
                          setEmail(e.target.value);
                          let errTemp = _.cloneDeep(error);
                          errTemp.email = '';
                          setError({ ...errTemp });
                        }}
                        value={email}
                        size="medium"
                        placeholder=""
                        width="100%"
                        autoComplete="off"
                        disabled={isEdit}
                        />
                        <Typography variant="caption" color="red">
                          {error.email}
                        </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={3} sx={classes.centerBox}>
                    <Typography sx={classes.label}>Status:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Select
                        selectList={STATUS_PAST_V2}
                        placeholder='Select'
                        setSelect={setStatusSelected}
                        selected={statusSelected}
                        width="auto"
                        height = "36px"
                        helperText="Status"
                        dropHeight="200px"
                        error={error.status}
                        onChange={handleSelectChange}
                    />
                </Grid>
            </Grid>
          <Box pt={7} pb={2} display="flex" justifyContent="flex-end" alignItems="center">
            <CustomButton text="Cancel" color="secondary" size="large" onClick={() => onCancel()} />
            <CustomButton text="Save" color="primary" size="large" disabled={loading} onClick={handleApply} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default FacilityModal;

import { Box } from "@mui/material"
import Filter from "./Filter"
import Header from './Header'
import Details from "./Details"
import { useEffect } from "react"
import { useSelector,useDispatch } from "react-redux"
import { getTrasactions,setPage,setLimit } from "src/slices/transaction"
export default function Transaction() {
    const { currentTransactionFilter, defaultTransactionFilter } = useSelector((state) => state.filter);
    const {page,limit} = useSelector((state)=>state.transactions);
    const dispatch = useDispatch();
    const getTransactionData = (currentFilter, pgNum, limNum,orderBy,direction) => {
        let pageNum = pgNum ? pgNum : page;
        let limitCount = limNum ? limNum : limit;
        if (Object.keys(currentFilter).length > 0) {
          let filter = {};
          currentFilter.fromDate && (filter.fromDate = currentFilter.fromDate);
          currentFilter.toDate && (filter.toDate = currentFilter.toDate);
          currentFilter.facilities && (filter.facilities = currentFilter.facilities);
          currentFilter.specialities && (filter.specialities = currentFilter.specialities);
          currentFilter.visitId && (filter.visitId = currentFilter.visitId);
          currentFilter.response && (filter.response = currentFilter.response);
          currentFilter.transactionId && (filter.transactionId = currentFilter.transactionId);
          currentFilter.requestId && (filter.requestId = currentFilter.requestId);
          currentFilter.clients && (filter.clients = currentFilter.clients);
          currentFilter.instances && (filter.instances = currentFilter.instances);
          currentFilter.transactionType && (filter.transactionType = currentFilter.transactionType);
          filter.startRow = (pageNum - 1) * limitCount + 1;
          filter.rowsToFetch = limitCount;
          filter.sort = direction ? 'ASC' : 'DESC';
          filter.orderBy = orderBy || 'requestOn';
          dispatch(getTrasactions(filter));
        }
    };
    const fetchDefaultTransactionData = (pgNum, limNum,orderBy,direction) => {
      getTransactionData(currentTransactionFilter, pgNum, limNum,orderBy,direction);
    };
    // eslint-disable-next-line
    useEffect(() => {
        getTransactionData(defaultTransactionFilter, 1, 50);// eslint-disable-next-line
    },[]); // eslint-disable-next-line
    return (
        <Box display="flex">
            <Filter fetchTransactionData={getTransactionData} setPage={setPage}/>
            <Box px={7} flex="1" sx={{
                height: 'calc(100vh - 55px)',
                overflow: 'auto',
            }}>
            <Header/>
            <Details 
              fetchTransactionData={fetchDefaultTransactionData}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
            </Box>
        </Box>
    )
}

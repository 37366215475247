import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Icon from 'src/reusable-components/icons';
import UserBreadcrumbs from 'src/reusable-components/navigation/Breadcrumbs';

import EditRoleStatus from './EditRoleStatus';
import { useTheme } from '@emotion/react';

const Header = ({role, roleId}) => {
  const theme = useTheme();
  const classes = {
    name: {
        color: theme.palette.text.gray,
        marginRight: '20px',
      },
      newButton: {
        background: theme.palette.text.secondary,
        color: 'white',
        height: '36px',
        width: '104px',
        borderRadius: '36px',
        textTransform: 'capitalize',
        '&:hover': {
          background: theme.palette.text.secondary,
          opacity: '0.9',
        },
      },
      status: {
        border: '1px solid #D6D6D6',
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 2px',
        fontWeight: '500',
        fontSize: '13px',
        fontFamily: 'Inter',
        color: theme.palette.text.neutral,
        height: '32px',
        width: '160px',
      }
  }
  const navigate = useNavigate();
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const permissions = true;
  const [roleStatus, setRoleStatus] = useState(role?.role.status)
  useEffect(()=>{
    setRoleStatus(role?.role.status)
  },[role])
  return (
    <Box>
      <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
        <Grid container justifyContent="space-between">
          <Grid item>
            <UserBreadcrumbs type="roleDetails" />
          </Grid>
        </Grid>
      </Box>
      <Box pt={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Role details</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
        {permissions && (
          <Box sx={classes.status}>
            <Box display="flex" alignItems="center">
              {role?.role.status?.toLowerCase() === 'activated' && <Icon name="success" size="lg" mr="6" ml="2" />}
              {role?.role.status?.toLowerCase() === 'deactivated' && <Icon name="reject" size="lg" mr="6" ml="2" />}
              {role?.role.status?.toLowerCase() === 'locked' && <Icon name="locked" size="lg" mr="6" ml="2" color="red" />}
              {role?.role.status}
            </Box>
            <Tooltip title="Update Status" arrow placement="top">
              <img
                src="/images/edit.svg"
                alt="edit status"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenStatusModal(true)}
              />
            </Tooltip>
          </Box>
        )}

          <Tooltip title="Edit Role" arrow placement="top">
              <img
                src="/images/edit.svg"
                alt="edit status"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/dashboard/roles/edit/${roleId}`)}
              />
            </Tooltip>
        </Box>
      </Box>
      <EditRoleStatus
        open={openStatusModal}
        onCancel={() => setOpenStatusModal(false)}
        status={roleStatus}
        roleId={roleId}
      />
    </Box>
  );
};

export default Header;

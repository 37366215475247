import AuthLayout from "./layouts/auth"
import ForgetPassword from "./pages/auth/forgetPassword";
import InitialLogin from "./pages/auth/initialLogin";
import Login from "./pages/auth/login";
import Layout from "./layouts";
import Error from "./pages/error";
import Transaction from "./pages/transaction";
import TransactionDetails from "./pages/transactionDetails";
import Roles from "./pages/roles";
import Users from "./pages/users";
import Home from "./pages/home";
import RoleDetail from "./pages/roles/role/roleDetails";
import UsersDetail from "./pages/users/user/userDetails";
import AddNewRole from "./pages/roles/role/Create";
import AddUser from "./pages/users/user/Create";
import EditUser from "./pages/users/user/Edit";
import EditRole from "./pages/roles/role/Edit";
import Clients from "./pages/clients";
import ClientDetail from "./pages/clients/client/clientDetails";

const routes = [
    {
        path: "/",
        element: <InitialLogin />,
        errorElement: <Error />
    },
    {
        path: "/auth",
        element: <AuthLayout />,
        children: [
            {
                path: "/auth/login",
                element: <Login />,
            },
            {
                path: "/auth/forgetpassword",
                element: <ForgetPassword />,
            }
        ],
    },
    {
        path: "/dashboard",
        element: <Layout />,
        children: [
            {
                path: "/dashboard/home",
                element: <Home />,
            },
            {
                path: "/dashboard/transaction",
                element: <Transaction />,
            },
            {
                path: "/dashboard/transaction/:tid",
                element: <TransactionDetails />,
            },
            {
                path: "/dashboard/users",
                element: <Users />,
            },
            {
                path: "/dashboard/users/:uid",
                element: <UsersDetail />,
            },
            {
                path: "/dashboard/users/edit/:uid",
                element: <EditUser />,
            },
            {
                path: "/dashboard/users/new",
                element: <AddUser />,
            },
            {
                path: "/dashboard/roles",
                element: <Roles />,
            },
            {
                path: "/dashboard/roles/:rid",
                element: <RoleDetail />,
            },
            {
                path: "/dashboard/roles/new",
                element: <AddNewRole />,
            },
            {
                path: "/dashboard/roles/edit/:rid",
                element: <EditRole />,
            },
            {
                path: "/dashboard/clients",
                element: <Clients />,
            },
            {
                path: "/dashboard/clients/:cid",
                element: <ClientDetail />,
            },
            
        ],
    }
];
export default routes

import React from "react";
import {TextField, Box} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
  setMarginBottom,
  setMarginLeft,
  setMarginRight,
  setMarginTop,
  setPaddingBottom,
  setPaddingLeft,
  setPaddingRight,
  setPaddingTop,
} from "../utils";

const CssTextField = styled(TextField)((props) => ({

        "& .MuiOutlinedInput-root": {
          marginTop: setMarginTop(props, "0px"),
          marginBottom: setMarginBottom(props, "0px"),
          marginRight: setMarginRight(props, "0px"),
          marginLeft: setMarginLeft(props, "0px"),
          paddingTop: setPaddingTop(props, "16px"),
          paddingBottom: setPaddingBottom(props, "16px"),
          paddingRight: setPaddingRight(props, "16px"),
          paddingLeft: setPaddingLeft(props, "16px"),
          backgroundColor: props.disabled ? "#F9F9F9 !important" : "white",
          "& fieldset": {
            top: "0px",
            borderRadius: "3px",
          },
          "& textarea": {
            padding: "0px",
            fontSize: "14px",
            fontFamily: "Inter",
            lineHeight: "20px",
            fontWeight: "500",
            backgroundColor: props.disabled ? "#F9F9F9 !important" : "white",
          },
          "& textarea::-webkit-scrollbar": {
            width: "5px",
            backgroundColor: "#F5F5F5",
          },
          "& textarea::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            webkitBowShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#4285F4",
          },
          "& textarea::placeholder": {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: "#A4A4A4",
            opacity: 1 /* Firefox */,
          },
          "&.Mui-focused textarea": {
            backgroundColor: "white",
          },
          // "& textarea:-ms-textarea-placeholder": {
          //   /* Internet Explorer 10-11 */
          //   color: "#A4A4A4",
          // },
          "& legend": {
            display: "none",
          },
          "&.Mui-focused fieldset": {
            borderColor: "red",
            border: "solid 1px",
          },
          "&.Mui-focused.Mui-error fieldset": {
            borderColor: "#F84F4F",
          },
        },
        "& .MuiFormHelperText-root": {
          margin:
            props.size === "small" ? "8px 14px 8px 0px" : "10px 14px 10px 0px",
        }
  }));

const CustomTextField = (props) => {
  const { value, onChange, error, placeholder, helperText, size, disabled } =
    props;
  const handleChange = (e) => {
    onChange(e);
  };
  return (
    <Box style={{backgroundColor: disabled ? "#F9F9F9 !important" : "white"}}>
      <CssTextField
        id="filled-error"
        disabled={disabled}
        error={error}
        fullWidth={true}
        placeholder={placeholder}
        helperText={helperText}
        variant="outlined"
        size={size}
        onChange={handleChange}
        value={value}
        multiline
        rows={props.rows ? props.rows : 2}
      />
    </Box>
  );
};

export default CustomTextField;

import { Card, Box, Typography, Collapse, Grid, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import  * as _ from "lodash"
import { isEmpty } from "lodash";
import SwitchToggle from "src/reusable-components/switch";
export default function Result() {
    const classes = {
        header: {
          backgroundColor: '#F9F9F9',
          cursor: 'pointer',
          justifyContent: 'space-between',
        },
        value: {
          '& p': {
            height: '22px',
            width: '150px',
            textAlign: 'right',
          },
        },
        divider: {
        //   backgroundColor: '#F9F9F9',
          height: '2px',
          border: 'none',
        },
        body: {
            maxHeight: '500px',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px',
                height: '5px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#D2D2D2',
                borderRadius: '5px',
            },
        }
      };
    const [isRequestOpen, setIsRequestOpen] = useState(true);
    const [decodedRequest, setDecodedRequest] = useState();
    const [isResponseOpen, setIsResponseOpen] = useState(true);
    const [isDataElementsOpen, setIsDataElementsOpen] = useState(true);
    const [checked, setChecked] = useState(false);
    const switches = ['Encode','Decode'];
    const {transactionDetails} = useSelector((state) => state.transactionDetails);
    const requestPrettified = JSON.stringify(!checked ? transactionDetails.Request : decodedRequest,null,4);
    const responsePrettified = JSON.stringify(transactionDetails.Response,null,4);
    const dataElementsPrettified = JSON.stringify(transactionDetails.dataElement,null,4);

    const handleClickRequest = (e) => {
        setIsRequestOpen(!isRequestOpen);
    };
    
    const handleClickResponse = (e) => {
        setIsResponseOpen(!isResponseOpen);
    };

    const handleClickDataElements = (e) => {
        setIsDataElementsOpen(!isDataElementsOpen);
    };

    useEffect(()=>{
        if(!isEmpty(transactionDetails)){
            var decodedRequest = _.cloneDeep(transactionDetails.Request);
            decodedRequest.visit.map((visit)=>{
                console.log(JSON.parse(atob(visit.chart)))
                visit.chart = JSON.parse(atob(visit.chart));
            })
            setDecodedRequest(decodedRequest);
        }
        
    },[transactionDetails]);

    const handleCheck = (e) => {
        e.stopPropagation();
        setChecked(e.target.checked); 
    }

    const validCheck = (data) =>{
        return (data != "" ? data : 'No Data Available');
    }

    return (
        <>
        <Card style={{ marginTop: '25px' }}>
            <Box px={1.5} py={1.2} display="flex" sx={classes.header} onClick={handleClickRequest} >
                <Typography variant="subtitle2">
                Request
                </Typography>
                <Box display='flex'>
                    <SwitchToggle checked={checked} handleCheck={handleCheck} switches={switches}/>
                    {isRequestOpen ? (
                    <img src="/images/up-arrow.svg" alt="arrow" />
                    ) : (
                    <img src="/images/down-arrow.svg" alt="arrow" />
                    )} 
                </Box>
                
            </Box>
            <Box >
                <Collapse in={isRequestOpen}>
                <Box px={1.5} py={2} sx={classes.body}>
                    <Grid container>
                        <pre style={{fontFamily: 'Inter, sans-serif',marginTop: 0}}>
                            <Typography variant="body1">
                                {validCheck(requestPrettified)}
                            </Typography>
                        </pre>
                    </Grid>
                </Box>
                </Collapse>
            </Box>
        </Card>
        <Card style={{ marginTop: '35px',marginBottom:'35px' }} >
            <Box px={1.5} py={1.2} display="flex" sx={classes.header} onClick={handleClickResponse} >
                <Typography variant="subtitle2">
                Response
                </Typography>
                {isResponseOpen ? (
                <img src="/images/up-arrow.svg" alt="arrow" />
                ) : (
                <img src="/images/down-arrow.svg" alt="arrow" />
                )}
            </Box>
            <Box>
                <Collapse in={isResponseOpen}>
                <Box px={1.5} py={1.2} sx={classes.body}>
                    <Grid container>
                        <pre style={{fontFamily: 'Inter, sans-serif',marginTop: 0}}>
                            <Typography variant="body1">
                                {validCheck(responsePrettified)}
                            </Typography>
                        </pre>
                    </Grid>
                </Box>
                </Collapse>
            </Box>
        </Card>
        <Card style={{ marginTop: '35px',marginBottom:'35px' }} >
            <Box px={1.5} py={1.2} display="flex" sx={classes.header} onClick={handleClickDataElements} >
                <Typography variant="subtitle2">
                Data Elements
                </Typography>
                {isDataElementsOpen ? (
                <img src="/images/up-arrow.svg" alt="arrow" />
                ) : (
                <img src="/images/down-arrow.svg" alt="arrow" />
                )}
            </Box>
            <Box>
                <Collapse in={isDataElementsOpen}>
                <Box px={1.5} py={1.2} sx={classes.body}>
                    <Grid container>
                        <pre style={{fontFamily: 'Inter, sans-serif',marginTop: 0}}>
                            <Typography variant="body1">
                                {validCheck(dataElementsPrettified)}
                            </Typography>
                        </pre>
                    </Grid>
                </Box>
                </Collapse>
            </Box>
        </Card>
    </>
    )
}
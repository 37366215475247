import { useState,useEffect,useRef } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CustomInput from "src/components/CustomInput";
import CustomButton from "src/components/CustomButton";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loading from "src/components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { getUser,initialize } from "src/slices/user";
import * as storage from 'src/helper/storage'
import ReactSelect from "src/reusable-components/text-field/ReactSelect";
import { getClients,getInstances } from "src/slices/filter";
import { changeReactSelectFormat } from "src/helper";
const Login = () => {
  const { isAuthenticated, error } = useSelector(
    (state) => state.user
  );
  const { clients, instances } = useSelector((state)=>state.filter);
  const theme = useTheme();
  const classes = {
    root: {
      padding: '20px',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
      position: 'relative',
    },
    title: {
      fontWeight: 'bold',
      paddingBottom: '24px',
      textAlign: 'center',
      color: theme.palette.text.neutral,
    },
    forgotPwd: {
      margin: '10px 0px',
      marginBottom: '20px',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '500',
      height: '20px',
      textAlign: 'right',
      color: theme.palette.text.secondary,
      '& span:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    support: {
      color: theme.palette.text.gray,
      bottom: '32px',
      '& a': {
        color: theme.palette.text.secondary,
        textDecoration: 'none'
      }
    },
    error: {
      height: '36px',
      padding: '8px 10px',
      backgroundColor: error ? 'rgba(248, 79, 79, 0.07)' : 'white',
      fontSize: '12px',
      color: '#F84F4F',
      margin: '5px 0px',
    },
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { auth: authLoading } = useSelector((state) => state.loading);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [nameError, setNameError] = useState('');
  const [pwdError, setPwdError] = useState('');
  const [errorMsg, setError] = useState('');
  const [clientSelected, setClientSelected] = useState("");
  const [instanceSelected, setInstanceSelected] = useState("");
  const [loginDisable, setLoginDisable] = useState(false);

  const checkLoginPossibility = () => {
    const authTrialErrorNum = localStorage.getItem('authTrialErrorNum');
    const authTrial = storage.getWithExpiry('authTrial');
    if (authTrial && Number(authTrialErrorNum) >= 5) {
      return false;
    } else if (!authTrial) {
      localStorage.setItem('authTrialErrorNum', 0);
    }
    return true;
  };
  useEffect(() => {
    error && setError(error);
    if(isAuthenticated){
      //redirect page
      navigate('/dashboard/transaction',{replace: true})
    }
  }, [isAuthenticated, error, navigate]);

  const btnRef = useRef();
  const handleKeyPress = (e) => {
    if (e.code === 'Enter' || e.keyCode === '13') {
      btnRef?.current?.click();
    }
  };

  useEffect(() => {
    dispatch(getClients())
    dispatch(getInstances())
    localStorage.removeItem('isIdle');
    if (!localStorage.getItem('token')) {
      dispatch(initialize());
    }
    if (!checkLoginPossibility()) {
      setLoginDisable(true);
      setError('You cannot login for an hour , because you have exceeded the trial limit');
    }

    window.addEventListener('keypress', handleKeyPress);
    return () => {
      btnRef.current = null;
      window.removeEventListener('keypress', handleKeyPress);
    };
  },[dispatch]);
  const onNameChange = (e) => {
    setUserName(e.target.value);
    setNameError('');
  };
  const onPassChange = (e) => {
    setPassword(e.target.value);
    setPwdError('');
  };
  const goToForgetPassword = () => {
    return navigate("/auth/forgetpassword")
  }
  const handleSubmit = () => {
    userName === '' && setNameError('Please enter your username!');
    password === '' && setPwdError('Please enter your password!');

    if (!checkLoginPossibility()) {
      setLoginDisable(true);
      setError('You cannot login for an hour , because you have exceeded the trial limit');
      return;
    }

    if (userName !== '' && password !== '') {
      setError('');
      let credentials = {};
      credentials.userName = userName;
      credentials.password = password;
      clientSelected.label && (credentials.client = clientSelected.label.toLowerCase());
      instanceSelected.label && (credentials.instance = instanceSelected.label.toLowerCase());
      dispatch(getUser(credentials));
    }
  }  
  var clientList = clients ? changeReactSelectFormat(clients) : [];
  const instanceList = instances ? changeReactSelectFormat(instances) : [];
  return (
    <>
      <Box sx={classes.root}>
        <Typography variant="h5" sx={classes.title}>
        Log In
        </Typography>
        {authLoading && (
        <Box
          h="0px"
          position="absolute"
          top="55px"
          width={['calc(100% - 32px)', 'calc(100% - 40px)']}
          display="flex"
          justifyContent="center"
          >
          <Loading />
        </Box>
         )}
        <CustomInput
          label="Username"
          type="text"
          value={userName}
          onChange={onNameChange}
          error={nameError}
        />
        <CustomInput
          label="Password"
          type="password"
          value={password}
          onChange={onPassChange}
          error={pwdError}
        />
        <Box width="100%" display="flex" mt="5px" height="76px" flexDirection="column">
          <Typography style={{ color: '#878787', padding: '5px 0px', fontSize: '12px' }}>Client</Typography>
          <ReactSelect
            name="client"
            options={clientList || []}
            changeOption={setClientSelected}
            value={clientSelected}
            size="small"
            width="100%"
            height="36px"
            dropdownHeight={clientList?.length > 2 ? '130px' : '70px'}
          />
        </Box>
        <Box width="100%" display="flex" mt="5px" height="76px" flexDirection="column">
          <Typography style={{ color: '#878787', padding: '5px 0px', fontSize: '12px' }}>Instance</Typography>
          <ReactSelect
            name="location"
            options={instanceList || []}
            changeOption={setInstanceSelected}
            value={instanceSelected}
            size="small"
            width="100%"
            height="36px"
            dropdownHeight={instanceList?.length > 2 ? '130px' : '80px'}
          />
        </Box>
        <Typography sx={classes.forgotPwd}>
          <span onClick={goToForgetPassword}>Forgot Password?</span>
        </Typography>
        {errorMsg && (
        <Typography sx={classes.error} variant="h6">
          {errorMsg}
        </Typography>
      )}
      <CustomButton label="Login" onClick={handleSubmit} btnRef={btnRef} disabled={loginDisable || authLoading}/>
      </Box>
      <Typography variant="caption" sx={classes.support}>Having troubles to access the portal?&nbsp; <Link to="#">Contact Support</Link></Typography>
      </>
    )
}
export default Login;
import { Box, useTheme, Typography } from "@mui/material";
import CustomSelect from './CustomSelect'
const Select = (props) => {
    const theme = useTheme();
    const classes = {
        root: {
            width: props.width ? props.width : '200px',
          },
        label: {
            height: props.size === 'small' ? '32px' : '36px',
            fontSize: props.fontSize ? props.fontSize : '14px',
            lineHeight: '22px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            color: theme.palette.text.gray,
            fontWeight: '500',
            whiteSpace: 'nowrap',
          },
        customInput: {
            width: '100%',
            padding: props.size === 'small' ? '6px' : '8px',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            fontFamily: 'Inter',
            height: props.size === 'small' ? '32px' : '36px',
            borderRadius: '3px',
            border: 'solid 1px',
            borderColor: '#D6D6D6',
            '&:disabled': {
              backgroundColor: '#F9F9F9 !important',
            },
            '&::placeholder': {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: '#D6D6D6',
              opacity: 1 /* Firefox */,
            },
            '&:hover': {
              backgroundColor: '#F3F4F4',
            },
            '&:focus': {
              borderColor: '#355EEF',
            },
          }
    }
    return (
        <Box sx={classes.root}>
        {props.label && <Typography sx={classes.label}>{props.label}</Typography>}
        <Box sx={classes.root}>
            {props.label && <Typography sx={classes.label}>{props.label}</Typography>}
            <CustomSelect
                selectList={props?.isIncludeNone ? ['None', ...props.selectList] : props.selectList}
                placeholder={props.placeholder}
                selected={props.selected}
                setSelect={props.setSelect}
                onChange={props.onChange}
                setSelectKey={props.setSelectKey}
                size={props.size}
                name={props.name}
                error={props.error}
                helperText={props.helperText}
                dropHeight={props.dropHeight}
                disabled={props.disabled}
                isButton={props.isButton}
                isCalendar={props.isCalendar}
                setPosition={props.setPosition}
            />
    </Box>
    </Box>
    )
}
export default Select;
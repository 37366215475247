import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CustomInput from "src/components/CustomInput";
import CustomButton from "src/components/CustomButton";

export default function ForgetPassword() {
  const theme = useTheme();
  const classes = {
    root: {
      padding: '10px 20px',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
      position: 'relative',
    },
    title: {
      fontWeight: 'bold',
      textAlign: 'left',
      paddingBottom: '24px',
      color: theme.palette.text.neutral,
    },
    label: {
      color: theme.palette.text.default
    }
  }
  const [userName, setUserName] = useState('');
  const onNameChange = (e) => {
    setUserName(e.target.value);
  };
    return (
      <Box sx={classes.root}>
      <Typography variant="subtitle1" sx={classes.title}>
        Forget Password
            </Typography>
            <Typography variant="info" sx={classes.label}>
            Enter your email address and we'll send you a link to reset your password.
            </Typography>
            <br />
            <br />
        <CustomInput
          label="Email address / Username"
          type="text"
          value={userName}
          onChange={onNameChange}
        />
        <br />
        <CustomButton label="Reset Password" />
      </Box>
    )
}
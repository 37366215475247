import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../utils/endPoints";
import { getNotificationStart } from "./notification";
import {NOTIFICATION} from '../utils/constant'
import { getLoadingStart,getLoadingEnd } from "./loading";
const initialState = {
  users: [],
  total: 0,
  page: 1,
  limit: 50
};

const slice = createSlice({
  name: "userlist",
  initialState,
  reducers: {
    initialize(state, action) {
      state.users = [];
      state.total = 0;
      state.page = 1;
      state.limit = 50;
    },
    getUsers(state, action) {
      const payload = action.payload;
      state.users = payload.users;
      state.total = payload?.total || 0;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload
    }
  },
});

export const reducer = slice.reducer;

export const getUsers = (requestData) => async (dispatch) =>{
    dispatch(getLoadingStart('user'));
  await axios({
    method: 'POST',
    url: endPoints.GET_USERS,
    data: { ...requestData }
  })
  .then(async (response) =>{
    dispatch(slice.actions.getUsers(response.data));
    dispatch(getLoadingEnd('user'));
  })
  .catch((err) => {
    dispatch(getLoadingEnd('user'));
    dispatch(
      getNotificationStart(NOTIFICATION.ERROR, err?.response?.data?.errorMessage || 'Error trying to fetch reports.'));
  });
}
export const setPage = (data) => async (dispatch) => {
  dispatch(slice.actions.setPage(data));
};

export const setLimit = (data) => async (dispatch) => {
  dispatch(slice.actions.setLimit(data));
};

export const userInitialize = () => async (dispatch) => {
  dispatch(slice.actions.initialize());
};
export default slice;

import React from 'react';
import { Checkbox, FormControlLabel, Typography, Box } from '@mui/material';
import { useTheme } from '@emotion/react';

export const CustomCheckBox = ({ label, disabled, checked, onChange }) => {
    const theme = useTheme();
    const classesChk = {
        checkBox: {
            '&.MuiCheckbox-root': {
                  padding: '3px',
            },
            color: '#6D6D6D',
            '&.Mui-checked': {
              color: theme.palette.button.blue
            }
        },
        item: {
            width: 'fit-content',
            '&.MuiFormControlLabel-root': {
              marginLeft: '-1px',
            },
        },
    }
  return (
    <FormControlLabel
      sx={classesChk.item}
      control={
        <Checkbox
          sx={classesChk.checkBox}
          checked={checked}
          onChange={onChange}
          name="checkedA"
          size={'small'}
          disableRipple
        />
      }
      label={label}
      disabled={disabled}
    />
  );
};

export const GroupCheckBox = ({ title, labelGroup, selectedLabels, setSelectedLabels, ...props }) => {
    const classes = {
        groupContainer:{
            marginLeft: `${props.ml}px`,
            marginTop: `${props.mt}px`,
            textTransform: `${props.textTransform}`,
          },
          selectContainer: {
            display: 'flex',
            flexDirection: 'column',
          },
          selectContainerRow: {
            display: 'flex',
            flexDirection: 'row',
          },
    }
  const handleChange = (e, label) => {
    if (e.target.checked) {
      setSelectedLabels([...selectedLabels, label]);
    } else {
      setSelectedLabels([...selectedLabels.filter((item) => item !== label)]);
    }
  };
  return (
    <Box sx={classes.groupContainer}>
      {title === "" ? "" : <Box py={1}>
        <Typography variant="subtitle2">{title}</Typography>
      </Box>}
        <Box sx={title === "" ? classes.selectContainerRow : classes.selectContainer}>
        {labelGroup?.map((item, index) => {
          return (
            <CustomCheckBox
              label={item}
              key={index}
              checked={selectedLabels?.indexOf(item) > -1}
              onChange={(e) => handleChange(e, item)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export const GroupCheckBoxWithDisable = ({ title, labelGroup, selectedLabels, setSelectedLabels, ...props }) => {
    const classes = {
        groupContainer:{
            marginLeft: `${props.ml}px`,
            marginTop: `${props.mt}px`,
            textTransform: `${props.textTransform}`,
          },
          selectContainer: {
            display: 'flex',
            flexDirection: 'column',
          },
          selectContainerRow: {
            display: 'flex',
            flexDirection: 'row',
          },
    }
  const handleChange = (e, label) => {
    if (e.target.checked) {
      setSelectedLabels([...selectedLabels, label]);
    } else {
      setSelectedLabels([...selectedLabels.filter((item) => item !== label)]);
    }
  };
  return (
    <Box sx={classes.groupContainer}>
      {title === "" ? "" : <Box py={1}>
        <Typography variant="subtitle2">{title}</Typography>
      </Box>}
        <Box sx={title === "" ? classes.selectContainerRow : classes.selectContainer}>
        {labelGroup?.map((item, index) => {
          return (
            <CustomCheckBox
              label={item?.label}
              key={index}
              checked={selectedLabels.indexOf(item?.label) > -1}
              onChange={(e) => handleChange(e, item?.label)}
              disabled={item?.disable}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default CustomCheckBox;

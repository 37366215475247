import { Outlet } from "react-router-dom"
import { Box, Paper, Typography } from "@mui/material"
import { useTheme } from "@mui/material";
const AuthLayout = () => {
  const theme = useTheme();
  const classes = {
    card: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '400px',
      maxWidth: 'calc(100vw - 10px)',
      padding: '20px',
      margin: '5px',
      bgcolor: 'white',
      borderRadius: '10px'
    },
    logo: {
      '& img': {
        width: 250
      }
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(5, 0),
      lineHeight: '20px',
      textAlign: 'center',
      color: theme.palette.text.gray,
      '& img': {
        marginBottom: '24px',
        width: '30px',
        height: '30px',
      }
    },
    coright: {
      lineHeight: "20px",
      fontWeight: 400
    },
    label: {
      color: theme.palette.text.secondary,
    }
  }
    return (
      <Box display="flex" alignItems="center" flexDirection="column" bgcolor="#E5E5E5" minHeight="100vh">
        <Box py={5} display="flex" alignItems="center" sx={classes.logo}>
        <img src="/images/logo.svg" alt="logo" />
        </Box>
        <Paper elevation={0} sx={classes.card}>
          <Outlet />
        </Paper>
        <Box sx={classes.footer}>
          <img src="/images/brandmark.svg" alt="brand" />
          <Typography variant="caption" sx={classes.coright}>
            Powered by <span style={classes.label}>BUDDI AI</span>
            <br></br>
            Coding AI &copy;. All Rights Reserved. 2024
          </Typography>
        </Box>
      </Box>
    )
}
export default AuthLayout